/**
 * Create an rxjs observable stream that is updated on a `requestAnimationFrame` tick.
 * Passes the current time as well as the elapsed time (delta) since last tick.
 */

import { interval, animationFrameScheduler, Observable } from 'rxjs';
import { scan, share } from 'rxjs/operators';

export type rafClockStream = Observable<{
	time: number;
	delta: number;
}>;

export default function rafClock() {
	return interval(0, animationFrameScheduler).pipe(
		scan(
			(acc, _) => {
				const time = performance.now();
				return { time, delta: time - acc.time };
			},
			{
				time: performance.now(),
				delta: 0
			}
		),
		share()
	);
}
