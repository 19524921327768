/**
 * Insight button tooltip
 */

import React from 'react';
import { Lexicon } from '../data/contextualLexicon';
import { IInsightData } from '../utils/InsightsTree';

interface IInsightTooltipProps {
	data: IInsightData;
	lexicon: Lexicon;
}

const InsightTooltip: React.FC<IInsightTooltipProps> = ({ data, lexicon }) => {
	return (
		<div>
			<div
				style={{
					color: '#45812b',
					textTransform: 'uppercase',
					fontWeight: 600,
					fontSize: '12px',
					lineHeight: '13px'
				}}
			>
				Insight Available
			</div>
			<div
				style={{
					fontWeight: 100,
					marginTop: '1.2em'
				}}
			>
				{data.Title}
			</div>
			<div
				style={{
					fontWeight: 100,
					marginTop: '1.3em',
					fontStyle: 'italic'
				}}
			>
				{
					lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD.BEANPLOT_TOOLTIP
						.Click
				}
			</div>
		</div>
	);
};

export default InsightTooltip;
