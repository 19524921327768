/**
 * Dashboard Component
 */

import React, { useMemo, useState } from 'react';
import { isEqual, times, reduce } from 'lodash-es';

import deepStatePipeline from '../app/utils/index';
import { getLevelAdapter, IDashboardProps } from './contentAdapters';

import ControlPanel from './controlPanel';
import ContextPanel from './ContextPanel';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FiltersPanel } from './FiltersPanel';

const Dashboard: React.FC<IDashboardProps> = ({
	store,
	nav,
	DATA,
	isSplashModalOpen,
	setIsExplainModalOpen,
	setIsDownloadModalOpen,
	isSmallScreen,
	setOpenInsightIndex
}) => {
	const [isQThrobbing, setIsQThrobbing] = useState(true);
	const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);
	let {
		filterObjectArray,
		filteredObservationsByUID,
		filteredStudiesAndLocations
	} = deepStatePipeline(DATA, nav, store);

	const levels = [nav.Outcome, nav.GLII, nav.GLIII];

	const levelAdapter = useMemo(
		() => getLevelAdapter(nav),
		[nav.Studies, ...levels]
	);

	return (
		<div className="dashboard">
			<div className="dashboard-body">
				<ControlPanel
					{...{
						store,
						nav,
						DATA,
						filteredObservationsByUID,
						filterObjectArray,
						setIsDownloadModalOpen,
						setFiltersPanelOpen
					}}
				/>
				{
					<div className="practices-and-outcomes-panel">
						<div className="title text_heading underline">
							<levelAdapter.Title
								{...{
									store,
									levels,
									practice: nav.Practice,
									setIsExplainModalOpen,
									nav,
									isQThrobbing,
									setIsQThrobbing
								}}
							/>
						</div>

						<div className="panel-content">
							<div
								className="tray"
								style={{
									transform: `translate(${
										-levelAdapter.depth * 100
									}%,0)`
								}}
							>
								<TransitionGroup component={null}>
									<CSSTransition
										key={levelAdapter.key}
										classNames="fade"
										timeout={{ enter: 500, exit: 500 }}
									>
										<div
											key={levelAdapter.key}
											className="slide"
											style={{
												transform: `translate(${
													levelAdapter.depth * 100
												}%,0)`
											}}
										>
											<levelAdapter.Content
												{...{
													store,
													nav,
													DATA,
													isSplashModalOpen,
													filterObjectArray,
													filteredObservationsByUID,
													setIsExplainModalOpen,
													setOpenInsightIndex,
													isSmallScreen
												}}
											/>
										</div>
									</CSSTransition>
								</TransitionGroup>
							</div>
						</div>
					</div>
				}
				<ContextPanel
					{...{
						store,
						nav,
						DATA,
						isSplashModalOpen,
						filterObjectArray,
						filteredObservationsByUID,
						filteredStudiesAndLocations,
						setIsExplainModalOpen,
						setIsDownloadModalOpen,
						isSmallScreen
					}}
				/>
			</div>
			<FiltersPanel
				{...{
					open: filtersPanelOpen,
					setOpen: setFiltersPanelOpen,
					filterObjectArray,
					filteredObservationsByUID,
					DATA
				}}
			/>
		</div>
	);
};

export default Dashboard;
