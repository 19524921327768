/**
 * lil' bundle of tooltip components
 */

import React, { useMemo } from 'react';
import { IObservation } from '../../../../preprocess/src/ts/exec';
import { uniq } from 'lodash-es';
import { meanToNormStatus } from '../utils/norms';
import { showDistribution } from './beanplot';
import { Lexicon } from '../data/contextualLexicon';

// lifted from periscopic libs
function commaDelimited(num: number) {
	const comma = ',';
	const period = '.';

	var n: string = num + '';
	n += '';
	var x = n.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? period + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + comma + '$2');
	}
	return x1 + x2;
}

interface IBeanplotTooltipProps {
	observations: IObservation[];
	mean: number;
	norm: string;
	lexicon: Lexicon;
}

const BeanplotTooltip: React.FC<IBeanplotTooltipProps> = ({
	observations,
	mean,
	norm,
	lexicon
}) => {
	const numObservations = observations.length;
	const numStudies = useMemo(
		() => uniq(observations.map((o) => o.studyUID)).length,
		[observations]
	);

	const status = meanToNormStatus(mean, norm);

	const color = status.colors.dark;
	return (
		<div>
			{numObservations > 0 ? (
				<>
					<strong>
						{commaDelimited(numObservations)}{' '}
						<span className="text_fake_smallcaps">
							observation
							{numObservations === 1 ? '' : 's'}
						</span>
					</strong>{' '}
					from{' '}
					<strong>
						{numStudies}{' '}
						<span className="text_fake_smallcaps">
							stud
							{numStudies === 1 ? 'y' : 'ies'}
						</span>
					</strong>{' '}
					<br />
					show a{' '}
					<strong>
						<span className="text_fake_smallcaps" style={{ color }}>
							mean change
						</span>
					</strong>{' '}
					of{' '}
					<strong style={{ color }}>
						{mean > 0 ? '+' : ''}
						{mean.toFixed(2)}%
					</strong>
					, <br />
					which is{' '}
					<strong>
						<span className="text_fake_smallcaps" style={{ color }}>
							{status.label}
						</span>
					</strong>
					.
				</>
			) : (
				lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD.BEANPLOT_TOOLTIP
					.No_Observations
			)}

			{!showDistribution(numObservations) && (
				<div
					style={{
						fontWeight: 100,
						marginTop: '1.3em'
					}}
				>
					{
						lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
							.BEANPLOT_TOOLTIP.No_Distribution
					}
				</div>
			)}

			{numObservations > 0 ? (
				<div
					style={{
						fontWeight: 100,
						marginTop: '1.3em',
						fontStyle: 'italic'
					}}
				>
					{
						lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
							.BEANPLOT_TOOLTIP.Click
					}
				</div>
			) : (
				<div
					style={{
						fontWeight: 100,
						marginTop: '1.3em',
						fontStyle: 'italic'
					}}
				>
					{
						lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
							.BEANPLOT_TOOLTIP.Adjust_Filters
					}
				</div>
			)}
		</div>
	);
};

export default BeanplotTooltip;
