/**
 * The root shared view of the application; shared between both server & client isomorphic versions.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { INavState } from '../nav/state';
import { AppState } from './state';

import Header from '../components/header';
import ValueTransition from '../components/valueTransition';
import SplashModal from '../components/splashModal';
import BeanfieldModal from '../components/beanfieldModal';
import DownloadModal from '../components/downloadModal';
import { BehaviorSubject, from, combineLatest, of } from 'rxjs';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { TData } from '../../../../preprocess/src/ts/exec';

import Colors from '../utils/color';
import Axios from 'axios';
import { Region } from '../components/region';
import { find, findIndex, memoize } from 'lodash';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useIORX } from '../stream/useIORX';
import AppConfig from '../app/config/AppConfig';
import getContextualLexicon from '../data/contextualLexicon';

interface IMainProps {
	nav?: INavState;
	store?: any;
}

export interface IRegionMeta {
	name: string;
	key: string;
}

const loadRegionData = memoize((regionKey: string) => {
	return Axios.get<TData>(
		`${AppConfig.assetPath}data/${regionKey}/payload.json?${AppConfig.cacheBreak}`
	).then((v) => v.data);
});

const dataIO = () => {
	const regionKey$ = new BehaviorSubject<string>('');
	const regions$ = from(
		Axios.get<IRegionMeta[]>(
			`${AppConfig.assetPath}data/regions.json?${AppConfig.cacheBreak}`
		).then((r) => r.data)
	);
	const out$ = combineLatest([
		regionKey$.pipe(distinctUntilChanged()),
		regions$
	]).pipe(
		switchMap(([regionKey, regions]) => {
			const region = find(regions, (r) => r.key === regionKey);
			if (region) {
				return from(
					loadRegionData(region.key).then((data) => ({
						regions,
						data
					}))
				);
			}
			return of({ regions, data: null as TData });
		})
	);
	return { out: out$, in: { regionKey$ } };
};

const LowestCommonAncestor: React.FC<IMainProps> = ({ nav, store }) => {
	const [isExplainModalOpen, _setIsExplainModalOpen] = useState(false);
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

	const [state, { regionKey$ }] = useIORX(
		{ regions: null, data: null },
		dataIO
	);
	const isSplashModalOpen = !state.data;

	useEffect(() => {
		// push region key into stream
		regionKey$.next(nav.Region);
		return () => {};
	}, [nav.Region]);
	const lexicon = getContextualLexicon(nav);

	const setIsExplainModalOpen = (value: boolean) => {
		//(window as any).GA('Info', 'Explain Modal', value ? 'open' : 'close');
		_setIsExplainModalOpen(value);
	};

	const [isSmallScreen, setIsSmallScreen] = useState(
		window.innerWidth < 1440
	);

	window.addEventListener('resize', () =>
		setIsSmallScreen(window.innerWidth < 1440)
	);

	return (
		<>
			{state.regions && (
				<Header regions={state.regions} regionKey={nav.Region || ''} />
			)}
			{state.data && (
				<Region
					{...{
						store,
						nav,
						DATA: state.data,
						isSmallScreen,
						isSplashModalOpen,
						setIsDownloadModalOpen,
						setIsExplainModalOpen
					}}
				/>
			)}
			<TransitionGroup>
				{!state.data && (
					<CSSTransition
						timeout={300}
						classNames={{
							enter: 'splash-transition-enter',
							enterActive: 'splash-transition-enter-active',
							exit: 'splash-transition-exit',
							exitActive: 'splash-transition-exit-active'
						}}
					>
						<SplashModal
							isOpen={isSplashModalOpen}
							regions={state.regions}
						/>
					</CSSTransition>
				)}
			</TransitionGroup>

			{isExplainModalOpen ? (
				<BeanfieldModal set={setIsExplainModalOpen} lexicon={lexicon} />
			) : (
				false
			)}
			{isDownloadModalOpen ? (
				<DownloadModal set={setIsDownloadModalOpen} lexicon={lexicon} />
			) : (
				false
			)}
		</>
	);
};

function mapStateToProps(state: AppState, ownProps: any) {
	return {
		nav: state.nav,
		store: ownProps.store
	};
}

export default connect(mapStateToProps)(LowestCommonAncestor as any);
