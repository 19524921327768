/**
 * Component for distribution in bean plots
 */

import React, { useEffect, useRef } from 'react';
import { TweenLite, Power2, Power3 } from 'gsap';

import { ScaleLinear } from 'd3-scale';
import PointsAnimator from '../utils/PointsAnimator';
import scaleInX from './fx/scaleInX';
import { partialRight } from 'lodash-es';
import reflectPoints from '../utils/reflectPoints';

function generatePolygonString(pts: [number, number][]) {
	return pts.map((p) => `${p[0].toFixed(3)},${p[1].toFixed(3)}`).join(' ');
}

// function mirrorPointsOnY(pts: [number, number][]) {
// 	let pts2 = pts.map(p => [-p[0], p[1]]);

// 	pts.reverse();
// 	return pts.concat(pts2 as any);
// }

const inFx = partialRight(scaleInX, {
	delay: 1,
	ease: Power3.easeOut,
	duration: 0.5
});

const AnimatedDistribution: React.FC<{
	density: [number, number][];
	x: ScaleLinear<number, number>;
	y: ScaleLinear<number, number>;
	svgProps?: React.SVGProps<SVGPolygonElement>;
}> = ({ density, x, y, svgProps }) => {
	const elRef = useRef(null as SVGPolygonElement);
	const animRef = useRef(null as PointsAnimator);

	useEffect(() => {
		inFx(elRef.current);
	}, []);

	useEffect(() => {
		let animator = animRef.current;
		const targetPts: [number, number][] = reflectPoints(
			density.map((pt) => [x(pt[1]), y(pt[0])]),
			0,
			true,
			true
		);

		if (!animator) {
			const poly = elRef.current;
			// initial points

			animRef.current = animator = new PointsAnimator(
				targetPts,
				(pts: [number, number][]) => {
					poly.setAttributeNS(
						null,
						'points',
						generatePolygonString(pts)
					);
				}
			);
			animator.applyCurrentPoints();
		} else {
			// interpolation animation
			animator.toNextPoints(targetPts, {
				ease: Power2.easeInOut,
				duration: 0.5
			});
			return () => animator.stop();
		}
	}, [density, y]);

	return (
		<polygon
			ref={elRef}
			style={{ transform: 'scale(0,1)' }}
			{...svgProps}
		/>
	);
};

export default AnimatedDistribution;
