/**
 * Utilities for working with Markdown Tables.
 */
import camelcase from 'camelcase';
import { fromPairs } from 'lodash-es';

/**
 * Convert a css string (e.g. `'text-align: right; font-weight: bold')`
 * to a JS style object (e.g. `{textAlign: 'right', fontWeight: 'bold'}`)
 */
function transformCSSStringToObject(cssStr: string) {
	const pairs = cssStr
		.split(';')
		.map((s) => {
			const pair = s.split(':');
			if (pair.length === 2) {
				return [camelcase(pair[0]), pair[1]].map((s) => s.trim());
			}
		})
		.filter((x) => !!x);
	if (pairs.length === 0) {
		return null;
	}
	const res = fromPairs(pairs);
	return res;
}

const transformTableAttributePairs = (attrs?: [string, string][]) =>
	attrs?.map((pair) => {
		switch (pair[0]) {
			case 'style':
				return [pair[0], transformCSSStringToObject(pair[1])];
			case 'colspan':
				return ['colSpan', pair[1]];
			case 'rowspan':
				return ['rowSpan', pair[1]];
			default:
				return pair;
		}
	});

/** NOTE: mutates tokens; that is how examples in markdown-it do it **/
const transformTableToken = (tokens, idx) => {
	const tok = tokens[idx];
	tok.attrs = transformTableAttributePairs(tok.attrs);
};

export const tableTokenRules = {
	table_open: [transformTableToken],
	th_open: [transformTableToken],
	tr_open: [transformTableToken],
	td_open: [transformTableToken]
};
