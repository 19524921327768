/**
 * Panel
 */

import React, { useRef } from 'react';

export const Panel = React.forwardRef(
	(
		{
			children,
			containerClass,
			contentClass,
			renderControls
		}: {
			renderControls?: { (): React.ReactNode };
			containerClass?: string;
			contentClass?: string;
			children: React.ReactNode;
		},
		ref: React.MutableRefObject<HTMLDivElement>
	) => {
		return (
			<div className={containerClass} ref={ref}>
				{renderControls?.()}
				<div className={contentClass}>{children}</div>
			</div>
		);
	}
);
