/**
 * A react markdown component that utilizes Markdown-It (same markdown engine used by PDF rendering).
 * @author brett@periscopic.com
 **/

import MarkdownIt from 'markdown-it';
import React from 'react';

import MarkdownStructuredText, {
	IMarkdownTreeNode,
	IMarkdownTreeTagNode
} from './MarkdownStructuredText';
import shallowequal from 'shallowequal';
import { isArray } from 'lodash-es';

interface ITagRenderers {
	[key: string]: {
		(
			nd: IMarkdownTreeTagNode,
			idx: number,
			children?: (string | JSX.Element)[]
		): string | JSX.Element;
	};
}

export interface IProps {
	component?:
		| React.ComponentClass<any>
		| React.StatelessComponent<any>
		| string;
	className?: string;
	onClick?: React.MouseEventHandler<any>;
	componentProps?: any;
	source: string | MarkdownStructuredText;
	renderers?: ITagRenderers; //grafter?: IReactMarkdownGrafter;
}

export default class ReactMarkdownText extends React.Component<IProps, any> {
	props: IProps;

	shouldComponentUpdate(nextProps: any, nextState: any) {
		if (nextProps.renderers) {
			return true;
		}

		return !shallowequal(nextProps, this.props);
	}

	render() {
		const {
			source,
			component,
			componentProps,
			renderers,
			className,
			onClick
		} = this.props;
		const txt =
			typeof source === 'string'
				? new MarkdownStructuredText(source)
				: source;
		const tree = txt.getTree();

		return React.createElement(
			// handle typing inadequacies
			((component || 'div') as any) as string,
			{ className, onClick, ...componentProps },
			ReactMarkdownText.renderTree(tree, { renderers })
		);
	}

	static renderTree(
		nodes: IMarkdownTreeNode[],
		{
			attrWhitelist = [] as string[],

			// a grafter allows insertion of non-markdown expressable
			// elements from within markdown
			renderers = null as ITagRenderers
		} = {}
	): (JSX.Element | string)[] {
		const renderNode = (nd: IMarkdownTreeNode, idx: number) => {
			if (typeof nd === 'string') {
				return nd;
			}

			let children = renderNodes(nd.children) || [];

			// use custom renderer for this tag
			if (renderers && renderers[nd.tag]) {
				return renderers[nd.tag](nd, idx, children);
			}

			const args = [nd.tag, { ...nd.attrs, key: idx }, ...children];

			return React.createElement.apply(null, args);
		};

		const renderNodes = (nds: IMarkdownTreeNode[]) => {
			if (!nds) {
				return;
			}

			return nds.map(renderNode);
		};

		return renderNodes(nodes);
	}
}
