/**
 * React hook for connecting to RXJS observables
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Observable } from 'rxjs';

export const useIORX = (() => {
	const ONCE = [];

	function isSource(v: Observable<any> | Object): v is Observable<any> {
		return typeof v === 'function';
	}

	function normalizeIO<T, U>(
		v: Observable<T> | { out: Observable<T>; in: U }
	): { out: Observable<T>; in: U } {
		if (isSource(v)) {
			return { out: v, in: null };
		}
		return v;
	}

	return function useIORX<T, U>(
		initialState: T,
		factory: { (val: T): Observable<T> | { out: Observable<T>; in: U } }
	): [T, U] {
		const [state, setState] = useState(initialState);
		const ref = useRef({ disposed: false });

		const { dispose, input } = useMemo(() => {
			const { out, in: input } = normalizeIO(factory(initialState));

			const subscription = out.subscribe((v) => {
				if (!ref.current.disposed) {
					setState(v as any);
				}
			});

			return {
				dispose: () => {
					ref.current.disposed = true;
					subscription.unsubscribe();
				},
				input
			};
		}, ONCE);

		useEffect(() => dispose, ONCE);

		return [state, input];
	};
})();
