/**
 * Utility for serializing and deserializing various data types.
 */

import { fromPairs } from 'lodash';

interface Dict<T> {
	[key: string]: T;
}

export default class Transcode {
	/**
	 * An encoder that converts a boolean value to a string.
	 *
	 * @param  {boolean} val
	 * @param  {Object} state Context
	 * @return {string}
	 */
	static encodeBoolean(val: boolean /*, state?: any*/) {
		return val ? '1' : '0';
	}

	/**
	 * An decoder that converts a string back to a boolean value.
	 *
	 * @param  {string} val
	 * @param  {Object} state Context
	 * @return {boolean}
	 */
	static decodeBoolean(val: string /*, state?: any*/) {
		return val === '1';
	}

	static decodeInt(val: string /*, state?: any*/): number {
		let num = parseInt(val, 10);

		if (!isNaN(num)) {
			return num;
		}

		// default
		return undefined;
	}

	static encodeInt(val: number /*, state?: any*/): string {
		if (val === undefined || val === null) {
			return '';
		}
		return val.toString();
	}

	static decodeString(val: string): string {
		return val;
	}

	static encodeString(val: string): string {
		if (val === undefined || val === null) {
			return '';
		}
		return val;
	}

	// TODO: figure out type constraints for enums
	// see: https://github.com/Microsoft/TypeScript/issues/2491
	static createEnumStringEncoder<ENM>(anEnum: any) {
		return function (val: any /*, state?: any*/): string {
			return anEnum[val] || '';
		};
	}

	static createEnumStringDecoder<ENM>(anEnum: any) {
		return function (val: string /*, state?: any*/): ENM {
			let num = anEnum[val];
			if (!isNaN(num) && anEnum[num] !== undefined) {
				return num as any as ENM;
			}

			// default
			return undefined;
		};
	}

	static createEnumIntEncoder<ENM>(anEnum: any) {
		return function (val: ENM /*, state?: any*/): string {
			if (val === undefined || val === null) {
				return '';
			}
			return (val as any as number).toString();
		};
	}

	static createEnumIntDecoder<ENM>(anEnum: any) {
		return function (val: string /*, state?: any*/): ENM {
			let num = Transcode.decodeInt(val /*, state*/);

			if (anEnum[num] !== undefined) {
				return num as any as ENM;
			}

			// default
			return undefined;
		};
	}

	static createChoicesEncoder<T>(choices: T[], encode: { (val: T): string }) {
		return function (val: any /*, state?: any*/): string {
			return encode(val);
		};
	}

	static createFilteredStringDecoder(predicate: { (val: string): boolean }) {
		return (val: string) => {
			return predicate(val) ? val : '';
		};
	}

	static createOptionsStringDecoder(
		options: string[],
		transform = (v: string) => v
	): (v: string) => string {
		const exists = fromPairs(options.map((s) => [s, true]));
		return (val: string) => {
			val = transform(val);
			return exists[val] ? val : '';
		};
	}

	static createIdListEncoder<T>(idEncode: { (item: T): string }) {
		return function (vals: T[]): string {
			return (vals || []).map(idEncode).join('-');
		};
	}

	static createIdListDecoder<T>(idDecode: { (id: string): T }) {
		return function (ids: string): T[] {
			return (ids || '')
				.split('-')
				.map(idDecode)
				.filter((v) => v !== undefined);
		};
	}

	static createBoolDictEncoder(
		keys: string[],
		{ defaultVal = false, encodeKey = (s: string) => s }
	) {
		return function (dict: Dict<boolean>) {
			return (
				keys
					.filter((key) => {
						return dict[key] !== defaultVal;
					})
					// remap keys (for example to external name)
					.map(encodeKey)
					// join into string
					.join('-')
			);
		};
	}

	static createBoolDictDecoder(
		keys: string[],
		{ defaultVal = false, decodeKey = (s: string) => s }
	) {
		return function (str: string) {
			// initialize dict
			let dict: Dict<boolean> = keys.reduce((memo, key) => {
				memo[key] = defaultVal;
				return memo;
			}, {});

			dict = (str || '')
				// get the input keys
				.split('-')
				// remap (for example to internal name)
				.map(decodeKey)
				// remove any that don't fit our original set
				.filter((key) => dict[key] !== undefined)
				// set remaining to correct val
				.reduce((memo, key) => {
					memo[key] = !defaultVal;
					return memo;
				}, dict);

			return dict;
		};
	}
}
