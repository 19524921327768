/**
 * Configuration of routing for a single property or layer.
 */
import { identity } from 'lodash-es';
import { Thenable } from '../asapPromiseInterfaces';

export const identityMount = <T>(val: any, storeState: T) => storeState;

type RouteEncoder<ValT, StoreStateT> = {
	(val: ValT, state?: StoreStateT): string;
};
type RouteDecoder<ValT, StoreStateT, ConfigStateT> = {
	(
		s: string,
		state?: StoreStateT,
		nextConfigState?: Partial<ConfigStateT>
	): ValT;
};
type RouteMounter<ValT, StoreStateT> = {
	(val: ValT, state?: StoreStateT): StoreStateT | Thenable<StoreStateT>;
};
type RouteStoreSetterFn<Data> = { (data: Data): void };
type RouteStoreGetterFn<StoreState extends Object, Data> = {
	(storeState: StoreState): Data;
};

export interface BaseRouteConfigNodeOpts<ValT, StoreStateT> {
	autoEscapeURI?: boolean;
	mountDecoded?: RouteMounter<ValT, StoreStateT>;
}

export interface CodeRouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT> {
	encode?: RouteEncoder<ValT, StoreStateT>;
	decode?: RouteDecoder<ValT, StoreStateT, ConfigStateT>;
}

export interface RouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT>
	extends BaseRouteConfigNodeOpts<ValT, StoreStateT>,
		CodeRouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT> {}

export class RouteConfigNode<ValT, StoreStateT, ConfigStateT> {
	public encode: RouteEncoder<ValT, StoreStateT>;
	public decode: RouteDecoder<ValT, StoreStateT, ConfigStateT>;
	public mountDecoded: RouteMounter<ValT, StoreStateT>;

	constructor(
		public fieldKey: string,
		opts: RouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT>
	) {
		this.mountDecoded = opts.mountDecoded || identityMount;

		let encode =
			opts.encode || (identity as RouteEncoder<ValT, StoreStateT>);

		let decode =
			opts.decode ||
			(identity as RouteDecoder<ValT, StoreStateT, ConfigStateT>);

		// should this be wrapped to encodeURIComponent
		// or will collision with '&/=' characters be handled within encode/decode
		if (opts.autoEscapeURI !== false) {
			this.encode = (t: ValT, state: StoreStateT): string => {
				const s = encode(t, state);
				// uri encode unless falsey and not a string
				// this allows exclusion of values from route
				// by pass `null` or `undefined`
				return s || s === '' ? encodeURIComponent(s) : s;
			};

			this.decode = (
				s: string,
				state: StoreStateT,
				nextConfigState: ConfigStateT
			): ValT => {
				return decode(decodeURIComponent(s), state, nextConfigState);
			};
		} else {
			//
			this.encode = encode;
			this.decode = decode;
		}
	}
}

export interface BasePropRouteConfigNodeOpts<ValT, StoreStateT>
	extends BaseRouteConfigNodeOpts<ValT, StoreStateT> {
	urlKey?: string;
}

export interface PropRouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT>
	extends BasePropRouteConfigNodeOpts<ValT, StoreStateT>,
		CodeRouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT> {}

export class PropRouteConfigNode<
	ValT,
	StoreStateT,
	ConfigStateT
> extends RouteConfigNode<ValT, StoreStateT, ConfigStateT> {
	public nsUrlKey: string;

	constructor(
		fieldKey: string,
		opts: PropRouteConfigNodeOpts<ValT, StoreStateT, ConfigStateT>,
		namespace: string
	) {
		super(fieldKey, opts);
		this.nsUrlKey =
			(namespace && namespace.length ? namespace + '-' : '') +
			(opts.urlKey || fieldKey);
	}
}
