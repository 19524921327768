/**
 * Utilities to transform deep links into dashboard state
 */

import * as _ from 'lodash';
import { INavState, SET } from '../../nav/state';
import { TData } from '../../../../../preprocess/src/ts/exec';
import { IFilterObject } from '../../components/filters';
import { keyBy } from 'lodash';

function normalizeFilterOption(o: string | { label: string; value: string }) {
	return typeof o === 'string' ? { label: o, value: o } : o;
}

export default function getFilterObjectsFromNavState(
	DATA: TData,
	nav: INavState,
	store
): IFilterObject[] {
	const { Practice, ChartRange, PracticeFilters } = nav;

	// DEEP LINK SETTERS
	const setChartRangeFilter = (ChartRange) => {
		store.dispatch({ type: SET, val: { ChartRange } });
	};

	const setPracticeFilters = (PracticeFilters) => {
		store.dispatch({ type: SET, val: { PracticeFilters } });
	};

	// CHART RANGE FILTER

	const chartRangeFilterObj = {
		label: 'Chart range',
		key: 'Chart range',
		highlight: false,
		type: 'radio',
		options: [
			{ value: '100', label: '100%' },
			{ value: '200', label: '200%' }
		]
	};

	const deserializedChartRangeFilter =
		ChartRange === '' ? [] : ChartRange.split('!');

	const chartFilterObjWithSetter: IFilterObject = {
		...chartRangeFilterObj,
		valueType: 'primitive',
		options: chartRangeFilterObj.options.map((opt, idx) => {
			const { value, label } = normalizeFilterOption(opt);
			let defaultActive = idx === 0;
			let active =
				deserializedChartRangeFilter.length === 0
					? defaultActive
					: deserializedChartRangeFilter[0] === value;

			return {
				value,
				label,
				active,
				defaultActive,
				set: () => {
					setChartRangeFilter(
						serializeFilter(
							updateRadio(
								[chartFilterObjWithSetter],
								'ChartRange',
								value,
								active
							)[0]
						)
					);
				}
			};
		})
	};

	// PRACTICE SPECIFIC FILTERS
	const rawPracticeFilter = DATA.filterOptions[Practice] ?? [];

	const deserializedPracticeFilters =
		PracticeFilters === ''
			? _.mapValues(
					keyBy(rawPracticeFilter, (f) => f.key),
					(value) => []
			  ) // _.mapValues(keyBy(rawPracticeFilter, f => f.key), (value) => [])
			: _.mapValues(
					_.fromPairs(
						PracticeFilters.split('_').map((pF) => pF.split('!'))
					),
					(value, key) => {
						return value.split('^');
					}
			  );

	const workingPracticeFilterArray: IFilterObject[] = _.flatten(
		rawPracticeFilter.map((individualPracticeFilter) => {
			const { label, key, highlight, valueType } =
				individualPracticeFilter;
			return {
				label,
				key,
				highlight: highlight ?? false,
				type: individualPracticeFilter.type,
				valueType,
				options: individualPracticeFilter.options.map((opt, idx) => {
					const { label, value } = normalizeFilterOption(opt);
					let defaultActive = false;
					let active = false;

					// Determine whether option is active using filter type and deserialized filter data
					switch (individualPracticeFilter.type) {
						case 'checkbox':
							defaultActive = true;
							active =
								_.indexOf(
									deserializedPracticeFilters[key],
									value
								) === -1
									? true
									: false;
							break;
						case 'radio':
							defaultActive =
								individualPracticeFilter.default === undefined
									? idx === 0
									: individualPracticeFilter.default ===
									  value;

							// if there is no active selection
							if (deserializedPracticeFilters[key].length === 0) {
								active = defaultActive;
							} else if (
								deserializedPracticeFilters[key][0] === value
							) {
								// if there is not an active selection
								active = true;
							}
							break;
						default:
							defaultActive = false;
							active = false;
							break;
					}

					return {
						value,
						label,
						active,
						defaultActive
					};
				})
			};
		})
	);

	const practiceFilterArrayWithSetters = workingPracticeFilterArray.map(
		(practiceFilter) => {
			let { label, key, type, valueType, highlight } = practiceFilter;
			let options = practiceFilter.options.map((option) => {
				let { value, label, active, defaultActive } = option;
				let set = () => undefined;
				// SET HANDLER FOR COMPONENT onChange EVENTS
				switch (type) {
					case 'checkbox':
						set = () =>
							setPracticeFilters(
								serializePracticeFilters(
									updateCheckbox(
										workingPracticeFilterArray,
										key,
										value,
										active
									)
								)
							);
						break;
					case 'radio':
						set = () =>
							setPracticeFilters(
								serializePracticeFilters(
									updateRadio(
										workingPracticeFilterArray,
										key,
										value,
										active
									)
								)
							);
						break;
					default:
						break;
				}
				return { value, label, active, defaultActive, set };
			});
			return { label, key, highlight, type, valueType, options };
		}
	);

	let serializeFilter = (filter: IFilterObject): string => {
		return filter.options
			.filter((o) => !o.active)
			.map((o) => o.value)
			.join('!');
	};

	let serializePracticeFilters = (
		practiceFilters: IFilterObject[]
	): String => {
		const serializedPracticeFilters = practiceFilters
			.map((filterObj) => {
				return [
					filterObj.key,
					filterObj.options
						.filter((o) =>
							filterObj.type === 'radio' ? o.active : !o.active
						)
						.map((o) => o.value)
						.join('^')
				].join('!');
			})
			.join('_');

		return serializedPracticeFilters;
	};

	let updateCheckbox = (
		filterArray: IFilterObject[],
		key: string,
		value: string,
		active: boolean
	): IFilterObject[] => {
		return filterArray.map((filterObj) => {
			if (filterObj.key === key) {
				return {
					...filterObj,
					options: filterObj.options.map((option) => {
						if (option.value === value) {
							return { ...option, active: !active };
						}
						return option;
					})
				};
			}
			return filterObj;
		});
	};

	let updateRadio = (
		filterArray: IFilterObject[],
		key: string,
		value: string,
		active: boolean
	): IFilterObject[] => {
		return filterArray.map((filterObj) => {
			if (filterObj.key === key) {
				return {
					...filterObj,
					options: filterObj.options.map((option) => {
						if (option.value === value && option.active === false) {
							return { ...option, active: true };
						}
						if (option.value === value && option.active === true) {
							return { ...option, active: true };
						} else {
							return { ...option, active: false };
						}
					})
				};
			}
			return filterObj;
		});
	};

	const isChartView = nav.GLIII === '' && nav.Practice !== '';
	if (isChartView) {
		return [chartFilterObjWithSetter, ...practiceFilterArrayWithSetters];
	} else {
		return practiceFilterArrayWithSetters;
	}
}
