/**
 * Component for demonstrating selection components for the nav.
 **/

import React from 'react';
import { INavState } from '../nav/state';
import { TData } from '../../../../preprocess/src/ts/exec';
import BreadCrumbTrail from './breadCrumbTrail';
import Filters, { IFilterObject } from './filters';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

interface IControlPanelProps {
	nav: INavState;
	store: any;
	DATA: TData;
	filteredObservationsByUID: number[];
	filterObjectArray: IFilterObject[];
	setIsDownloadModalOpen: (x: boolean) => void;
	setFiltersPanelOpen: (x: boolean) => void;
}

const ControlPanel: React.FC<IControlPanelProps> = ({
	DATA,
	nav,
	store,
	filteredObservationsByUID,
	filterObjectArray,
	setIsDownloadModalOpen,
	setFiltersPanelOpen
}) => (
	<div className={`control-panel expanded`}>
		<BreadCrumbTrail {...{ DATA, nav, store }} />
		<div className="text-grid">
			<div className="control-panel-text">
				{filterObjectArray.length > 0
					? 'Filter data prior to interpretation'
					: ''}
			</div>
			<Filters
				{...{
					DATA,
					nav,
					store,
					filteredObservationsByUID,
					filterObjectArray,
					setIsDownloadModalOpen,
					setFiltersPanelOpen
				}}
			/>
		</div>
	</div>
);

export default ControlPanel;
