/**
 * The core state of the application.
 */

import { combineReducers } from 'redux';
import { createTypedStore, OtherAction, IReducer } from '../router/redux/typed';

import { nav, NavStateAction, INavState } from '../nav/state';

// union of applicable action types
export type AppStateAction = OtherAction | NavStateAction;

export interface AppState {
	nav?: INavState;
}

const appReducer = combineReducers({ nav }) as IReducer<
	AppState,
	AppStateAction
>;

export class StoreVault {
	private static _store: any;

	static initStore(
		router: any,
		preloadedState: any,
		server: boolean = false
	) {
		if (this._store && !server) {
			throw 'StoreVault has already been initialized once';
		}
		this._store = createTypedStore(
			// only tie state to router if in browser
			server ? appReducer : router.wrapReducer(appReducer),
			preloadedState
		);
	}
	static getStore() {
		if (!this._store) {
			throw 'StoreVault has not been initialized';
		}
		return this._store;
	}
}
