/**
 * Component for wrapping children in react portal
 */

import ReactDOM from 'react-dom';
import React from 'react';

const Portal: React.FC<{ container: Element }> = ({ container, children }) => {
	return ReactDOM.createPortal(children, container);
};

export default Portal;
