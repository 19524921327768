/**
 * Utils for handling insight lookup
 */
import {
	IInsightConfig,
	InsightLink
} from '../../../../preprocess/src/ts/prepObservations';

export type InsightsNode<T> = { branches: InsightsBranch<T>; data?: T };
type InsightsBranch<T> = { [key: string]: InsightsNode<T> };
export interface IInsightData extends IInsightConfig {
	index: number;
}

export const makeInsightsTree = (cfgs: IInsightConfig[]) =>
	cfgs.reduce(
		(acc, cfg, cfgIdx) => {
			let data: IInsightData = { ...cfg, index: cfgIdx };

			return cfg.Links.reduce((acc2, link) => {
				let node = acc2;

				for (let value of link) {
					node = node.branches[value] = node.branches[value] || {
						branches: {}
					};
				}

				node.data = data;
				return acc2;
			}, acc);
		},
		{ branches: {} } as InsightsNode<IInsightData>
	);

export const findInInsightsTree = <T>(
	root: InsightsNode<T>,
	link: InsightLink
) => link.reduce((acc, key) => acc?.branches?.[key], root)?.data;
