/**
 * Methods Component
 */

import { flatten } from 'lodash';
import React, { useState } from 'react';
import { Lexicon } from '../data/contextualLexicon';
import ReactMarkdownText from './text/ReactMarkdownText';
import Measure from 'react-measure';

const Methods: React.FC<{ open: boolean; lexicon: Lexicon }> = ({
	open,
	lexicon
}) => {
	const [dimensions, setDimensions] = useState(null as DOMRectReadOnly);
	return (
		<>
			<div
				className="methods"
				style={{
					height: open && dimensions ? dimensions.height : 0,
					overflow: 'hidden',
					opacity: open ? 1 : 0,
					transition: 'height 0.3s ease-in, opacity 0.3s ease-in 0.2s'
				}}
			>
				{' '}
				<Measure
					bounds
					onResize={(contentRect) => {
						setDimensions(contentRect.bounds);
					}}
				>
					{({ measureRef }) => (
						<div ref={measureRef}>
							<ReactMarkdownText
								source={lexicon.METHODS.Title}
								className="text_title"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS.SEARCHING.Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								component="p"
								source={lexicon.METHODS.SEARCHING.Body}
								className="text_body"
							/>

							<ReactMarkdownText
								className="figure"
								source={lexicon.METHODS.TABLE_1}
							/>

							<ReactMarkdownText
								source={lexicon.METHODS.SELECTING.Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS.SELECTING.Body}
								className="text_body"
							/>

							<ReactMarkdownText
								className="figure"
								source={lexicon.METHODS.TABLE_2}
							/>

							<ReactMarkdownText
								source={lexicon.METHODS.EXTRACTING.Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS.EXTRACTING.Body}
								className="text_body"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS['P&V'].Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS['P&V'].Body}
								className="text_body"
							/>

							<ReactMarkdownText
								className="figure"
								source={lexicon.METHODS.TABLE_3}
							/>

							<ReactMarkdownText
								className="figure"
								source={lexicon.METHODS.TABLE_4}
							/>

							<ReactMarkdownText
								source={lexicon.METHODS['T&E'].Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS['T&E'].Body}
								className="text_body"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS.SOURCES.Title}
								className="text_heading"
							/>
							<ReactMarkdownText
								source={lexicon.METHODS.SOURCES.Body}
								className="text_body"
							/>
						</div>
					)}
				</Measure>
			</div>
		</>
	);
};

export default Methods;

const table1 =
	'|                   Grouping           || \n' +
	'| :- | :- | \n' +
	'| Geographic Location & Cropping Systems		|("Midwest* AND United States" OR "Midwest* AND U.S." OR "Midwest* AND US" OR "Illinois" OR "Indiana" OR "Iowa" OR "Kansas" OR "Michigan" OR "Minnesota" OR "Missouri" OR "North Dakota" OR "South Dakota" OR "Nebraska" OR "Ohio" OR "Wisconsin") AND ("corn" OR "maize" OR "soybean" OR "Zea mays" OR "Glycine max" OR "agricultur*" OR "agro-ecosystem*" OR "agroecosystem*" OR "crop" OR "field crop*" OR "cropping system" OR "farm*" OR "conservation agricult*") AND | \n' +
	'| Cover Crops | ("cover crop" OR "cover-crop*" OR "cover crop*") | \n' +
	'| Nutrient Management | ("variable rate" OR "precision agriculture" OR "precision AND (fertili*" OR "nitrogen" OR "phosphorus)" OR "band* fert*" OR "4R" OR "((enhance*" OR "efficien*) AND (nitrogen" OR "phosphorus))") | \n' +
	'| Pest Management | ("foliar AND insecticide*" OR "pesticide seed treatment*" OR "seed treatment*" OR "systemic insect*" OR "neonic*" OR "pyrethr*") | \n' +
	'| Tillage Management | ("conservation till*" OR "conventional till" OR "no-till" OR "no till*" OR "reduced till*" OR "minimum till*") | \n' +
	'[Table 1: Boolean search terms used to search peer reviewed studies published 1980-2017] \n';

const table2 =
	'| Search | Papers returned from initial search | Papers included in AgEvidence | \n' +
	'| :- | :-: | :-: | \n' +
	'| Cover crops | 351 | 58 | \n' +
	'| Tillage Management | 1130 | 176 | \n' +
	'| Pest Management | 135 | 26 | \n' +
	'| Nutrient Management | 738 | 25 | \n' +
	'[Table 2: Number of papers returned vs. included for each conservation agriculture management practice.] \n';

const table3 =
	'| Agricultural Practice | Control Options | \n' +
	'| :- | :- | \n' +
	'| Cover Crops | Winter Fallow | \n' +
	'| Early Season Pest Management | No Pesticide | \n' +
	'| Nutrient Management > Application Timing > Early Season | Pre-Plant | \n' +
	'| Nutrient Management > Application Timing > Split Application | Single Application | \n' +
	'| Nutrient Management > Application Timing > Spring | Fall | \n' +
	'| Nutrient Management > Fertilizer Placement | Broadcast | \n' +
	'| Conventional Tillage | Conservation Tillage, No Tillage, Zonal Tillage | \n' +
	'| Conservation Tillage | No Tillage, Zonal Tillage | \n' +
	'| Zonal Tillage | Conservation Tillage, No Tillage | \n' +
	'[Table 3: Controls for each practice in AgEvidence] \n';

const table4 =
	'| :- | :- | \n' +
	'| Conventional | Moldboard plow | \\\n' +
	'|  | Disc Plow | \\\n' +
	'|  | Deep ripper | \\\n' +
	'|  | Subsoil | \\\n' +
	'|  | Rotary tillage | \\\n' +
	'|  | Conventional tillage - rarely “conventional” was used, but the tillage implement was not described | \n' +
	'| Conservation | Chisel plow | \\\n' +
	'|  | Conservation  tillage - rarely “conservation” was used, but the tillage implement was not described | \\\n' +
	'|  | Field cultivator | \\\n' +
	'|  | Vertical tillage | \\\n' +
	'|  | Reduced tillage | \\\n' +
	'|  | Mulch tillage | \\\n' +
	'|  | Stubble mulch | \n' +
	'| Zonal        | Deep zonal tillage | \\\n' +
	'|  | Ridge till | \\\n' +
	'|  | Strip tillage | \n' +
	'| No-tillage   | Deep zonal tillage | \\\n' +
	'|  | Slot tillage | \\\n' +
	'|  | No tillage| \n' +
	'[Table 4: Types of tillage nested within each tillage category] \n';

const figureOne = {
	title: 'Table 1: Boolean search terms used to search peer reviewed studies published 1980-2017',
	rows: [
		[
			'Geographic Location & Cropping Systems',
			`("Midwest* AND United States" OR "Midwest* AND U.S." OR "Midwest* AND US" OR "Illinois" OR "Indiana" OR "Iowa" OR "Kansas" OR "Michigan" OR "Minnesota" OR "Missouri" OR "North Dakota" OR "South Dakota" OR "Nebraska" OR "Ohio" OR "Wisconsin") AND ("corn" OR "maize" OR "soybean" OR "Zea mays" OR "Glycine max" OR "agricultur*" OR "agro-ecosystem*" OR "agroecosystem*" OR "crop" OR "field crop*" OR "cropping system" OR "farm*" OR "conservation agricult*") AND`
		],
		['Cover Crops', `("cover crop" OR "cover-crop*" OR "cover crop*")`],
		[
			'Nutrient Management',
			`("variable rate" OR "precision agriculture" OR "precision AND (fertili*" OR "nitrogen" OR "phosphorus)" OR "band* fert*" OR "4R" OR "((enhance*" OR "efficien*) AND (nitrogen" OR "phosphorus))")`
		],
		[
			'Pest Management',
			`("foliar AND insecticide*" OR "pesticide seed treatment*" OR "seed treatment*" OR "systemic insect*" OR "neonic*" OR "pyrethr*")`
		],
		[
			'Tillage Management',
			`("conservation till*" OR "conventional till" OR "no-till" OR "no till*" OR "reduced till*" OR "minimum till*")
`
		]
	]
};

const figureTwo = {
	title: 'Table 2: Number of papers returned vs. included for each conservation agriculture management practice.',
	rows: [
		[
			'Search',
			'Papers returned from initial search',
			'Papers included in AgEvidence'
		],
		['Cover Crops', 351, 58],
		['Tillage Management', 1130, 176],
		['Pest Management', 135, 26],
		['Nutrient Management', 738, 25]
	]
};

const figureThree = {
	title: 'Table 3: Controls for each practice in AgEvidence',
	rows: [
		['Agricultural Practice', 'Control Options'],
		['Cover Crops', 'Winter Fallow'],
		['Early Season Pest Management', 'No Pesticide'],
		[
			'Nutrient Management > Application Timing > Early Season',
			'Pre-Plant'
		],
		[
			'Nutrient Management > Application Timing > Split Application',
			'Single Application'
		],
		['Nutrient Management > Application Timing > Spring', 'Fall'],
		['Nutrient Management > Fertilizer Placement', 'Broadcast'],
		[
			'Conventional Tillage',
			['Conservation Tillage', 'No Tillage', 'Zonal Tillage']
		],
		['Conservation Tillage', ['No Tillage', 'Zonal Tillage']],
		['Zonal Tillage', ['Conservation Tillage', 'No Tillage']]
	]
};

const figureFour = {
	title: 'Table 4: Types of tillage nested within each tillage category',
	rows: [
		[
			'Conventional',
			[
				'Moldboard plow',
				'Disc plow',
				'Deep ripper',
				'Subsoil',
				'Rotary tillage',
				'Conventional tillage - rarely “conventional” was used, but the tillage implement was not described'
			]
		],
		[
			'Conservation',
			[
				'Chisel plow',
				'Conservation  tillage - rarely “conservation” was used, but the tillage implement was not described',
				'Field cultivator',
				'Vertical tillage',
				'Reduced tillage',
				'Mulch tillage',
				'Stubble mulch'
			]
		],
		['Zonal', ['Deep zonal tillage', 'Ridge till', 'Strip tillage']],
		['No-tillage', ['Slot tillage', 'No tillage']]
	]
};
