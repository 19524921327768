/**
 * Region; the content associated with a specific region
 */

import React, { useEffect, useMemo, useState } from 'react';
import { IDashboardProps } from './contentAdapters';

import Dashboard from './dashboard';
import KeyInsights from './keyInsights';
import Footer from './footer';
import { TData } from '../../../../preprocess/src/ts/exec';
import { INavState } from '../nav/state';

export const Region: React.FC<{
	store: any;
	nav: INavState;
	DATA: TData;
	isSplashModalOpen: boolean;
	setIsExplainModalOpen: { (b: boolean): void };
	setIsDownloadModalOpen: { (b: boolean): void };
	isSmallScreen: boolean;
}> = ({
	store,
	nav,
	DATA,
	isSplashModalOpen,
	setIsExplainModalOpen,
	setIsDownloadModalOpen,
	isSmallScreen
}) => {
	const [openInsightIndex, setOpenInsightIndex] = useState(undefined);
	return (
		<>
			<Dashboard
				{...{
					store,
					nav,
					DATA,
					isSplashModalOpen,
					setIsExplainModalOpen,
					setIsDownloadModalOpen,
					isSmallScreen,
					setOpenInsightIndex
				}}
			/>
			<div style={{ borderTop: '1px solid #d1d1d1' }} />
			<KeyInsights
				regionKey={nav.Region}
				{...{ DATA, openInsightIndex, setOpenInsightIndex }}
			/>
			<Footer
				timestamp={DATA.timestamp}
				regionKey={DATA.key}
				share={DATA.share}
			/>
		</>
	);
};
