/**
 * Configures how a model is encoded-to/decoded-from the route url.
 */

import Transcode from '../Transcode';
import { defaults as _defaults } from 'lodash-es';
import {
	RouteConfigNode,
	PropRouteConfigNode,
	RouteConfigNodeOpts,
	BaseRouteConfigNodeOpts,
	PropRouteConfigNodeOpts
} from './RouteConfigNode';
import RouteConfigCore from './RouteConfigCore';
import { RouteDecoder } from './RouteDecoder';
import RouteEncoder from './RouteEncoder';

export interface IEncodedProps {
	[key: string]: string[];
}

export interface IDecodeOpts {
	prepare: boolean;
}

export interface RouteConfigOpts<StoreStateT, DataT> {
	reducerKey?: string;
	urlNamespace?: string;

	// notes if any default values should actually be rendered
	// to url
	explicitDefaults?: { [key: string]: boolean };
}

export default class RouteConfig<StoreStateT, DataT> {
	private core: RouteConfigCore<StoreStateT, DataT>;

	constructor(
		defaults: DataT,
		opts: RouteConfigOpts<StoreStateT, DataT> = {}
	) {
		this.core = new RouteConfigCore(defaults, opts);
	}

	/**
	 * Set the defaults used by this config. Useful if the defaults
	 * need to change, for example because has loaded.
	 **/
	setDefaults(defaults: DataT) {
		this.core.defaults = defaults;
		// invalidate
		this.core._applicableDefaults = null;
	}

	getDefaults() {
		return this.core.defaults;
	}

	/**
	 * Add a hierarchical layer to the configuration
	 *
	 * NOTE: If you need to encode an empty string (i.e. ''),
	 * that must be done using a `property`, not a `layer` since
	 * an empty string is ambiguous with the default value in layers.
	 */
	addLayer<ValT>(
		key: string,
		opts: RouteConfigNodeOpts<ValT, any, DataT> = {}
	) {
		const { core } = this;
		if (core.brancher) {
			throw 'RouteConfig layers already have been sealed by adding brancher';
		}

		core.layers.push(new RouteConfigNode(key, opts));

		// invalidate
		core._applicableDefaults = null;

		return this;
	}

	addStringLayer(
		key: string,
		opts: BaseRouteConfigNodeOpts<string, StoreStateT> = {}
	) {
		return this.addLayer(key, {
			...opts,
			encode: Transcode.encodeString,
			decode: Transcode.decodeString
		});
	}

	addBoolLayer(
		key: string,
		opts: BaseRouteConfigNodeOpts<boolean, StoreStateT> = {}
	) {
		return this.addLayer(key, {
			...opts,
			encode: Transcode.encodeBoolean,
			decode: Transcode.decodeBoolean
		});
	}

	addIntLayer(
		key: string,
		opts: BaseRouteConfigNodeOpts<number, StoreStateT> = {}
	) {
		return this.addLayer(key, {
			...opts,
			encode: Transcode.encodeInt,
			decode: Transcode.decodeInt
		});
	}

	addEnumStringLayer<ENM>(
		anEnum: any,
		key: string,
		opts: BaseRouteConfigNodeOpts<ENM, StoreStateT> = {}
	) {
		return this.addLayer(key, {
			...opts,
			encode: Transcode.createEnumStringEncoder<ENM>(anEnum),
			decode: Transcode.createEnumStringDecoder<ENM>(anEnum)
		});
	}

	addEnumIntLayer<ENM>(
		anEnum: any,
		key: string,
		opts: BaseRouteConfigNodeOpts<ENM, StoreStateT> = {}
	) {
		return this.addLayer(key, {
			...opts,
			encode: Transcode.createEnumIntEncoder<ENM>(anEnum),
			decode: Transcode.createEnumIntDecoder<ENM>(anEnum)
		});
	}

	addBrancher(brancher: { (lastLayerVal: any): RouteConfig<any, any> }) {
		if (this.core.brancher) {
			throw 'RouteConfig layers already have been sealed by adding brancher';
		}

		// add brancher as a function composition that gets the core
		// rather than the config. This allows us to maintain the
		// internal / external boundary and keep 'core' completely internal
		// and behind 'private'
		this.core.brancher = (lastLayerVal: any) => {
			const config = brancher(lastLayerVal);
			return config && config.core;
		};

		return this;
	}

	/**
	 * Add a property to the configuration
	 *
	 * @param {string} key The key on the model for the property
	 * @param {function(any):string} [encode] Takes the source value and encodes it to a string (such as an ID). If not included, this is is passthrough method  - this should only be used when the source value is a string.
	 * @param {function(string):any} [decode] Takes a string (such as an ID) and decodes it to its corresponding value. If not included this is a pass through method - this should only be used when the source value is a string.
	 * @param {string} [urlKey] The key in the url corresponding to this property. If not included, it will be the same as `key`.
	 * @return {app.control.RouteConfig} this
	 */
	addProperty<ValT>(
		key: string,
		opts: PropRouteConfigNodeOpts<ValT, any, DataT> = {}
	) {
		const { core } = this;
		this.core.properties.push(
			new PropRouteConfigNode(key, opts, this.core.namespace)
		);

		// invalidate
		this.core._applicableDefaults = null;

		return this;
	}

	addStringProperty(
		key: string,
		opts: PropRouteConfigNodeOpts<string, any, DataT> = {}
	) {
		return this.addProperty<string>(key, {
			...opts,
			encode: Transcode.encodeString,
			decode: Transcode.decodeString
		});
	}

	addBoolProperty(
		key: string,
		opts: PropRouteConfigNodeOpts<boolean, any, DataT> = {}
	) {
		return this.addProperty<boolean>(key, {
			...opts,
			encode: Transcode.encodeBoolean,
			decode: Transcode.decodeBoolean
		});
	}

	addIntProperty(
		key: string,
		opts: PropRouteConfigNodeOpts<number, any, DataT> = {}
	) {
		return this.addProperty<number>(key, {
			...opts,
			encode: Transcode.encodeInt,
			decode: Transcode.decodeInt
		});
	}

	addEnumStringProperty<ENM>(
		anEnum: any,
		key: string,
		opts: PropRouteConfigNodeOpts<ENM, any, DataT> = {}
	) {
		return this.addProperty(key, {
			...opts,
			encode: Transcode.createEnumStringEncoder<ENM>(anEnum),
			decode: Transcode.createEnumStringDecoder<ENM>(anEnum)
		});
	}

	addEnumIntProperty<ENM>(
		anEnum: any,
		key: string,
		opts: PropRouteConfigNodeOpts<ENM, any, DataT> = {}
	) {
		return this.addProperty(key, {
			...opts,
			encode: Transcode.createEnumIntEncoder<ENM>(anEnum),
			decode: Transcode.createEnumIntDecoder<ENM>(anEnum)
		});
	}

	/**
	 * Encode the corresponding model
	 *
	 * @return {Object<string, Array<string>>}
	 */
	encode(storeState: StoreStateT): IEncodedProps {
		return RouteEncoder.projectAndRecursiveEncode(
			this.core,
			storeState,
			{ vals: [], tempVals: [] },
			{},
			false
		);
	}

	/**
	 * Decode an object with key/values corresponding to the URL into model properties, using the current state as context.
	 */
	decode = (
		urlProps: IEncodedProps,
		storeState: StoreStateT,
		targetState: StoreStateT,
		opts: IDecodeOpts
	) => {
		return RouteDecoder.recursiveDecode(
			this.core,
			urlProps[this.core.namespace],
			urlProps,
			storeState,
			targetState,
			opts
		);
	};
}
