/**
 * Top level declaration of application routing.
 */

import { registerNavRoutes } from '../nav/state';
import StatelessRouterSetup from './redux/StatelessRouterSetup';
import { AppState } from '../app/state';

const routesRegistryArray = [registerNavRoutes];

let theRouter: StatelessRouterSetup<AppState>;

const registerRoutes = function (router: StatelessRouterSetup<AppState>) {
	theRouter = router;
	routesRegistryArray.forEach((register) => register(router));
};

export function getRouter() {
	return theRouter;
}

export default registerRoutes;
