/**
 * Google Analytics dispatch based on global availability
 *
 */

import { compact, fromPairs, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import RouterSetupHelpers from '../../router/redux/RouterSetupHelpers';
import AppConfig from '../config/AppConfig';
import { AppState, StoreVault } from '../state';

export const analyticsCategories = {
	LANDING_PAGE: 'landing-page',
	HEADER: 'header',
	CONTEXT_PANEL: 'context-panel',
	DOWNLOADS_MENU: 'downloads-menu',
	INSIGHT_SUMMARIES: 'insight-summaries',
	STAGE: 'stage',
	BREADCRUMBS: 'breadcrumbs',
	FOOTER: 'footer',
	FILTERS: 'filters'
};

// global gtag.js
declare var gtag: { (...args: any[]): void };

const sendToGTag = gtag;
/* const sendToGTag = (...args: any[]) =>{
	console.log('gtag', args);
	gtag.apply(null, args);
} */

export function setupAnalyticsHandlers() {
	if (!AppConfig.analyticsId) {
		console.warn('No analyticsId configured in AppConfig');
	}
	setupCustomDimensions();
	setupPageViewAnalytics();
}

type dimensionKeys =
	| 'region_id'
	| 'practice_name'
	| 'outcome_name'
	| 'context_type'
	| 'download_type'
	| 'insight_name'
	| 'filter_name'
	| 'share_type';
function setupCustomDimensions() {
	const dimensions: { [key: string]: dimensionKeys } = {
		// for categoric value
		dimension1: 'region_id',
		dimension2: 'practice_name',
		dimension3: 'outcome_name',
		dimension4: 'context_type',
		dimension5: 'download_type',
		dimension6: 'insight_name',
		dimension7: 'filter_name',
		dimension8: 'share_type'
	};

	sendToGTag('config', AppConfig.analyticsId, {
		custom_map: dimensions
	});
}

function setupPageViewAnalytics() {
	function makePathStream() {
		const path$ = new Subject<string>();
		const history = RouterSetupHelpers.getHistory();
		history.listen((update) => path$.next(update.location.pathname));

		return path$.pipe(
			startWith(
				// initial path name
				window.location.hash.split('&')[0].replace('#', '')
			),
			distinctUntilChanged()
		);
	}
	const path$ = makePathStream();

	const page$ = path$.pipe(
		map((path) => (path ?? '').split('&')[0]),
		distinctUntilChanged()
	);
	return page$.subscribe((page_path: string) => {
		sendToGTag('event', 'page_view', { page_path });
	});
}

interface IAnalyticDataPoint {
	key: dimensionKeys;
	value: string;
}

function getCurrentDataPoints() {
	const { nav } = StoreVault.getStore().getState() as AppState;
	const pts: IAnalyticDataPoint[] = [
		{ key: 'region_id', value: nav.Region },
		{ key: 'practice_name', value: nav.Practice },
		{ key: 'outcome_name', value: nav.Outcome }
	];

	return pts.filter((v) => !!v.value).map(({ key, value }) => [key, value]);
}

export function sendAnalyticsUIInteraction(
	action: string,
	event_category: string,
	event_label: string,
	...dataPoints: IAnalyticDataPoint[]
) {
	const pairs = compact(dataPoints).map(({ key, value }) => [key, value]);

	sendToGTag('event', action, {
		event_category,
		event_label,
		...fromPairs(getCurrentDataPoints()),
		...fromPairs(pairs)
	});
}
