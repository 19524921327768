/**
 * Utilities to transform deep links into dashboard state
 */

import * as _ from 'lodash';
import { INavState } from '../../nav/state';
import { TData } from '../../../../../preprocess/src/ts/exec';
import { IFilterObject } from '../../components/filters';

import getFilterObjectsFromNavState from './createFilterObjects';
import getObservationsFromNavState from './pruneAndFilterObservations';

export default function deepStatePipeline(DATA: TData, nav: INavState, store) {
	const filterObjectArray: IFilterObject[] = getFilterObjectsFromNavState(
		DATA,
		nav,
		store
	);

	const {
		filteredObservationsByUID,
		filteredStudiesAndLocations
	} = getObservationsFromNavState(DATA, nav, filterObjectArray);

	return {
		filterObjectArray,
		filteredObservationsByUID,
		filteredStudiesAndLocations
	};
}
