/**
 * Norms
 */

import Colors from './color';

export const norms = {
	negative: 0,
	positive: 2,
	'assumed societal benefit': 2,
	'assumed societal harm': 0,
	'dependent on other factors': 1
};

const normStatuses = [
	{
		label: 'Unfavorable',
		colors: {
			dark: Colors.unfavorableDark,
			light: Colors.unfavorablelight
		}
	},
	{
		label: 'Indeterminate',
		colors: {
			dark: Colors.indeterminateDark,
			light: Colors.indeterminateLight
		}
	},
	{
		label: 'Favorable',
		colors: {
			dark: Colors.favorableDark,
			light: Colors.favorableLight
		}
	}
];

export const normColors = normStatuses.map(n => n.colors.light);

export const normMeanColors = normStatuses.map(n => n.colors.dark);

export function meanToNormStatusIndex(mean: number, norm: string) {
	let idx = norms[norm.toLowerCase()];

	if (Math.sign(mean) === -1) {
		idx = normStatuses.length - (idx + 1);
	}
	if (mean === 0) {
		idx = 1;
	}
	return idx;
}

export function meanToNormStatus(mean: number, norm: string) {
	return normStatuses[meanToNormStatusIndex(mean, norm)];
}

export function meanToNormColor(mean: number, norm: string) {
	return `${normMeanColors[meanToNormStatusIndex(mean, norm)]}`;
}
