/**
 * Component for reference lines in bean plots
 */

import React, { useMemo } from 'react';
import { ScaleLinear } from 'd3-scale';
import AnimatedPoints from './AnimatedPoints';
import Colors from '../utils/color';

const AnimatedPlotLabels: React.FC<{
	height: number;
	scaleY: ScaleLinear<number, number>;

	labels: { text: string; point: [number, number]; dashed: boolean }[];
}> = ({ scaleY, height, labels }) => {
	function updatePoint(el: HTMLDivElement, pt: [number, number]) {
		el.style.transform = `translate(0, ${pt[1].toFixed(3)}px)`;
	}

	function renderPoint(pt: [number, number], idx: number) {
		return (
			<div
				key={idx}
				style={{
					position: 'absolute',
					top: 'calc(50% - 7px)',
					right: '0%'
				}}
			>
				{labels[idx].dashed ? (
					labels[idx].text
				) : (
					<div
						className="control-label"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							textAlign: 'right'
						}}
					>
						<span>No Change</span>
						{labels[idx].text && (
							<span
								style={{
									color: Colors.mediumGray,
									fontSize: '10px'
								}}
							>
								({labels[idx].text})
							</span>
						)}
					</div>
				)}
			</div>
		);
	}
	const points = useMemo(() => labels.map(l => l.point), [labels]);

	return (
		<div
			className="plot-labels text_data-label"
			style={{ height, overflow: 'hidden', position: 'relative' }}
		>
			<AnimatedPoints
				{...{
					component: 'div',
					y: scaleY,
					points,
					updatePoint,
					renderPoint
				}}
			/>
		</div>
	);
};

export default AnimatedPlotLabels;
