/* tslint:disable:max-file-line-count */
/**
 * Component for demonstrating selection components for the nav.
 */

import React, { useState, useEffect, useMemo } from 'react';
import { INavState, SET, DEFAULTS } from '../nav/state';
import { ICounts, TData } from '../../../../preprocess/src/ts/exec';
import { interpolateRgb } from 'd3-interpolate';
import { scaleSymlog } from 'd3-scale';
import Colors from '../utils/color';
import { max, map, get, delay, flatten, omit, uniq } from 'lodash-es';

import { CSSTransition } from 'react-transition-group';
import Legend from './legend';
import ReactTooltip from 'react-tooltip';
import { HeatmapTooltip, DefaultTooltipProps } from './tooltips';
import Portal from './Portal';
import getContextualLexicon from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

// Create log scale for heatmap & legend key color interpolation
const i = interpolateRgb(Colors.heatmapLight, Colors.heatmapDark);

export function makeHeatmapScale(counts: ICounts) {
	let maxObservationCount = max(
		flatten(
			map(counts.sub, (practice) =>
				map(practice, (outcome) => outcome.observations)
			)
		)
	);

	const scale = scaleSymlog().domain([1, maxObservationCount]).range([0, 1]);
	const scaleObservationCount = (count: number) => {
		return scale(count);
	};
	const legendNums = [
		1,
		Math.round(scale.invert(0.5)),
		maxObservationCount
	].map((x) => x.toLocaleString());

	return { scaleObservationCount, legendNums };
}

interface IHeatmapProps {
	nav: INavState;
	store: any;
	DATA: TData;
	hover?: boolean;
	isSmallScreen?: boolean;
}

const Heatmap: React.FC<IHeatmapProps> = (props) => {
	const { DATA, nav, store, isSmallScreen } = props;
	const lexicon = getContextualLexicon(nav);

	const setPracticeAndOutcome = (Practice, Outcome) => {
		store.dispatch({
			type: SET,
			val: { ...omit(DEFAULTS, 'Region'), Practice, Outcome }
		});
	};

	const { scaleObservationCount } = useMemo(
		() => makeHeatmapScale(DATA.counts),
		[DATA]
	);

	const [activeHeatmapCell, setActiveHeatmapCell] = useState(
		undefined as {
			p: string;
			o: string;
		}
	);

	const { PracticeList, OutcomeList } = useMemo(
		() => getPracticeOutcomeLists(DATA),
		[DATA]
	);

	return (
		<div style={{ position: 'relative' }}>
			<div className="heatmap--wrap">
				<div className="heatmap--column-labels">
					{
						// outcomes
						OutcomeList.map((o, OutcomeIdx) => (
							<div key={OutcomeIdx} className="label-cell">
								{o}
							</div>
						))
					}
				</div>
				<div className="heatmap--row-labels">
					{
						// practices
						PracticeList.map((p, PracticeIdx) => (
							<div key={PracticeIdx} className="label-cell">
								{p}
							</div>
						))
					}
				</div>

				<GenericHeatmap
					{...props}
					className="heatmap--main"
					renderCell={({ DATA, o, OutcomeIdx, p, PracticeIdx }) => (
						<MainHeatmapOutcomeCell
							{...{
								key: OutcomeIdx,
								DATA,
								p,
								PracticeIdx,
								o,
								OutcomeIdx,
								setPracticeAndOutcome,
								setActiveHeatmapCell,
								scaleObservationCount
							}}
						/>
					)}
				/>
			</div>

			<Legend bean={false} norm={false} DATA={DATA} lexicon={lexicon} />

			<Portal container={document.body}>
				{activeHeatmapCell && (
					<ReactTooltip
						id="heatmap-tooltip"
						{...{
							...DefaultTooltipProps,
							effect: 'float',
							offset: { top: '25%' as any, right: 5 },
							place: 'right'
						}}
						delayHide={300}
					>
						<HeatmapTooltip {...{ activeHeatmapCell }} />
					</ReactTooltip>
				)}
			</Portal>
		</div>
	);
};

export default Heatmap;

interface ICellAddress {
	p: string; // practice name/key
	o: string; // outcome name/key
}
interface ICell extends ICellAddress {
	PracticeIdx: number;
	OutcomeIdx: number;
}

let MainHeatmapOutcomeCell = ({
	DATA,
	p,
	PracticeIdx,
	o,
	OutcomeIdx,
	setPracticeAndOutcome,
	setActiveHeatmapCell,
	scaleObservationCount
}: { DATA: TData } & ICell & {
		setPracticeAndOutcome: { (p: string, o: string): any };
		setActiveHeatmapCell: { (a: ICellAddress): any };
		scaleObservationCount: { (v: number): number };
	}) => {
	{
		const hasData = get(DATA.practicesAndOutcomesTree, [p, 'groups', o]);

		let studyCount = hasData ? DATA.counts.sub[p][o].studies : 0;

		let observationCount = hasData ? DATA.counts.sub[p][o].observations : 0;

		let [hover, setHover] = useState(false);
		let [inProp, setInProp] = useState(false);

		useEffect(() => {
			delay(() => setInProp(true), 30);
		}, []);

		return (
			<CSSTransition
				in={inProp}
				timeout={300}
				classNames="heatmap-transition"
			>
				<div
					className="cell invisible"
					data-tip
					data-for="heatmap-tooltip"
					key={OutcomeIdx}
					style={{
						color: 'black',
						transition: `opacity ${
							(Math.pow((PracticeIdx + 1) * (OutcomeIdx + 1), 2) *
								300) /
							((5 * PracticeIdx + 1) * (OutcomeIdx + 1))
						}ms ease-in 0.3s`
					}}
				>
					<div
						className="h-w-fill hot-cell"
						style={{
							textAlign: 'right',
							backgroundColor: hasData
								? i(scaleObservationCount(observationCount))
								: Colors.white,
							border: hasData
								? hover
									? `1px solid ${Colors.clover}`
									: '1px solid transparent'
								: `1px solid ${Colors.lightGray}`,
							cursor: hasData ? 'pointer' : 'default'
						}}
						onClick={() => {
							if (hasData) {
								sendAnalyticsUIInteraction(
									'SELECT',
									analyticsCategories.STAGE,
									'heatmap-cell',
									{ key: 'practice_name', value: p },
									{ key: 'outcome_name', value: o }
								);
								setPracticeAndOutcome(p, o);
							}
						}}
						onMouseEnter={() => {
							setHover(true);
							setActiveHeatmapCell(
								hasData ? { p, o } : undefined
							);
						}}
						onMouseLeave={() => {
							setHover(false);
							setActiveHeatmapCell(undefined);
						}}
					>
						{hasData && (
							<React.Fragment>
								<div>{`${observationCount.toLocaleString()}`}</div>
								<div
									className={`content ${
										hover ? 'hover' : ''
									}`}
								>
									{hover && (
										<React.Fragment>
											<div className="text_matrix-label">
												{`${
													observationCount === 1
														? 'observation'
														: 'observations'
												}`}
											</div>
											<div>
												<span className="text_matrix-label">{` in `}</span>
												{studyCount}
												<span className="text_matrix-label">{` ${
													studyCount === 1
														? 'study'
														: 'studies'
												}`}</span>
											</div>
										</React.Fragment>
									)}
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</CSSTransition>
		);
	}
};

function getPracticeOutcomeLists(DATA: TData) {
	return {
		PracticeList: Object.keys(DATA.practicesAndOutcomesTree).sort(),
		OutcomeList: uniq(
			flatten(
				map(DATA.practicesAndOutcomesTree, (p) => Object.keys(p.groups))
			)
		).sort()
	};
}

export const GenericHeatmap: React.FC<
	IHeatmapProps & {
		renderCell: { (props: { DATA: TData } & ICell): React.ReactElement };
		onClick?: React.MouseEventHandler;
		className?: string;
	}
> = ({ DATA, nav, hover, onClick, className, renderCell }) => {
	const { PracticeList, OutcomeList } = getPracticeOutcomeLists(DATA);

	return (
		<div
			className={`heatmap ${className} ${hover ? 'hover' : ''}`}
			onClick={onClick}
		>
			{PracticeList.map((p, PracticeIdx) => {
				return (
					<div className="practice-row" key={PracticeIdx}>
						{OutcomeList.map((o, OutcomeIdx) =>
							renderCell({
								DATA,
								o,
								OutcomeIdx: OutcomeIdx,
								p,
								PracticeIdx: PracticeIdx
							})
						)}
					</div>
				);
			})}
		</div>
	);
};

export class SmallHeatmap extends React.PureComponent<IHeatmapProps, any> {
	resetPracticeAndOutcome = () => {
		sendAnalyticsUIInteraction(
			'SELECT',
			analyticsCategories.BREADCRUMBS,
			'heatmap'
		);

		this.props.store.dispatch({
			type: SET,
			val: omit(DEFAULTS, 'Region')
		});
	};
	render() {
		const { DATA, nav } = this.props;
		const { Practice, Outcome } = nav;

		return (
			<GenericHeatmap
				{...this.props}
				renderCell={({ o, p, OutcomeIdx }) => {
					const hasData = get(DATA.practicesAndOutcomesTree, [
						p,
						'groups',
						o
					]);

					const isSelected = Outcome === o && Practice === p;

					return (
						<div
							key={OutcomeIdx}
							className={` cell ${isSelected ? 'selected' : ''} ${
								hasData ? 'has-data' : ''
							}`}
						/>
					);
				}}
				onClick={this.resetPracticeAndOutcome}
				className={'heatmap--small'}
			/>
		);
	}
}

export const MediumHeatmap: React.FC<IHeatmapProps> = (props) => {
	const { DATA, nav, store } = props;
	const { Practice, Outcome } = nav;

	const { scaleObservationCount } = useMemo(
		() => makeHeatmapScale(DATA.counts),
		[DATA]
	);

	const setPracticeAndOutcome = (Practice, Outcome) => {
		store.dispatch({
			type: SET,
			val: { ...omit(DEFAULTS, 'Region'), Practice, Outcome }
		});
	};

	return (
		<GenericHeatmap
			{...props}
			renderCell={({ o, p, OutcomeIdx }) => {
				const hasData = get(DATA.practicesAndOutcomesTree, [
					p,
					'groups',
					o
				]);

				let observationCount = hasData
					? DATA.counts.sub[p][o].observations
					: 0;

				const isSelected = Outcome === o && Practice === p;

				return (
					<div
						className={`cell ${isSelected ? 'selected' : ''} ${
							hasData ? 'has-data' : ''
						}`}
						key={OutcomeIdx}
						style={{
							color: 'black',
							backgroundColor: hasData
								? i(scaleObservationCount(observationCount))
								: Colors.white
						}}
						onClick={() => {
							if (hasData) {
								sendAnalyticsUIInteraction(
									'SELECT',
									analyticsCategories.BREADCRUMBS,
									'heatmap-cell',
									{ key: 'practice_name', value: p },
									{ key: 'outcome_name', value: o }
								);
								setPracticeAndOutcome(p, o);
							}
						}}
					>
						{hasData && (
							<div className="h-w-fill">
								<div className="p text_breadcrumb-label">
									{p.replace('Management', 'Mgmt')}
								</div>
								<div className="o text_breadcrumb-label">
									{o}
								</div>
							</div>
						)}
					</div>
				);
			}}
			className={'heatmap--medium'}
		/>
	);
};
