/**
 * Component for reference lines in bean plots
 */

import React, { useMemo } from 'react';
import { ScaleLinear } from 'd3-scale';
import AnimatedPoints from './AnimatedPoints';

const AnimatedRefLines: React.FC<{
	y: ScaleLinear<number, number>;
	width: number;
	refLines: { point: [number, number]; dashed: boolean }[];
}> = ({ y, width, refLines }) => {
	function updatePoint(line: SVGLineElement, pt: [number, number]) {
		line.setAttributeNS(
			null,
			'transform',
			`translate(0 ${pt[1].toFixed(3)}) scale(${pt[0].toFixed(3)} 1)`
		);
	}

	function renderPoint(pt: [number, number], idx: number) {
		return (
			<line
				x1={-width / 2}
				x2={width / 2}
				y1={0}
				y2={0}
				key={idx}
				stroke="#fff"
				strokeWidth={1}
				strokeDasharray={refLines[idx]?.dashed ? '4 5' : ''}
				transform={`translate(0 0) scale(0 1)`}
			/>
		);
	}

	const points = useMemo(() => refLines.map(l => l.point), [refLines]);

	return (
		<AnimatedPoints
			{...{ component: 'g', y, points, updatePoint, renderPoint }}
		/>
	);
};

export default AnimatedRefLines;
