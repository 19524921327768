/**
 * Filters Component
 */

import React, { useMemo, useState } from 'react';
import { INavState } from '../nav/state';
import { map, omit } from 'lodash-es';

let md5 = require('md5');
const { parse } = require('json2csv');
import FileSaver from 'file-saver';
import axios from 'axios';

import OutsideClickHandler from 'react-outside-click-handler';
import { CSSTransition } from 'react-transition-group';
import { TData } from '../../../../preprocess/src/ts/exec';
import getContextualLexicon, { Lexicon } from '../data/contextualLexicon';
import AppConfig from '../app/config/AppConfig';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

// Capture
var API_URL = 'https://cdn.capture.techulus.in/';
var your_api_key = 'e2faba90-2041-4df2-8bba-d94e66fd62cd';
var your_api_secret = '0d0ad244-d2a4-47a7-a05a-12caec9a4121';

const Downloads: React.FC<{
	DATA: TData;
	filteredObservationsByUID: number[];
	nav: INavState;
	setIsDownloadModalOpen: (x: boolean) => void;
}> = ({ DATA, filteredObservationsByUID, nav, setIsDownloadModalOpen }) => {
	let { Practice, GLIII, Studies } = nav;

	let [open, setOpen] = useState(false);
	const lexicon = getContextualLexicon(nav);

	// Target URL
	let input_url = encodeURIComponent((window as any).location);
	let params =
		's3Redirect=true&fresh=true&waitForId=map-loaded&vh=830&top=65';
	let hash = md5(your_api_secret + params + '&url=' + input_url);

	// Image URL
	let result_img_url =
		API_URL +
		your_api_key +
		'/' +
		hash +
		'/image?' +
		params +
		'&url=' +
		input_url;

	return (
		<div className="download">
			<OutsideClickHandler onOutsideClick={() => setOpen(false)}>
				<img
					onClick={() => setOpen(!open)}
					className={`download-icon ${open ? 'hover' : ''}`}
					src={require('../../icon/download_without_box.svg')}
				/>

				<CSSTransition
					in={open}
					timeout={300}
					classNames={'no-delay-fade-transition'}
					unmountOnExit
				>
					<div
						style={{
							position: 'absolute',
							zIndex: 1,
							bottom: 0,
							left: 0,
							right: 0
						}}
					>
						<div className="download-menu">
							{GLIII === '' && Studies === '' && (
								<div
									className="download-option"
									onClick={() => {
										sendAnalyticsUIInteraction(
											'SELECT',
											analyticsCategories.DOWNLOADS_MENU,
											'screenshot'
										);
										setOpen(false);
										setIsDownloadModalOpen(true);

										axios
											.get(result_img_url)
											.then((res) => {
												FileSaver.saveAs(
													result_img_url,
													'AgEvidence-Screenshot.jpg'
												);
											})
											.catch((err) => {
												alert('Screenshot Failed');
											})
											.then(() => {
												setIsDownloadModalOpen(false);
											});
									}}
								>
									<span className={`text_breadcrumb-label`}>
										{
											lexicon.DASHBOARD.CONTROL_PANEL
												.DOWNLOADS.MENU.Image
										}
									</span>
								</div>
							)}
							{
								<div className={`download-option`}>
									<span
										className={`text_breadcrumb-label`}
										onClick={() => {
											sendAnalyticsUIInteraction(
												'SELECT',
												analyticsCategories.DOWNLOADS_MENU,
												'filtered-data'
											);
											setOpen(false);
											downloadActions.downloadFilteredDataset(
												DATA,
												filteredObservationsByUID
											);
										}}
									>
										{
											lexicon.DASHBOARD.CONTROL_PANEL
												.DOWNLOADS.MENU.Filtered_Data
										}
									</span>
								</div>
							}
							<div className="download-option">
								{/* Updated <span /> to redirect to download pg. - Set to finding the #hash difference between Kenya & us-corn-belt */}
								<span
									className={`text_breadcrumb-label`}
									onClick={() => {
										if (
											window.location.hash.split(
												'/'
											)[1] === 'kenya'
										) {
											window.open(
												'https://doi.org/10.5063/F1805124',
												'_blank'
											);
										} else {
											window.open(
												'https://doi.org/10.5063/F1CR5RSR',
												'_blank'
											);
										}
									}}
								>
									{
										lexicon.DASHBOARD.CONTROL_PANEL
											.DOWNLOADS.MENU.Full_Data
									}
								</span>
							</div>
						</div>
					</div>
				</CSSTransition>
			</OutsideClickHandler>
		</div>
	);
};

export default Downloads;

export const downloadActions = {
	downloadFullDataset: (regionKey: string) => {
		FileSaver.saveAs(
			`${AppConfig.assetPath}data/${regionKey}/data.zip`,
			`${regionKey}_data.zip`
		);
	},
	downloadFilteredDataset: (
		DATA: TData,
		filteredObservationsByUID: number[]
	) => {
		// get the observations
		let observations =
			filteredObservationsByUID.length > 0
				? filteredObservationsByUID.map(
						(uid) => DATA.observationDictionary[uid]
				  )
				: map(DATA.observationDictionary, (o) => o);

		let data = observations
			.map((observation) => {
				let study = DATA.studyDictionary[observation.studyUID];
				if (!study) {
					return;
				}
				let location = study.locations[observation.location];

				return {
					...omit(observation, ['location', 'studyUID', 'variables']),
					...omit(observation.variables, ['duration']),
					...omit(study, ['locations']),
					...location
				};
			})
			.filter((row) => !!row);

		let csv = parse(data, {
			fields: Object.keys(data[1])
		});

		let blob = new Blob([csv], {
			type: 'text/plain;charset=utf-8'
		});

		FileSaver.saveAs(blob, 'data.csv');
	}
};

/**
 * FileSaver.saveAs(
								`./assets/data/${regionKey}/data.zip`,
								`${regionKey}_data.zip`
							);
 */
