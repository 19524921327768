/**
 * Beanfield Modal
 */

import React from 'react';
import AriaModal from 'react-aria-modal';
import ReactMarkdownText from './text/ReactMarkdownText';
import Colors from '../utils/color';
import { Lexicon } from '../data/contextualLexicon';

interface IBeanfieldModalProps {
	set: (v: boolean) => void;
	lexicon: Lexicon;
}

const BeanfieldModal: React.FC<IBeanfieldModalProps> = ({ set, lexicon }) => {
	return (
		<AriaModal
			titleText="How To Read This Chart"
			initialFocus="#modal"
			onExit={() => set(false)}
			getApplicationNode={() => document.getElementsByName('body')[0]}
			underlayStyle={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<div
				id="modal"
				style={{
					background: 'white',
					padding: '2rem',
					border: `1px solid ${Colors.lightGray}`,
					width: '1024px',
					height: '100%'
				}}
			>
				<div className="panel">
					<div
						className="title text_heading underline panel-heading"
						style={{
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<span>
							{
								lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
									.EXPLAIN_MODAL.HOW_TO_READ.Title
							}
						</span>
						<span className="close-btn" onClick={() => set(false)}>
							[close]
						</span>
					</div>
					<div style={{ display: 'flex', padding: '40px 0' }}>
						<img
							src={require('../../img/sample_bean_with_icons.svg')}
							style={{ marginRight: '20px', height: '563px' }}
						/>
						<div style={{ padding: '48px 0 20px', width: '636px' }}>
							<div className="explain-title">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.CHANGE.Title
								}
							</div>
							<div className="explain-body text_body">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.CHANGE.Body
								}
							</div>
							<div className="explain-title">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.OBSERVATIONS
										.Title
								}
							</div>
							<div className="explain-body text_body">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.OBSERVATIONS
										.Body
								}
							</div>
							<div className="explain-title">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.MEAN.Title
								}
							</div>
							<div className="explain-body text_body">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.MEAN.Body
								}
							</div>
							<div className="explain-title">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.DISTRIBUTION
										.Title
								}
							</div>
							<div className="explain-body text_body">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.DISTRIBUTION
										.Body
								}
							</div>
							<div className="explain-title">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.NORMS.Title
								}
							</div>
							<div className="explain-body text_body">
								{
									lexicon.DASHBOARD['P&O_PANEL'].BEANFIELD
										.EXPLAIN_MODAL.HOW_TO_READ.NORMS.Body
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AriaModal>
	);
};

export default BeanfieldModal;
