/**
 * Practices & Outcomes Legend
 */

import React, { useMemo } from 'react';
import FileSaver from 'file-saver';
import Colors from '../utils/color';
import { times } from 'lodash-es';
import { makeHeatmapScale } from './heatmap';
import { TData } from '../../../../preprocess/src/ts/exec';
import { Lexicon } from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';
import { downloadActions } from './downloads';
import { INavState } from '../nav/state';
import { AppState, StoreVault } from '../app/state';

interface ILegendProps {
	bean: boolean;
	norm: boolean;
	DATA: TData;
	lexicon: Lexicon;
}

const Legend: React.FC<ILegendProps> = ({ bean, norm, DATA, lexicon }) => {
	const { legendNums } = useMemo(() => makeHeatmapScale(DATA.counts), [DATA]);
	return (
		<div className="legend">
			{!bean && !norm && (
				<div className="heatmap-key">
					<div className="text_data-label">
						{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.Heatmap_Key}
					</div>
					<div className="key">
						<div className="nums text_data-label">
							{times(3, (idx) => (
								<div
									key={idx}
									className={`num-${['a', 'b', 'c'][idx]}`}
								>
									{legendNums[idx]}
								</div>
							))}
						</div>
						<div className="ticks">
							{times(3, (idx) => (
								<div key={idx} className="legend-tick" />
							))}
						</div>
						<div className="rectangle" />
					</div>
				</div>
			)}

			<div
				style={{
					justifySelf: 'flex-end',
					display: norm ? 'flex' : 'none'
				}}
			>
				{bean && (
					<>
						<div className="text_data-label pair">
							{
								lexicon.DASHBOARD['P&O_PANEL'].LEGEND
									.OBSERVATIONS.Label
							}
							<img
								src={require('../../icon/observation_icon.svg')}
							/>
						</div>
						<div className="text_data-label pair">
							{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.MEAN.Label}
							<img src={require('../../icon/mean.svg')} />
						</div>
					</>
				)}
				<div className="text_data-label pair">
					{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.NORMS.Red}{' '}
					<Icon color={Colors.unfavorablelight} />
				</div>
				<div className="text_data-label pair">
					{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.NORMS.Yellow}{' '}
					<Icon color={Colors.indeterminateLight} />
				</div>
				<div className="text_data-label pair">
					{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.NORMS.Green}{' '}
					<Icon color={Colors.favorableLight} />
				</div>
			</div>

			<div
				style={{
					display: !bean && norm ? 'flex' : 'none'
				}}
			>
				<span
					className="button text-body text--clover"
					onClick={() => {
						sendAnalyticsUIInteraction(
							'SELECT',
							analyticsCategories.STAGE,
							'download',
							{ key: 'download_type', value: 'full-dataset' }
						);

						downloadActions.downloadFullDataset(
							(StoreVault.getStore().getState() as AppState).nav
								.Region
						);
					}}
				>
					{lexicon.DASHBOARD['P&O_PANEL'].LEGEND.Download}
				</span>
			</div>
		</div>
	);
};

export default Legend;

const Icon = ({ color }) => {
	return <div className="icon" style={{ background: color }} />;
};
