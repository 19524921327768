/**
 * Component for distribution, mean, ticks in bean plots
 */

import React, { useMemo } from 'react';

import { IObservation } from '../../../../preprocess/src/ts/exec';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import * as array from 'd3-array';
import Colors from '../utils/color';
import AnimatedDistribution from './AnimatedDistribution';
import AnimatedTicks from './AnimatedTicks';
import AnimatedRefLines from './AnimatedRefLines';
import AnimatedMean from './AnimatedMean';
import { showDistribution } from './beanplot';

interface IBeanplotContentProps {
	observations: IObservation[];
	height: number;
	width: number;
	plotWidth: number;
	scaleY: ScaleLinear<number, number>;
	refLines: { point: [number, number]; dashed: boolean }[];
	mean: number;
	norm: string;
	percentChangesOnly: number[];
}

const getDistributionMetrics = (props: {
	height: number;
	width: number;
	mean: number;
	norm: string;
	percentChangesOnly: number[];
}) => {
	const { percentChangesOnly, height, width, mean } = props;

	let binY = scaleLinear()
		.domain([-200, 200])
		.nice()
		.range([height, 0]);

	let thresholds = binY.ticks(height / 2);
	let data = percentChangesOnly;

	let density = (data.length > 0
		? kde(epanechnikov(bandwidth), thresholds, data)
		: thresholds.map(t => [t, 0])) as [number, number][];

	let x = scaleLinear()
		.domain([0, data.length > 0 ? array.max(density, d => d[1]) : 1])
		.range([0, width * 0.475]);

	let bins = array
		.histogram()
		.domain(binY.domain() as [number, number])
		.thresholds(thresholds)(data);

	return { bins, density, x };
};

const BeanplotContent: React.FC<IBeanplotContentProps> = ({
	observations,
	height,
	width,
	plotWidth,
	scaleY,
	refLines,
	percentChangesOnly,
	mean,
	norm
}) => {
	const { bins, density, x } = useMemo(
		() =>
			getDistributionMetrics({
				height,
				width,
				mean,
				norm,
				percentChangesOnly
			}),
		[height, plotWidth, mean, norm, percentChangesOnly.toString()]
	);

	return (
		<svg
			viewBox={`0 0 ${plotWidth} ${height}`}
			style={{
				position: 'absolute',
				top: '0px',
				left: '0px'
			}}
		>
			<defs>
				<line
					id="tick"
					className="tick"
					x1={-10}
					x2={+10}
					y1={0}
					y2={0}
					strokeWidth={1}
					stroke={Colors.darkGray}
				/>
			</defs>
			<g
				style={{
					transform: `translate(${(plotWidth / 2).toFixed(0)}px, ${(
						height / 2
					).toFixed(0)}px)`
				}}
			>
				<AnimatedRefLines
					{...{ y: scaleY, refLines, width: plotWidth }}
				/>
				{showDistribution(observations.length) && (
					<AnimatedDistribution
						{...{
							x,
							y: scaleY,
							density,
							svgProps: {
								fill: 'rgba(255, 255, 255, 0.7)',
								strokeWidth: 0.5,
								stroke: Colors.darkGray,
								strokeOpacity: 0.4
							}
						}}
					/>
				)}
				<AnimatedTicks {...{ y: scaleY, bins }} />
				<AnimatedMean {...{ y: scaleY, mean }} />
			</g>
		</svg>
	);
};

const bandwidth = 20;

function kde(kernel, thresholds: number[], data: number[]) {
	return thresholds.map(t => [t, array.mean(data, d => kernel(t - d))]);
}

function epanechnikov(bandwidth) {
	return x =>
		Math.abs((x /= bandwidth)) <= 1 ? (0.75 * (1 - x * x)) / bandwidth : 0;
}

export default BeanplotContent;
