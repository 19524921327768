/**
 * Encapsulates startup and root render logic on the client.
 */

import AppConfig from '../shared/ts/app/config/AppConfig';
import domready from 'domready';
import LowestCommonAncestor from '../shared/ts/app/View';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { setupAnalyticsHandlers } from '../shared/ts/app/utils/analytics';

function success(store) {
	setupAnalyticsHandlers();

	// Setup the view
	ReactDOM.render(
		<Provider store={store}>
			<LowestCommonAncestor store={store} />
		</Provider>,
		document.getElementById(AppConfig.containerId)
	);
}

function failure() {
	// Doesn't have the necessary support
}

function hasSupport() {
	// Change this to whatever technology support tests are necessary for app
	return true;
}

function startup(store) {
	// Fire callback when document is ready
	domready(function () {
		if (hasSupport()) {
			success(store);
		} else {
			failure();
		}
	});
}

export default startup;
