/* tslint:disable:max-file-line-count */
/**
 * Component for demonstrating selection components for the nav.
 */

import React, { useState, useMemo, useEffect } from 'react';
import { get, map, flatten, uniq, sortBy, range, find } from 'lodash-es';
import { INavState, SET } from '../nav/state';
import { TData } from '../../../../preprocess/src/ts/exec';

import { filterObservations } from '../app/utils/pruneAndFilterObservations';
import { IFilterObject } from './filters';

import { normColors, norms } from '../utils/norms';
import Legend from './legend';
import Beanplot from './beanplot';

import ReactCarousel from '@brainhubeu/react-carousel';
import { scaleLinear } from 'd3-scale';
import AnimatedPlotLabels from './AnimatedPlotLabels';
import getContextualLexicon from '../data/contextualLexicon';
import { InsightsNode, makeInsightsTree } from '../utils/InsightsTree';
import InsightButton from './InsightButton';
import { InsightLink } from '../../../../preprocess/src/ts/prepObservations';
import CarouselComponent from './carousel/Carousel';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

interface IBeanfieldProps {
	nav: INavState;
	store: any;
	DATA: TData;
	level: number;
	filterObjectArray?: IFilterObject[];
	hover?: boolean;
	onClick?: () => void;
	isSmallScreen?: boolean;
}

const Beanfield: React.FC<
	IBeanfieldProps & { setOpenInsightIndex: (n: number) => void }
> = ({
	DATA,
	nav,
	level,
	filterObjectArray,
	store,
	isSmallScreen,
	setOpenInsightIndex
}) => {
	if (nav.Practice === '') {
		return null;
	}

	const lexicon = getContextualLexicon(nav);

	const setGroupLevelII = (GLII) => {
		store.dispatch({
			type: SET,
			val: { GLII, GLIII: '', Carousel: 0 }
		});
	};

	const setGroupLevelIII = (GLIII) => {
		store.dispatch({
			type: SET,
			val: { GLIII, Carousel: 0 }
		});
	};

	const setCarousel = (Carousel) => {
		store.dispatch({
			type: SET,
			val: { Carousel }
		});
	};

	const { Practice, Outcome, GLII, GLIII, ChartRange, Carousel } = nav;

	let groupCursor: string[] =
		level === 2
			? [Practice, 'groups', Outcome, 'groups']
			: [Practice, 'groups', Outcome, 'groups', GLII, 'groups'];

	const groups = get(DATA.practicesAndOutcomesTree, groupCursor);

	const groupsInABox = sortBy(
		map(groups, (group, key) => ({ group, key })),
		[(g) => g.key]
	);
	const insightLink: InsightLink = groupCursor.filter(
		(val, idx) => idx % 2 === 0
	);
	const insightsTree = useMemo(
		() => makeInsightsTree(DATA.insights),
		[DATA.insights]
	);

	const carouselLength = Object.keys(groups).length;

	const [carouselIndex, setCarouselIndex] = useState(Carousel);

	const proxySetCarousel = (Carousel) => {
		setCarouselIndex(Carousel);
		setCarousel(Carousel);
	};

	useEffect(() => {
		if (Carousel !== carouselIndex) {
			setCarouselIndex(Carousel);
		}
	}, [Carousel]);

	let maxVisibleCarouselItems = isSmallScreen ? 4 : 5;

	const height = isSmallScreen ? 446 : 516;
	const width = 114;
	const hardCodedCarouselWidth = isSmallScreen ? 657 : 788;

	const plotWidth =
		carouselLength >= maxVisibleCarouselItems
			? 114
			: (hardCodedCarouselWidth - 40 * carouselLength) / carouselLength; // invision inspected pentuplet = '114px'
	const carouselWidth =
		carouselLength >= 5 ? 162.5 : hardCodedCarouselWidth / carouselLength;

	const chartRange = parseInt(ChartRange || '100', 10);
	const scaleY = useMemo(() => {
		return scaleLinear()
			.domain([-chartRange, chartRange])
			.nice()
			.range([height * 0.475, -height * 0.475]);
	}, [chartRange]);

	const labels: {
		text: string;
		point: [number, number];
		dashed;
	}[] = useMemo(
		() =>
			range(-200, 250, 50).map((v) => ({
				text:
					v === 0 ? getControlText(nav, filterObjectArray) : v + '%',
				point: [1, v],
				dashed: v !== 0
			})),
		[nav]
	);

	return (
		<div className="beanfield" style={{ height: '650px' }}>
			<div className="field">
				<AnimatedPlotLabels {...{ scaleY, height, labels }} />
				<CarouselComponent
					slidesPerPage={maxVisibleCarouselItems}
					value={carouselIndex}
					setValue={proxySetCarousel}
					itemWidth={carouselWidth}
					rightPeek={
						groupsInABox.length > maxVisibleCarouselItems ? 0.5 : 0
					}
				>
					{map(groupsInABox, ({ group, key }, idx) => {
						const isShownOnCarousel =
							idx <= carouselIndex + 4 && idx >= carouselIndex;

						let observationsByUID: number[] = uniq(
							level === 3
								? group.observationsByUID
								: flatten(
										map(
											group.groups,
											(subGroup) =>
												subGroup.observationsByUID
										)
								  )
						);

						let filteredObservationsByUID = filterObservations(
							observationsByUID,
							filterObjectArray,
							DATA
						);

						return (
							<div
								key={key}
								className={`beanfield--inner ${
									filteredObservationsByUID.length == 0
										? 'no-click'
										: ''
								}`}
								style={{
									flexShrink: 0,
									width: carouselLength >= 5 ? 114 : '100%',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									margin: '0 auto'
								}}
								onClick={
									filteredObservationsByUID.length <= 0
										? undefined
										: level === 2
										? () => {
												sendAnalyticsUIInteraction(
													'SELECT',
													analyticsCategories.STAGE,
													'plot'
												);
												setGroupLevelII(key);
										  }
										: () => {
												sendAnalyticsUIInteraction(
													'SELECT',
													analyticsCategories.STAGE,
													'plot'
												);
												setGroupLevelIII(key);
										  }
								}
							>
								<div className="beanplot-label text_data-label">
									{key}
								</div>
								<Beanplot
									{...{
										nav,
										DATA,
										norm: group.normativeInterpretation,
										observationsByUID:
											filteredObservationsByUID,
										shown: isShownOnCarousel,
										plotWidth,
										width,
										height,
										refLines: labels,
										scaleY,
										beanKey: key.toString()
									}}
								/>
								<InsightButton
									tree={insightsTree}
									link={[...insightLink, key]}
									setOpenInsightIndex={setOpenInsightIndex}
									lexicon={lexicon}
									xPosition={plotWidth * 0.5}
								/>
							</div>
						);
					})}
				</CarouselComponent>

				<div className="carousel-nav">
					<img
						src={require('../../icon/carousel_left.svg')}
						style={{
							opacity: carouselIndex === 0 ? 0 : 1,
							display:
								carouselLength <= maxVisibleCarouselItems
									? 'none'
									: 'block'
						}}
						onClick={() =>
							proxySetCarousel(
								Math.max(
									0,
									carouselIndex - maxVisibleCarouselItems
								)
							)
						}
					/>
					<img
						src={require('../../icon/carousel_right.svg')}
						style={{
							opacity:
								carouselIndex ===
								carouselLength - maxVisibleCarouselItems
									? 0
									: 1,
							display:
								carouselLength <= maxVisibleCarouselItems
									? 'none'
									: 'block'
						}}
						onClick={() =>
							proxySetCarousel(
								Math.min(
									carouselLength - maxVisibleCarouselItems,
									carouselIndex + maxVisibleCarouselItems
								)
							)
						}
					/>
				</div>
			</div>

			<Legend bean={true} norm={true} DATA={DATA} lexicon={lexicon} />
		</div>
	);
};

export default Beanfield;

export const SmallBeanfield: React.FC<IBeanfieldProps> = ({
	DATA,
	nav,
	level,
	hover,
	onClick
}) => {
	const { Practice, Outcome, GLII, GLIII } = nav;

	let selectionAtLevel = level === 2 ? GLII : GLIII;

	let groupCursor =
		level === 2
			? [Practice, 'groups', Outcome, 'groups']
			: [Practice, 'groups', Outcome, 'groups', GLII, 'groups'];

	const groupsFromData = sortBy(
		Object.keys(get(DATA.practicesAndOutcomesTree, groupCursor)),
		[(x) => x]
	);

	return (
		<div
			className={`beanfield beanfield--small ${hover ? 'hover' : ''}`}
			onClick={onClick}
		>
			{map(groupsFromData, (groupKey, idx) => {
				const isSelected = groupKey === selectionAtLevel;
				return (
					<div
						className={`column ${isSelected ? 'selected' : ''}`}
						key={idx}
						style={{
							flexGrow: 1
						}}
					/>
				);
			})}
		</div>
	);
};

export const MediumBeanfield: React.FC<IBeanfieldProps> = ({
	DATA,
	nav,
	level,
	store
}) => {
	const { Practice, Outcome, GLII, GLIII } = nav;

	const setGroupLevelII = (GLII) => {
		store.dispatch({
			type: SET,
			val: { GLII, GLIII: '', Carousel: 0 }
		});
	};

	const setGroupLevelIII = (GLIII) => {
		store.dispatch({
			type: SET,
			val: { GLIII, Carousel: 0 }
		});
	};

	let selectionAtLevel = level === 2 ? GLII : GLIII;

	let groupCursor =
		level === 2
			? [Practice, 'groups', Outcome, 'groups']
			: [Practice, 'groups', Outcome, 'groups', GLII, 'groups'];

	const groupsFromData = get(DATA.practicesAndOutcomesTree, groupCursor);

	const groupsInABox = sortBy(
		map(groupsFromData, (group, key) => ({ group, key })),
		[(g) => g.key]
	);

	return (
		<div className="beanfield beanfield--medium">
			{map(groupsInABox, ({ group, key }) => {
				const isSelected = key === selectionAtLevel;

				const norm = group.normativeInterpretation;
				return (
					<div
						key={key}
						className={`column ${isSelected ? 'selected' : ''}`}
						onClick={
							level === 2
								? () => {
										sendAnalyticsUIInteraction(
											'SELECT',
											analyticsCategories.BREADCRUMBS,
											'plot'
										);
										setGroupLevelII(key);
								  }
								: () => {
										sendAnalyticsUIInteraction(
											'SELECT',
											analyticsCategories.BREADCRUMBS,
											'plot'
										);
										setGroupLevelIII(key);
								  }
						}
					>
						<div
							className={`norm-box`}
							style={{
								background:
									normColors[norms[norm.toLowerCase()]]
							}}
						/>
						<div
							className={`norm-box`}
							style={{
								background: [...normColors].reverse()[
									norms[norm.toLowerCase()]
								]
							}}
						/>
						<div className="name text_breadcrumb-label">{key}</div>
					</div>
				);
			})}
		</div>
	);
};

function getControlText(nav: INavState, filterObjectArray: IFilterObject[]) {
	let { Practice, Outcome, PracticeFilters } = nav;

	// first try control option
	const controlFlt = find(
		filterObjectArray,
		(filter) => filter.key === 'control'
	);

	if (controlFlt) {
		const selControl = controlFlt.options.filter(
			(option) => option.active === true
		);
		if (selControl.length === 1) {
			return selControl[0].value;
		}
		return '';
	}

	switch (Practice) {
		case 'Cover Crops':
			return 'Winter Fallow';
		case 'Nutrient Management':
			let group = find(
				filterObjectArray,
				(filter) => filter.key === 'group'
			).options.filter((option) => option.active === true)?.[0]?.value;

			if (group === 'Fertilizer Placement') {
				return 'Broadcast';
			} else {
				let applicationSpecifics = find(
					filterObjectArray,
					(filter) => filter.key === 'applicationspecifics'
				)
					.options.slice(2)
					.filter((option) => option.active === true);

				if (applicationSpecifics.length === 1) {
					switch (applicationSpecifics[0].value) {
						case 'Split Application':
							return 'Single Application';
						case 'Early Season':
							return 'Pre-Plant';
						case 'Spring':
							return 'Fall';
					}
				}
				return '';
			}
		case 'Pest Management':
			return 'No Pesticide';
		default:
			return '';
	}
}
