/**
 * Customized carousel
 *
 */
import e from 'express';
import { debounce } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Measure from 'react-measure';

const Carousel: React.FC<{
	itemWidth: number;
	slidesPerPage: number;
	value: number;
	setValue: (v: number) => void;
	children: JSX.Element[];
	rightPeek?: number;
}> = ({ children, slidesPerPage, itemWidth, value, setValue, rightPeek }) => {
	const [dimensions, setDimensions] = useState(null as DOMRectReadOnly);
	const scrollRef = useRef<HTMLDivElement>();
	const resolvedValue = useRef(0);
	const scrollable = children.length > slidesPerPage;
	slidesPerPage = Math.min(children.length, slidesPerPage);

	const onDebouncedScroll = useMemo(
		() =>
			debounce((el: HTMLElement) => {
				// sync carousel position to state
				const perChildPx =
					dimensions.width / (slidesPerPage + rightPeek);
				const cursor = Math.round(el.scrollLeft / perChildPx);
				resolvedValue.current = cursor;
				setValue(cursor);
			}, 150),
		[dimensions]
	);

	useEffect(() => {
		if (!dimensions) {
			return;
		}
		// sync carousel state to position
		const perChildPx = dimensions.width / (slidesPerPage + rightPeek);
		const el = scrollRef.current as HTMLElement;

		if (resolvedValue.current !== value) {
			// this cursor value has not been resolved to position yet
			// so move the scroll to match
			const targetPos = Math.round(perChildPx * value);
			el.scrollTo({
				left: targetPos,
				behavior: 'smooth'
			});
		}
	}, [value, dimensions]);

	return (
		<Measure
			bounds
			onResize={(contentRect) => {
				setDimensions(contentRect.bounds);
			}}
		>
			{({ measureRef }) => (
				<div
					style={{ width: '100%', overflow: 'hidden' }}
					ref={measureRef}
				>
					{(() => {
						if (!dimensions) {
							return null;
						}
						rightPeek ??= 0;

						//const RIGHT_CLIP = children.length > slidesPerPage ? 0.5 : 0;
						const perChildPct = 100 / (slidesPerPage + rightPeek);
						let trackWidthExpr =
							(
								(children.length + (scrollable ? 1 : 0)) *
								perChildPct
							).toFixed(3) + '%';
						let trackShiftExpr =
							((-100 * value) / children.length).toFixed(3) + '%';
						const perChildPx =
							dimensions.width / (slidesPerPage + rightPeek);

						return (
							<div
								style={{
									overflow: 'auto',
									scrollSnapType: 'x mandatory',
									// hide scrollbar
									marginBottom: '-20px',
									paddingBottom: '20px'
								}}
								ref={scrollRef}
								onScroll={(e) => {
									onDebouncedScroll(e.currentTarget);
								}}
							>
								<div
									style={{
										width: trackWidthExpr,
										display: 'flex'
									}}
								>
									{children.map((child, idx, arr) => (
										<div
											key={idx}
											style={{
												flex: '1 0',
												scrollSnapAlign:
													idx <
													arr.length -
														(slidesPerPage - 1)
														? 'start'
														: undefined
											}}
										>
											{child}
										</div>
									))}
									{scrollable && (
										<div style={{ flex: '1 0' }} />
									)}
								</div>
							</div>
						);
					})()}
				</div>
			)}
		</Measure>
	);
};

export default Carousel;
/*
<div class="carousel-nav"><img src="http://localhost:8080/src/shared/icon/0a23a7e1f779d9312d595944b4f6415d.carousel_left.svg" style="opacity: 0; display: block;"><img src="http://localhost:8080/src/shared/icon/4345600521fc670cb5cf81a9c6faa280.carousel_right.svg" style="opacity: 1; display: block;"></div>
*/
