/**
 * Component for ticks in bean plots
 */

import React, { useEffect, useRef } from 'react';
import { Power2 } from 'gsap';
import { ScaleLinear } from 'd3-scale';
import PointsAnimator from '../utils/PointsAnimator';

const AnimatedPoints: React.FC<{
	component: string;
	y: ScaleLinear<number, number>;
	points: [number, number][];
	renderPoint: {
		(pt: [number, number], index: number): JSX.Element;
	};
	updatePoint: { (ptEl: Element, pt: [number, number], index: number): void };
	containerEffect?: { (el: Element): { (): void } };
	containerProps?: any;
}> = ({
	component,
	y,
	points,
	renderPoint,
	updatePoint,
	containerEffect,
	containerProps
}) => {
	const elRef = useRef(null as Element);
	const animRef = useRef(null as PointsAnimator);

	useEffect(() => containerEffect?.(elRef.current), []);

	useEffect(() => {
		let animator = animRef.current;
		// const targetPts: [number, number][] = bins.map(pt => [
		// 	Math.min(1, pt.length),
		// 	y(pt.x0)
		// ]);
		const targetPts: [number, number][] = points.map(pt => [
			pt[0],
			y(pt[1])
		]);

		if (!animator) {
			const group = elRef.current;
			const ptEls = Array.from(group.children);

			// initial points
			animRef.current = animator = new PointsAnimator(
				targetPts,
				(pts: [number, number][]) => {
					pts.forEach((pt, idx) => {
						const ptEl = ptEls[idx];
						updatePoint(ptEl, pt, idx);
					});
				}
			);
			animator.applyCurrentPoints();
		} else {
			// interpolation animation
			animator.toNextPoints(targetPts, {
				ease: Power2.easeInOut,
				duration: 0.5
			});
			return () => animator.stop();
		}
	}, [points, y]);

	return React.createElement(
		component,
		{
			...containerProps,
			ref: elRef
			//style: { transform: 'scale(1, 0)' }
		},
		points.map(renderPoint)
	);
};

export default AnimatedPoints;
