/**
 * Component for mean line in bean plots
 */

import React, { useMemo } from 'react';
import { ScaleLinear } from 'd3-scale';
import AnimatedPoints from './AnimatedPoints';
import { partialRight } from 'lodash-es';
import reflectPoints from '../utils/reflectPoints';
import scaleInX from './fx/scaleInX';
import { Power3 } from 'gsap';
import Colors from '../utils/color';

const TVT = 4;
const TWD = 5;
const HWD = 55;
const THK = 1;

const svgPoints = reflectPoints(
	reflectPoints(
		[
			[-HWD, -TVT],
			[-HWD + TWD, -THK]
		],
		0,
		true,
		true
	),
	0,
	false,
	true
)
	.map(p => p.join(','))
	.join(' ');

const inFx = partialRight(scaleInX, {
	delay: 1,
	ease: Power3.easeOut,
	duration: 0.5
});

const AnimatedMean: React.FC<{
	y: ScaleLinear<number, number>;

	mean: number;
}> = ({ y, mean }) => {
	function updatePoint(poly: SVGPolygonElement, pt: [number, number]) {
		poly.setAttributeNS(
			null,
			'transform',
			`translate(0 ${pt[1].toFixed(3)}) scale(${pt[0].toFixed(3)} 1)`
		);
	}

	function renderPoint(pt: [number, number], idx: number) {
		return <polygon key={idx} points={svgPoints} fill={Colors.darkGray} />;
	}

	const points = useMemo(
		() => [isNaN(mean) ? [0, 0] : [1, mean]] as [number, number][],
		[mean]
	);

	return (
		<AnimatedPoints
			{...{
				component: 'g',
				y,
				points,
				updatePoint,
				renderPoint,
				containerEffect: inFx,
				containerProps: { style: { transform: 'scale(0, 1)' } }
			}}
		/>
	);
};

export default AnimatedMean;
