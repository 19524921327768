/**
 * RouteEncoder encodes url routes from state. Used internally by RouteConfig.
 */

import RouteConfigCore from './RouteConfigCore';
import { IEncodedProps } from './RouteConfig';
import { isEqual } from 'lodash-es';

export default class RouteEncoder {
	static projectAndRecursiveEncode<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		storeState: StoreStateT,
		layersData: { vals: string[]; tempVals: string[] },
		propVals: IEncodedProps,
		isBranch: boolean
	): IEncodedProps {
		const data = { ...core.defaults, ...core.project(storeState) };

		return RouteEncoder.recursiveEncode(
			core,
			storeState,
			data,
			layersData,
			propVals,
			isBranch
		);
	}

	private static recursiveEncode<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		storeState: StoreStateT,
		data: DataT,
		layersData: { vals: string[]; tempVals: string[] },
		propVals: IEncodedProps,
		isBranch: boolean
	): IEncodedProps {
		let i: number;
		let l: number;

		const {
			layers,
			defaults,
			explicitDefaults: explicits,
			properties
		} = core;

		if (layers && layers.length) {
			// layers should only be attached if root

			let layerVals = layersData.vals;
			let val: any;

			for (i = 0, l = layers.length; i < l; ++i) {
				let node = layers[i];
				val = data[node.fieldKey];

				let strVal = node.encode(val, storeState);
				layersData.tempVals.push(strVal);

				// Since these are hierarchical, continue adding only if
				// a truthy value
				if (strVal) {
					// if last value so far isn't default, add all currently on the stack
					if (
						explicits[node.fieldKey] ||
						!isEqual(val, defaults[node.fieldKey])
					) {
						layersData.vals.push(...layersData.tempVals);
						layersData.tempVals = [];
					}

					// if at end of this configs layers and
					// has appropriate branch, enter it
					if (i === l - 1 && core.brancher) {
						const branch = core.brancher(val);

						if (branch) {
							RouteEncoder.projectAndRecursiveEncode(
								branch,
								storeState,
								layersData,
								propVals,
								true
							);
						}
					}
				} else {
					break;
				}
			}

			// Add hierarchical if necessary
			if (!isBranch && layersData.vals.length) {
				propVals[core.namespace] = layersData.vals;
			}
		}

		// Add properties
		for (i = 0, l = properties.length; i < l; ++i) {
			let node = properties[i];
			let val = data[node.fieldKey];

			if (val === defaults[node.fieldKey]) {
				// Don't note in URL if it is the default
				continue;
			}

			let strVal = node.encode(val, storeState);

			if (strVal || strVal === '') {
				propVals[node.nsUrlKey] = [strVal];
			}
		}

		return propVals;
	}
}
