/**
 * Map Zones Controller
 */
import * as MapboxGl from 'mapbox-gl';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getMapFeaturesAnimationStream } from '../stream/getMapFeaturesAnimationStream';

function easeInOutQuad(x: number): number {
	return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export function makeMapZoneController(
	map: MapboxGl.Map,
	context$: Observable<boolean | { [key: string]: boolean }>
) {
	const LAYER_ID = 'farming-zones';
	const STATE_KEY = 'ACTIVATION';
	// key of property within map feature
	const FEATURE_PROPERTY_KEY = 'DESCRIPTIO';
	const TRANSITION = {
		ease: easeInOutQuad,
		duration: 500
	};
	const OPACITY_PROPERTIES = ['line-opacity', 'fill-opacity'];

	// handles actual rendering of the opacity based on activation
	OPACITY_PROPERTIES.forEach((propertyKey) => {
		try {
			map.setPaintProperty(LAYER_ID, propertyKey, [
				'number',
				['feature-state', STATE_KEY],
				1
			]);
		} catch (e) {
			// silent since we know this may not match certain layer types
		}
	});

	const subscription = getMapFeaturesAnimationStream({
		map,
		layerId: LAYER_ID,
		context$,
		getFeatureState: (f, context) => {
			return {
				// hide activated layers
				[STATE_KEY]: (
					typeof context === 'boolean'
						? context
						: context[f.properties[FEATURE_PROPERTY_KEY]] ?? false
				)
					? 0
					: 1
			};
		},
		interpolateState: (a, b, t) => ({
			[STATE_KEY]: (b[STATE_KEY] - a[STATE_KEY]) * t + a[STATE_KEY]
		}),
		getTransition: () => TRANSITION
	}).subscribe();

	return subscription;
}
