/**
 * Map zones Controller
 */
import * as MapboxGl from 'mapbox-gl';
import { Observable, ReplaySubject } from 'rxjs';
import {
	combineLatestWith,
	finalize,
	scan,
	withLatestFrom,
	map as rxmap,
	take,
	switchMapTo,
	switchMap,
	startWith,
	bufferCount
} from 'rxjs/operators';
import rafClock from '../stream/rafClock';

function watchMapViewport(map: MapboxGl.Map, handler: (ev: any) => void) {
	const keys = ['zoomend', 'dragend'];
	keys.forEach((key) => map.on(key, handler));
	return () => {
		// cancel
		keys.forEach((key) => map.off(key, handler));
	};
}
function getMapLayerSource(map: MapboxGl.Map, layerId: string) {
	const layer: MapboxGl.Layer = map
		.getStyle()
		.layers?.find((l) => l.id === layerId);
	let sourceId = layer?.['source-layer'];
	if (!sourceId) {
		throw `No layer ${layerId} with source`;
	}
	return sourceId;
}

function getFeatureStream(map: MapboxGl.Map, layerId: string) {
	const updateFeatureIds = () => {
		const features = map.queryRenderedFeatures(null, { layers: [layerId] });
		for (let feat of features) {
			if (!knownFeatures[feat.id]) {
				knownFeatures[feat.id] = true;
				feature$.next(feat);
			}
		}
	};
	let knownFeatures: { [id: string]: boolean } = {};
	const feature$: ReplaySubject<MapboxGl.MapboxGeoJSONFeature> =
		new ReplaySubject();
	updateFeatureIds();
	const cancel = watchMapViewport(map, updateFeatureIds);
	const featureCleanup$ = feature$.pipe(finalize(() => cancel));
	return featureCleanup$;
}

interface ITransition {
	ease: (v: number) => number;
	duration: number;
}

export function getMapFeaturesAnimationStream<
	Context,
	FeatState extends { [key: string]: any } = {}
>({
	map,
	layerId,
	context$,
	getFeatureState,
	interpolateState,
	getTransition
}: {
	map: mapboxgl.Map;
	layerId: string;
	context$: Observable<Context>;
	getFeatureState: (
		feature: MapboxGl.MapboxGeoJSONFeature,
		context: Context,
		lastState?: FeatState
	) => FeatState;
	interpolateState: (a: FeatState, b: FeatState, t: number) => FeatState;
	getTransition: (
		feature: MapboxGl.MapboxGeoJSONFeature,
		context: Context,
		currentState: FeatState,
		endState: FeatState
	) => ITransition;
}) {
	const feature$ = getFeatureStream(map, layerId);

	// waits till context$ has emitted, then combine with features
	const featContext$ = context$.pipe(
		take(1),
		switchMap((value) =>
			context$.pipe(startWith(value), combineLatestWith(feature$))
		)
	);

	const animations$ = featContext$.pipe(
		scan((acc, [context, lastFeature]) => {
			const items = acc;
			let now = performance.now();

			if (
				items[items.length - 1]?.feature !== lastFeature &&
				lastFeature
			) {
				// new feature
				const state = getFeatureState(lastFeature, context);
				if (lastFeature.id === undefined) {
					console.warn('feature has no id');
					return items;
				}
				items.push({
					feature: lastFeature,
					endState: state,
					currentState: state,
					startState: state,
					startTime: now,
					transition: null,
					complete: true
				});
				// apply initial state
				map.setFeatureState(lastFeature, state);
				return items;
			} else {
				// transform all animation items
				return items.map((item) => {
					// get the updated state
					let state = getFeatureState(item.feature, context);

					// skip update if already targeting the new state
					if (state === item.endState) {
						return item;
					}

					return {
						...item,
						transition: getTransition(
							item.feature,
							context,
							item.currentState,
							state
						),
						endState: state,
						startState: item.currentState,
						startTime: now,
						complete: false
					};
				});
			}
		}, [] as { feature: MapboxGl.MapboxGeoJSONFeature; endState: FeatState; currentState: FeatState; startState: FeatState; startTime: number; transition?: ITransition; complete: boolean }[])
	);

	// update each animation using RAF
	return animations$.pipe(
		combineLatestWith(rafClock()),
		rxmap(([animations, { time }]) => {
			animations
				// only those not complete yet
				.filter((anim) => !anim.complete)
				// with each animation
				.forEach((anim) => {
					const { startTime, transition } = anim;
					// raw change coef
					const t = Math.min(
						1,
						Math.max(0, (time - startTime) / transition.duration)
					);
					// eased coef
					const tPrime = transition.ease(t);
					// interpolate the state
					const currentState = (anim.currentState = interpolateState(
						anim.startState,
						anim.endState,
						tPrime
					));
					// apply the state to the feature
					map.setFeatureState(anim.feature, currentState);

					if (t === 1) {
						anim.complete = true;
					}
				});
			return animations;
		})
	);
}
