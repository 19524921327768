/**
 * Utility for managing tweening between arrays of points
 */
import { TweenLite } from 'gsap';

export default class PointsAnimator {
	private tween: TweenLite;
	private currentPoints: [number, number][];

	constructor(
		private startPts: [number, number][],
		private applyPoints: { (pts: [number, number][]): void }
	) {
		this.currentPoints = this.startPts;
	}

	toNextPoints(
		pts: [number, number][],
		opts: { ease: { (t: number): number }; duration: number }
	) {
		this.startPts = this.currentPoints;
		this.currentPoints = PointsAnimator.copyPoints(this.startPts);

		this.tween?.kill();
		let proxy = { t: 0 };
		this.tween = TweenLite.to(proxy, opts.duration, {
			t: 1,
			ease: opts.ease,
			onUpdate: () => {
				PointsAnimator.interpolatePoints(
					this.startPts,
					pts,
					proxy.t,
					this.currentPoints
				);
				this.applyCurrentPoints();
			}
		});
	}

	applyCurrentPoints() {
		this.applyPoints(this.currentPoints);
	}

	stop() {
		this.tween.kill();
		this.tween = null;
	}

	static copyPoints(pts: [number, number][]): [number, number][] {
		return pts.map(pt => [pt[0], pt[1]]);
	}

	static interpolatePoints(
		start: [number, number][],
		end: [number, number][],
		t: number,
		target: [number, number][]
	) {
		for (let i = 0, l = start.length; i < l; ++i) {
			const [s0, s1] = start[i];
			const [e0, e1] = end[i];
			target[i][0] = s0 + (e0 - s0) * t;
			target[i][1] = s1 + (e1 - s1) * t;
		}
	}
}
