/**
 * Encapsulates the stateless portion of the router setup.
 */

import RouteConfig from './RouteConfig';
import AsapPromise from '../AsapPromise';
import { extend, keys } from 'lodash-es';
import { addLeadingSlash, asapReduce } from './RouteUtils';
import { ROUTER_MODE } from './RouterConfig';

export default class StatelessRouterSetup<StoreStateT> {
	private routeConfigs: RouteConfig<StoreStateT, any>[] = [];
	static readonly prefix = StatelessRouterSetup.calcRoutePrefix();
	static readonly infix = StatelessRouterSetup.calcRouteInfix();

	constructor() {}

	public addConfig(config: RouteConfig<StoreStateT, any>) {
		this.routeConfigs.push(config);
		return this;
	}

	/**
	 * Get a route string for a specified state. Uses defaults where
	 *
	 * @param  {Object}} map
	 * @return {string} [description]
	 */
	public encodeAsHref(storeState: StoreStateT): string {
		return (
			StatelessRouterSetup.prefix +
			addLeadingSlash(this.getRouteString(storeState))
		);
	}

	protected getRouteString(store?: StoreStateT): string {
		store = store || ({} as StoreStateT);
		var params = {},
			key,
			str;

		let fragments: string[] = [];
		let configs = this.routeConfigs;

		function encodeValArray(vals) {
			return vals.join('/');
		}

		for (let i = 0, l = configs.length; i < l; ++i) {
			params = extend(params, configs[i].encode(store));
		}

		let leadChunk = '';

		for (key in params) {
			if (key === '') {
				// if it has an empty key, add to the beginning
				leadChunk = encodeValArray(params[key]);
			} else {
				str =
					encodeURIComponent(key) + '=' + encodeValArray(params[key]);
				fragments.push(str);
			}
		}

		let chunks = [leadChunk];

		if (fragments.length) {
			chunks.push(fragments.join('&'));
		}

		return chunks.join(StatelessRouterSetup.infix);
	}

	protected decodeHrefToState(state: any, path: string): any {
		// prepare `false` causes this process to complete synchronously
		return this._decodeHref(state, path, { prepare: false }).getResultNow();
	}

	loadAndDecodeHrefToState(state: any, path: string): any {
		return this._decodeHref(state, path, { prepare: true });
	}

	private _decodeHref(
		state: any,
		path: string,
		opts: { prepare: boolean }
	): AsapPromise<any> {
		// Decode url to state
		const configs = this.routeConfigs;
		const urlProps = StatelessRouterSetup.urlPropsFromPath(path);

		return asapReduce(
			configs,
			(memo, config) => {
				return config.decode(urlProps, state, memo, opts);
			},
			{} as any
		).then((nextState) => {
			return Object.keys(nextState).reduce(
				(acc, key) => {
					acc[key] = { ...acc[key], ...nextState[key] };
					return acc;
				},
				{ ...state }
			);
		});
	}

	private static urlPropsFromPath(path: string) {
		function decodeValArray(inStr) {
			return inStr.split('/');
		}

		// break up URL keyed chunks
		const fragments = path.split(/\?|&/g);

		return fragments.reduce((memo, fragment) => {
			let keyValPair = fragment.split('=');

			if (keyValPair.length === 2) {
				memo[decodeURIComponent(keyValPair[0])] = decodeValArray(
					keyValPair[1]
				);
			} else if (keyValPair.length === 1) {
				memo[''] = decodeValArray(keyValPair[0]);
			}

			return memo;
		}, {});
	}

	private static calcRoutePrefix() {
		return ROUTER_MODE === 'hash' ? '#' : '/';
	}

	private static calcRouteInfix() {
		return ROUTER_MODE === 'hash' ? '&' : '?';
	}
}
