/**
 * Intro Modal
 */

import React from 'react';
import AriaModal from 'react-aria-modal';
import ReactMarkdownText from './text/ReactMarkdownText';
import Colors from '../utils/color';
import { IRegionMeta } from '../app/View';
import { StoreVault } from '../app/state';
import { getRouter } from '../router/routes';
import getContextualLexicon from '../data/contextualLexicon';
import AppConfig from '../app/config/AppConfig';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

interface IIntroModalProps {
	isOpen: boolean;
	regions: IRegionMeta[];
}

const IntroModal: React.FC<IIntroModalProps> = ({ isOpen, regions }) => {
	const lexicon = getContextualLexicon();
	return (
		<div
			style={{
				backgroundColor: Colors.white,
				backgroundImage: `url(${require('../../img/splash-compressed.jpg')})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				overflow: 'scroll',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 1050
			}}
		>
			<div
				id="splash"
				className="splash"
				style={{
					background: Colors.white,
					padding: '80px 160px',
					border: `1px solid ${Colors.lightGray}`,
					width: '100%',

					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
				// onClick={() => set(false)}
			>
				<div className="splash-body">
					<div className="splash-head">
						<span className="title">
							{lexicon.SPLASH_MODAL.Title}
						</span>
						<span className="subtitle">
							{lexicon.HEADER.SubTitle}
						</span>
					</div>

					<video
						autoPlay={true}
						muted={true}
						loop={true}
						style={{
							width: '376px',
							marginRight: '60px',
							cursor: 'pointer'
						}}
					>
						<source
							src={require('../../img/mov.mp4')}
							type="video/mp4"
						/>
					</video>
					<div
						className="splash-text"
						style={{
							maxWidth: '700px',
							visibility: regions ? 'visible' : 'hidden'
						}}
					>
						<div
							className="text_splash-body"
							style={{ paddingBottom: '20px' }}
						>
							{lexicon.SPLASH_MODAL.SubTitle}
						</div>
						<div className="splash-menu">
							{(regions || []).map((region, idx) => (
								<div key={idx}>
									<img
										src={`${AppConfig.assetPath}region-icons/${region.key}.svg`}
									/>
									<a
										className="button button--invert"
										href={getRouter().encodeAsHref({
											nav: { Region: region.key }
										} as any)}
										onClick={() => {
											sendAnalyticsUIInteraction(
												'SELECT',
												analyticsCategories.LANDING_PAGE,
												'REGION_BUTTON',
												{
													key: 'region_id',
													value: region.key
												}
											);
										}}
										style={{ maxWidth: '250px' }}
									>
										Explore <strong>{region.name}</strong>
									</a>
								</div>
							))}
						</div>

						<ReactMarkdownText source={lexicon.SPLASH_MODAL.Body} />
					</div>
					<div className="signature">
						<img
							src={require('../../icon/TNCLogoPrimary_CMYK.svg')}
							style={{ height: '48px' }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntroModal;
