/**
 * Component for demonstrating selection components for the nav.
 */

import React, { useState } from 'react';
import {
	TData,
	IObservation,
	IStudy
} from '../../../../preprocess/src/ts/exec';
import Colors from '../utils/color';

import Legend from './legend';
import Portal from './Portal';
import ReactTooltip from 'react-tooltip';
import { StudyTooltip, DefaultTooltipProps } from './tooltips';

import ValueTransition from './valueTransition';
import { INavState, SET } from '../nav/state';
import { get, isNil, sortBy } from 'lodash-es';
import { openStudyHref } from './referencesPanel';
import getContextualLexicon from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';
import { SortIcon } from './sortIcon';

interface IObservationTableProps {
	store: any;
	DATA: TData;
	nav: INavState;
	filteredObservationsByUID: number[];
}

const ObservationTable: React.FC<IObservationTableProps> = ({
	store,
	DATA,
	nav,
	filteredObservationsByUID
}) => {
	const lexicon = getContextualLexicon(nav);
	const { Practice, Outcome, GLII, GLIII } = nav;

	const [tooltipStudy, setTooltipStudy] = useState(undefined as IStudy);
	const [alphaSort, setAlphaSort] = useState({ alpha: true, reverse: false });
	const [numSort, setNumSort] = useState({ num: false, reverse: false });

	const sortDict = {
		alpha: (list) => sortBy(list, (listItem) => listItem.alpha),
		num: (list) => sortBy(list, (listItem) => listItem.num)
	};

	let observationList: {
		o: IObservation;
		alpha: string;
		num: number;
		key: number;
	}[] = filteredObservationsByUID.map((UID, idx) => {
		let o = DATA.observationDictionary[UID];
		return { o, alpha: o.rv, num: o.percentChange, key: idx };
	});

	observationList = alphaSort.alpha
		? alphaSort.reverse
			? sortDict.alpha(observationList).reverse()
			: sortDict.alpha(observationList)
		: numSort.reverse
		? sortDict.num(observationList).reverse()
		: sortDict.num(observationList);

	const groupIIINorm = get(DATA.practicesAndOutcomesTree, [
		Practice,
		'groups',
		Outcome,
		'groups',
		GLII,
		'groups',
		GLIII
	]).normativeInterpretation;

	let norms = {
		negative: 0,
		positive: 2,
		'assumed societal benefit': 2,
		'assumed societal harm': 0,
		'dependent on other factors': 1
	};

	const normColors = [
		Colors.unfavorablelight,
		Colors.indeterminateLight,
		Colors.favorableLight
	];

	return (
		<div className="observation-table">
			<div className="table">
				<div key={' '} className="table_header text_data-heading">
					<div className="col-I">
						{lexicon.DASHBOARD['P&O_PANEL'].O_TABLE.COLUMNS.I}{' '}
						<SortIcon
							{...{
								reverse: alphaSort.reverse,
								active: alphaSort.alpha,
								onClick: () => {
									sendAnalyticsUIInteraction(
										'SELECT',
										analyticsCategories.STAGE,
										'sort-observation-table'
									);
									setNumSort({
										num: false,
										reverse: numSort.reverse
									});
									setAlphaSort({
										alpha: true,
										reverse: alphaSort.alpha
											? !alphaSort.reverse
											: alphaSort.reverse
									});
								}
							}}
						/>
					</div>
					<div className="col-II">
						{lexicon.DASHBOARD['P&O_PANEL'].O_TABLE.COLUMNS.II}
					</div>
					<div className="col-III">
						{lexicon.DASHBOARD['P&O_PANEL'].O_TABLE.COLUMNS.III}
					</div>
					<div className="col-IV">
						{lexicon.DASHBOARD['P&O_PANEL'].O_TABLE.COLUMNS.IV}
						<SortIcon
							{...{
								reverse: numSort.reverse,
								active: numSort.num,
								onClick: () => {
									setNumSort({
										num: true,
										reverse: numSort.num
											? !numSort.reverse
											: numSort.reverse
									});
									setAlphaSort({
										alpha: false,
										reverse: alphaSort.reverse
									});
								}
							}}
						/>
					</div>
				</div>
				<ValueTransition
					stableKey={'norm'}
					stableValue={observationList}
					norm={observationList}
					duration={200}
					render={({ state, stableValue }) => (
						<div
							className={`flash-transition-${state} table_data text_data-value`}
						>
							{stableValue.map(({ o, key }) => {
								let studyUID = o.studyUID;

								const backgroundColor =
									o.percentChange === 0
										? Colors.indeterminateLight
										: Math.sign(o.percentChange) === 1
										? normColors[
												norms[
													groupIIINorm.toLowerCase()
												]
										  ]
										: [...normColors].reverse()[
												norms[
													groupIIINorm.toLowerCase()
												]
										  ];

								return (
									<div
										key={key}
										data-tip
										data-for="observation-table-tooltip"
										onMouseEnter={() => {
											setTooltipStudy(
												DATA.studyDictionary[o.studyUID]
											);
										}}
										onMouseLeave={() => {
											setTooltipStudy(undefined);
										}}
										onClick={
											studyUID !== 'Unavailable'
												? () => {
														sendAnalyticsUIInteraction(
															'SELECT',
															analyticsCategories.STAGE,
															'observation-row'
														);
														openStudyHref(
															DATA
																.studyDictionary[
																o.studyUID
															]
														);
												  }
												: () => undefined
										}
										style={{
											cursor:
												studyUID !== 'Unavailable'
													? 'pointer'
													: 'default'
										}}
									>
										<div className="row">
											<div
												className="col-I"
												style={{
													backgroundColor
												}}
											>
												{o.rv}
											</div>
											<div
												className="col-II"
												style={{
													backgroundColor
												}}
											>
												{o.controlValue +
													' ' +
													o.rvUnits}
											</div>
											<div
												className="col-III"
												style={{
													backgroundColor
												}}
											>
												{o.trtValue + ' ' + o.rvUnits}
											</div>
											<div
												className="col-IV"
												style={{
													backgroundColor
												}}
											>
												{isNil(o.percentChange)
													? 'NA'
													: o.percentChange + '%'}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				/>
			</div>
			<Legend bean={false} norm={true} DATA={DATA} lexicon={lexicon} />
			<Portal container={document.body}>
				<ReactTooltip
					id="observation-table-tooltip"
					{...{ ...DefaultTooltipProps, place: 'right' }}
				>
					<StudyTooltip study={tooltipStudy} table={true} />
				</ReactTooltip>
			</Portal>
		</div>
	);
};

export default ObservationTable;
