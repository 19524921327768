/**
 * Utility for reflecting a point array about an axis
 */

export default function reflectPoints(
	pts: [number, number][],
	pivotVal: number,
	pivotOnYAxis: boolean,
	concat?: boolean
) {
	const piv = (v: number) => pivotVal - (v - pivotVal);
	const id = (v: number) => v;
	const x = pivotOnYAxis ? piv : id;
	const y = pivotOnYAxis ? id : piv;
	const ptsPrime = pts.map(p => [x(p[0]), y(p[1])]).reverse() as [
		number,
		number
	][];
	if (concat) {
		return pts.concat(ptsPrime);
	}
	return ptsPrime;
}
