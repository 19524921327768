/**
 *  Value Transition Component
 */

import React from 'react';

import { isEqual, debounce } from 'lodash';
import { Transition } from 'react-transition-group';

interface IValueTransitionProps {
	stableKey: string;
	stableValue: any;
	render: any;
	duration: number;
}

interface IValueTransitionState {
	stable: boolean;
	stabilize: () => void;
	stableValue: any;
}

export default class ValueTransition extends React.Component<
	any,
	IValueTransitionState
> {
	constructor(props) {
		super(props);
		this.state = {
			stable: true,
			stabilize: this.stabilize.bind(this),
			stableValue: props[props.stableKey]
		};
	}
	static getDerivedStateFromProps(props, state) {
		if (!isEqual(state.stableValue, props[props.stableKey])) {
			setTimeout(
				() => state.stabilize(props[props.stableKey]),
				props.duration
			);
			return {
				...state,
				stable: false
			};
		}

		return { ...state };
	}
	stabilize = debounce(
		stableValue => this.setState({ stable: true, stableValue }),
		60,
		{
			trailing: true
		}
	);
	render() {
		return (
			<Transition in={this.state.stable} timeout={this.props.duration}>
				{state =>
					this.props.render({
						state,
						stableValue: this.state.stableValue
					})
				}
			</Transition>
		);
	}
}
