/**
 * References List
 */

import React from 'react';
import { INavState, SET } from '../nav/state';
import { IStudy, TData } from '../../../../preprocess/src/ts/exec';
import OutsideClickHandler from 'react-outside-click-handler';
import { times, difference } from 'lodash-es';

interface IReferencesProps {
	nav: INavState;
	store: any;
	DATA: TData;
	filteredStudyUIDs: string[];
}

export function makeDoiHref(study: IStudy) {
	return `https://doi.org/${study.doi}`;
}

export function openStudyHref(study: IStudy) {
	window.open(makeDoiHref(study));
}

const ReferencesPanel: React.FC<IReferencesProps> = ({
	DATA,
	nav,
	store,
	filteredStudyUIDs: listOfStudyUIDs
}) => {
	const { Studies } = nav;

	return (
		<div className="references">
			{listOfStudyUIDs.map((studyUID, idx) => {
				let study = DATA.studyDictionary[studyUID];
				return (
					<a
						key={idx}
						className="reference"
						target="_blank"
						href={makeDoiHref(study)}
						style={{
							pointerEvents:
								study.doi !== 'Unavailable' ? 'auto' : 'none',
							cursor:
								study.doi !== 'Unavailable'
									? 'pointer'
									: 'default'
						}}
					>
						<div className="number text_tooltip-data-label text--clover">
							<span>{idx + 1}</span>
						</div>

						<div className="text_tooltip-body">
							{`${study.authors} (${study.pubyear}). ${study.title}. ${study.journal}, ${study.volume_issue}: ${study.pages}. `}

							{study.doi && (
								<span className="doi text--clover">
									{`DOI: ${study.doi}`}
								</span>
							)}
						</div>
					</a>
				);
			})}
		</div>
	);
};

export default ReferencesPanel;
