/* var raw = {defaults: {own: 'own', a: {b: 'hello'}}, overrides: [{id:'sub', defaults: {own: 'theirs', a: {b: 'world'}}}]};
var lc = lexiconContextualizer(raw, a => a, a => a=> a, a => a);
lc.getContextualLexicon([])
lc.getContextualLexicon(['sub'])
lc.getContextualLexicon([]) */

import { defaultsOverridesContextualizer } from 'periscopiclibs';
import { LEX } from './rawLexicon';

interface IContextData {
	Region?: string;
}

function contextToAddress(ctx: IContextData) {
	return ctx?.Region ? [ctx.Region] : [];
}

function normalizeContext(ctx?: IContextData) {
	return ctx ?? {};
}

function getProcessFunction(ctx: IContextData) {
	return (s: string) => s;
}

export type Lexicon = typeof LEX.defaults;

const contextualizer = defaultsOverridesContextualizer(
	LEX as any,
	contextToAddress,
	getProcessFunction,
	normalizeContext
);
//export const getContextualLexicon = contextualizer.getContextualDict as unknown as (ctx?: IContextData) => ILexicon<string>;
export const getContextualLexicon =
	contextualizer.getProcessedContextualDict as unknown as (
		ctx?: IContextData
	) => Lexicon;

export default getContextualLexicon;
