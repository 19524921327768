/**
 * Content adapters for various levels of content hierarchy
 */

import React from 'react';
import { INavState, SET } from '../nav/state';

import Heatmap, { SmallHeatmap, MediumHeatmap } from './heatmap';
import Beanfield, { MediumBeanfield, SmallBeanfield } from './beanfield';
import ObservationTable from './observationTable';
import References from './referencesPanel';
import { TData } from '../../../../preprocess/src/ts/exec';
import { IFilterObject } from './filters';

import { filter, last } from 'lodash-es';
import getContextualLexicon, { Lexicon } from '../data/contextualLexicon';
import { InsightsNode } from '../utils/InsightsTree';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

export interface IDashboardProps {
	store: any;
	nav: INavState;
	DATA: TData;
	isSplashModalOpen: boolean;
	setIsExplainModalOpen: (x: boolean) => void;
	setIsDownloadModalOpen?: (x: boolean) => void;
	isSmallScreen: boolean;
	setOpenInsightIndex: (n: number) => void;
}

export interface ITitleProps {
	nav?: INavState;
	store: any;
	practice: string;
	levels: string[];
	setIsExplainModalOpen: (x: boolean) => void;
	isQThrobbing?: boolean;
	setIsQThrobbing?: (b: boolean) => void;
}

export interface ICrumbProps {
	store: any;
	nav: INavState;
	DATA: TData;
}

export interface IDashboardAdapter {
	depth?: number;
	key: string;
	Content: React.FC<
		IDashboardProps & {
			filterObjectArray: IFilterObject[];
			filteredObservationsByUID: number[];
		}
	>;
	Title: React.FC<ITitleProps>;
}

export interface ILevelAdapter extends IDashboardAdapter {
	Crumb?: React.FC<ICrumbProps & { hover: boolean }>;
	CrumbDetail?: React.FC<ICrumbProps>;
}

export const levelAdapters: ILevelAdapter[] = [
	{
		key: 'level1',
		depth: 0,
		Content: (props) => <Heatmap {...props} />,
		Title: ({ nav }) => {
			const lexicon = getContextualLexicon(nav);
			return <div>{lexicon.DASHBOARD['P&O_PANEL'].HEATMAP.Title}</div>;
		},
		Crumb: (props) => <SmallHeatmap {...props} />,
		CrumbDetail: (props) => <MediumHeatmap {...props} />
	},
	{
		key: 'level2',
		depth: 1,
		Content: (props) => (
			<Beanfield
				{...{
					...props,
					level: 2
				}}
			/>
		),
		Title: ({
			setIsExplainModalOpen,
			practice,
			levels,
			isQThrobbing,
			setIsQThrobbing
		}) => (
			<>
				<div>{`Observed Changes from ${practice} on ${last(
					filter(levels)
				)}`}</div>
				<QuestionMark
					isThrobbing={isQThrobbing}
					set={() => (
						isQThrobbing && setIsQThrobbing(false),
						setIsExplainModalOpen(true)
					)}
				/>
			</>
		),
		Crumb: (props) => (
			<SmallBeanfield
				{...{
					...props,
					level: 2,
					onClick: () => {
						sendAnalyticsUIInteraction(
							'SELECT',
							analyticsCategories.BREADCRUMBS,
							'plot-group'
						);
						props.store.dispatch({
							type: SET,
							val: {
								GLII: '',
								GLIII: '',
								Carousel: 0
							}
						});
					}
				}}
			/>
		),
		CrumbDetail: (props) => <MediumBeanfield {...{ ...props, level: 2 }} />
	},
	{
		key: 'level3',
		depth: 2,
		Content: (props) => (
			<Beanfield
				{...{
					...props,
					level: 3
				}}
			/>
		),
		Title: ({
			setIsExplainModalOpen,
			practice,
			levels,
			isQThrobbing,
			setIsQThrobbing
		}) => (
			<>
				<div>{`Observed Changes from ${practice} on ${last(
					filter(levels)
				)}`}</div>
				<QuestionMark
					isThrobbing={isQThrobbing}
					set={() => (
						isQThrobbing && setIsQThrobbing(false),
						setIsExplainModalOpen(true)
					)}
				/>
			</>
		),
		Crumb: (props) => (
			<SmallBeanfield
				{...{
					...props,
					level: 3,
					onClick: () => {
						sendAnalyticsUIInteraction(
							'SELECT',
							analyticsCategories.BREADCRUMBS,
							'plot-group'
						);
						props.store.dispatch({
							type: SET,
							val: {
								GLIII: '',
								Carousel: 0
							}
						});
					}
				}}
			/>
		),
		CrumbDetail: (props) => <MediumBeanfield {...{ ...props, level: 3 }} />
	},
	{
		key: 'level4',
		depth: 3,
		Content: (props) => <ObservationTable {...props} />,
		Title: ({ setIsExplainModalOpen, practice, levels }) => (
			<div>{`Observed Changes from ${practice} on ${last(
				filter(levels)
			)}`}</div>
		)
	}
];

export function getLevelAdapterIndex(nav: INavState) {
	// index is first one for which we don't have data
	return [nav.Outcome, nav.GLII, nav.GLIII, ''].indexOf('');
}

export function getLevelAdapter(nav: INavState) {
	return levelAdapters[getLevelAdapterIndex(nav)];
}

const QuestionMark = ({ set, isThrobbing }) => {
	return (
		<svg
			className={`question ${isThrobbing ? 'throbbing' : ''}`}
			width={21}
			height={21}
			viewBox="0 0 21 21"
			onClick={() => set(true)}
		>
			<g transform="translate(1 1)" fill="none" fillRule="evenodd">
				<text fontFamily="Verdana" fontSize={12} fill="#45812B">
					<tspan x={6.455} y={14}>
						{'?'}
					</tspan>
				</text>
				<circle stroke="#D1D1D1" cx={9.5} cy={9.5} r={9.5} />
			</g>
		</svg>
	);
};
