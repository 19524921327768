/**
 * Button for linking to insights
 */

import React, { useMemo } from 'react';
import {
	findInInsightsTree,
	IInsightData,
	InsightsNode
} from '../utils/InsightsTree';
import { InsightLink } from '../../../../preprocess/src/ts/prepObservations';
import Portal from './Portal';
import ReactTooltip from 'react-tooltip';
import { DefaultTooltipProps } from './tooltips';
import InsightTooltip from './InsightTooltip';
import { Lexicon } from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

const InsightButton: React.FC<{
	tree: InsightsNode<IInsightData>;
	link: InsightLink;
	setOpenInsightIndex: (n: number) => void;
	lexicon: Lexicon;
	xPosition?: number;
}> = ({ tree, link, setOpenInsightIndex, lexicon, xPosition }) => {
	const value = useMemo(() => findInInsightsTree(tree, link), [tree, link]);
	const dataKey = 'bean_insight_' + value?.index;
	return (
		value !== undefined && (
			<svg
				version="1.1"
				id="Layer_1"
				x="0px"
				y="0px"
				viewBox="0 0 33 33"
				enableBackground="new 0 0 33 33"
				className="insight-button throbbing"
				data-tip
				data-for={dataKey}
				style={{ transform: `translate(${xPosition ?? 0}px, 0)` }}
				onClick={(e) => {
					e.stopPropagation();
					sendAnalyticsUIInteraction(
						'SELECT',
						analyticsCategories.STAGE,
						'insight-button'
					);
					setOpenInsightIndex(value.index);
					// TODO: scroll to an anchor
					window.scrollTo?.({ top: 830, behavior: 'smooth' });
				}}
			>
				<path
					fill="#FFFFFF"
					d="M16.5,0C25.6,0,33,7.4,33,16.5s-10.9,15-16.5,15c-5.9,0-16.5-5.9-16.5-15S7.4,0,16.5,0z"
				/>
				<path fill="#45812B" d="M15.5,12.5h2v12h-2V12.5z" />
				<circle
					fill="none"
					stroke="#D1D1D1"
					strokeWidth="2"
					strokeMiterlimit="10"
					cx="16.5"
					cy="16.5"
					r="15.5"
				/>
				<path
					fill="#45812B"
					d="M16.5,10.3L16.5,10.3c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h0C17.3,7.3,18,8,18,8.8v0
	C18,9.6,17.3,10.3,16.5,10.3z"
				/>
				<Portal container={document.body}>
					<ReactTooltip
						id={dataKey}
						getContent={() => {
							return (
								<InsightTooltip {...{ data: value, lexicon }} />
							);
						}}
						{...{
							...DefaultTooltipProps,
							place: 'right',
							effect: 'float'
						}}
					/>
				</Portal>
			</svg>
		)
	);
};

export default InsightButton;
