/**
 * Filters Panel
 */

import React, { useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TData } from '../../../../preprocess/src/ts/exec';
import { IFilterObject, Filter } from './filters';
import { Panel } from './Panel';

export const FiltersPanel: React.FC<{
	open: boolean;
	setOpen: { (o: boolean): void };
	filterObjectArray: IFilterObject[];
	filteredObservationsByUID: number[];
	DATA: TData;
}> = ({
	open,
	setOpen,
	filterObjectArray,
	filteredObservationsByUID,
	DATA
}) => {
	const ref = useRef<HTMLDivElement>();

	const sortedFilterObjectArray = [];

	filterObjectArray.forEach((filter, index) => {
		const newOptions = [];

		if (filter.label === 'Soil Sample Depth') {
			filter.options.forEach((o) => {
				newOptions.push({
					...o,
					sortKey: Number(o.value.split('-')[1]?.split(' ')[0])
				});
			});
		} else
			filter.options.forEach((o) => {
				newOptions.push({ ...o, sortKey: o.value });
			});

		const sortedOptions = newOptions.sort((a, b) => a.sortKey - b.sortKey);

		const sortedFilter = { ...filter, options: sortedOptions };
		sortedFilterObjectArray.push(sortedFilter);
	});

	const nonHighlighted = sortedFilterObjectArray.filter((o) => !o.highlight);
	const highlighted = sortedFilterObjectArray.filter((o) => o.highlight);
	const newOrderFilter = [...nonHighlighted, ...highlighted];

	useEffect(() => {
		if (open) {
			const onDown = (e: PointerEvent) => {
				// test if click is outside
				const inside =
					ref.current &&
					(ref.current === e.target ||
						ref.current.contains(e.target as any));
				if (!inside) {
					// close
					setOpen(false);
				}
			};
			document.body.addEventListener('pointerdown', onDown);
			return () => {
				document.removeEventListener('pointerdown', onDown);
			};
		}
	}, [open]);

	return (
		<TransitionGroup>
			{open && (
				<CSSTransition classNames="anchor-right" timeout={300}>
					<Panel
						ref={ref}
						containerClass="filters-panel"
						contentClass="filters filters-vertical"
						renderControls={() => (
							<div
								className="close-btn"
								onClick={() => setOpen(false)}
							>
								<img src={require('../../icon/close_x.svg')} />
							</div>
						)}
					>
						{newOrderFilter.map((filter, idx) => (
							<Filter
								key={idx}
								{...{
									DATA,
									filter,
									idx,
									filteredObservationsByUID
								}}
							/>
						))}
					</Panel>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
};
