/**
 * Component for ticks in bean plots
 */

import React from 'react';
import { Power3 } from 'gsap';
import * as array from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import AnimatedPoints from './AnimatedPoints';
import scaleInY from './fx/scaleInY';
import { partialRight } from 'lodash-es';

const inFx = partialRight(scaleInY, {
	delay: 0.5,
	duration: 0.5,
	ease: Power3.easeOut
});

const AnimatedTicks: React.FC<{
	y: ScaleLinear<number, number>;
	bins: array.Bin<number, number>[];
}> = ({ y, bins }) => {
	function updatePoint(tick: SVGUseElement, pt: [number, number]) {
		tick.setAttributeNS(
			null,
			'transform',
			`translate(0 ${pt[1].toFixed(3)}) scale(${pt[0].toFixed(3)} 1)`
		);
	}

	function renderPoint(pt: [number, number], idx: number) {
		return (
			<use
				key={idx}
				xlinkHref="#tick"
				transform={`translate(0 0) scale(0 1)`}
			/>
		);
	}

	const points: [number, number][] = bins.map(pt => [
		Math.min(1, pt.length),
		pt.x0
	]);

	return (
		<AnimatedPoints
			{...{
				component: 'g',
				y,
				points,
				updatePoint,
				renderPoint,
				containerEffect: inFx,
				containerProps: { style: { transform: 'scale(1, 0)' } }
			}}
		/>
	);
};

export default AnimatedTicks;
