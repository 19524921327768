/**
 * Effect for intro animation of an element
 */

import { TweenLite } from 'gsap';

export default function scaleInX(
	el: HTMLElement | SVGElement,
	opts: { delay: number; duration: number; ease: { (v: number): number } }
) {
	// scale up animation
	const proxy = { scaleX: 0 };
	const tw = TweenLite.to(proxy, opts.duration, {
		delay: opts.delay,
		ease: opts.ease,
		scaleX: 1,
		onUpdate: () => {
			el.style.transform = `scale(${proxy.scaleX.toFixed(3)},1)`;
		}
	});

	return () => tw.kill();
}
