/**
 * The root of the version of the isomorphic application that is run on the client.
 */

require('../shared/sass/style.scss');

// Promise polyfill
import * as e6p from 'es6-promise';
(e6p as any).polyfill();
import 'isomorphic-fetch';

import startup from './startup';

// Router deps
import RouterSetup from '../shared/ts/router/redux/RouterSetup';
import registerRoutes from '../shared/ts/router/routes';
import { StoreVault, AppState } from '../shared/ts/app/state';
import { DEFAULTS as NAV_DEFAULTS } from '../shared/ts/nav/state';

// Create router and register routes
const router = new RouterSetup();
registerRoutes(router);

// Get initial store state from url path
const urlLocation = RouterSetup.getHistory().location.pathname;

const initialState: AppState = { nav: NAV_DEFAULTS };

router
	.loadAndDecodeHrefToState(initialState, urlLocation as any)
	.then(initialState => {
		// Create Redux store with initial state and router
		StoreVault.initStore(router, initialState);
		const store = StoreVault.getStore();

		// Initialize the router
		router.setStore(store).init();

		// Get the party started
		startup(store);
	});
