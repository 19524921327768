/**
 * Download Modal
 */

import React from 'react';
import AriaModal from 'react-aria-modal';
import ReactMarkdownText from './text/ReactMarkdownText';
import Colors from '../utils/color';
import { Lexicon } from '../data/contextualLexicon';

interface IDownloadModalProps {
	set: (v: boolean) => void;
	lexicon: Lexicon;
}

const DownloadModal: React.FC<IDownloadModalProps> = ({ set, lexicon }) => {
	return (
		<AriaModal
			titleText="AgEvidence"
			initialFocus="#download"
			getApplicationNode={() => document.getElementsByName('body')[0]}
			underlayStyle={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<div
				id="download"
				style={{
					background: 'white',
					width: '792px',
					display: 'flex',
					border: `1px solid ${Colors.darkGray}`
				}}
			>
				<div
					style={{
						height: '375px',
						width: '257px',
						borderRight: `1px solid ${Colors.darkGray}`
					}}
				>
					<img
						src={require('../../img/TNC_download_image.jpg')}
						style={{ height: '375px', width: '255px' }}
					/>
				</div>
				<div
					style={{
						padding: '60px',
						display: 'flex',
						justifyContent: 'space-around',
						flexDirection: 'column'
					}}
				>
					<div className="text_title">
						{
							lexicon.DASHBOARD.CONTROL_PANEL.DOWNLOADS
								.DOWNLOAD_MODAL.Title
						}
					</div>
					<div className="text_splash-body">
						{
							lexicon.DASHBOARD.CONTROL_PANEL.DOWNLOADS
								.DOWNLOAD_MODAL.Paragraph_One
						}
					</div>
					<div className="text_splash-body">
						{
							lexicon.DASHBOARD.CONTROL_PANEL.DOWNLOADS
								.DOWNLOAD_MODAL.Paragraph_Two
						}
					</div>
				</div>
			</div>
		</AriaModal>
	);
};

export default DownloadModal;
