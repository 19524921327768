/**
 * Helper utility for RouterSetup to which methods that don't directly interace with router configrations are delegated.
 */

import {
	History,
	createBrowserHistory,
	createMemoryHistory,
	createHashHistory
} from 'history';
import { ROUTER_MODE } from './RouterConfig';
import StatelessRouterSetup from './StatelessRouterSetup';

export default class RouterSetupHelpers {
	private static _history: History;

	// Delegated from RouterSetup.onAnchorClick
	static onAnchorClick(e: React.MouseEvent<HTMLAnchorElement> | MouseEvent) {
		if (
			!(
				// don't intercept command/alt clicks
				// or right button clicks
				(
					e.metaKey === true ||
					e.button !== 0 ||
					// attempt routing
					RouterSetupHelpers.routeToLocation(
						e.currentTarget as HTMLAnchorElement
					)
				)
			)
		) {
			// if routed, prevent default behavior
			e.preventDefault();
			return false;
		}
		return true;
	}

	// Delegated from RouterSetup.onHitAreaClick
	static onHitAreaClick(e: React.MouseEvent<HTMLElement> | MouseEvent) {
		const currentTarget = e.currentTarget as HTMLElement;
		if (
			!(
				// don't intercept command/alt clicks
				// or right button clicks
				(
					e.metaKey === true ||
					e.button !== 0 ||
					// attempt routing
					RouterSetupHelpers.routeToLocation(
						currentTarget.getAttribute('data-href')
					)
				)
			)
		) {
			// if routed, prevent default behavior
			e.preventDefault();
			return false;
		}
		return true;
	}

	static routeToLocation(location: HTMLAnchorElement | string) {
		let a: HTMLAnchorElement;

		if (typeof location === 'string') {
			a = document.createElement('a');
			a.href = location;
		} else {
			a = location;
		}

		// TODO: make sure hostname and any path
		// that precedes app are shared
		if (a && (a.host === '' || a.host === window.location.host)) {
			// remove any trailing slash from path
			let route = (a.pathname || '').replace(/\/$/, '');

			if (a.hash && a.hash.length > 1) {
				route += a.hash;
			}

			if (RouterSetupHelpers.navigate(route)) {
				return false;
			}
		}
		return true;
	}

	static navigate(route: string) {
		let url = '/' + RouterSetupHelpers.trimPrefix(route);
		const history = RouterSetupHelpers.getHistory();

		if (history && history.push) {
			history.push(url);
			return true;
		}

		return false;
	}

	static trimPrefix(route: string) {
		if (route && route.charAt(0) === StatelessRouterSetup.prefix) {
			route = route.substring(1);
		}

		return route;
	}

	private static createHistory() {
		const env = ROUTER_MODE || 'browser';

		if (env === 'browser') {
			return createBrowserHistory({
				forceRefresh: false
			} as any);
		} else if (env === 'hash') {
			return createHashHistory({
				basename: '',
				hashType: 'noslash'
			} as any);
		} else {
			return createMemoryHistory();
		}
	}
	static getHistory() {
		return (RouterSetupHelpers._history =
			RouterSetupHelpers._history || RouterSetupHelpers.createHistory());
	}
}
