/**
 * Colors
 */

const Colors = {
	white: '#ffffff',
	lightGray: '#d1d1d1',
	mediumGray: '#5c5c5c',
	darkGray: '#3c3c3c',

	clover: '#45812b',
	frost: '#5e94b6',

	favorableLight: '#e2e9b7', // '#e4e7ce'
	favorableDark: '#90a301', //
	indeterminateLight: '#FFEfbc', // '#fdf3d4'
	indeterminateDark: '#e7b73d',
	unfavorablelight: '#Edbda0', // '#f5d4be'
	unfavorableDark: '#8d3a07',

	heatmapLight: 'white',
	heatmapDark: '#6C8F35' // '#4c7900' // '#987034'
};
export default Colors;
