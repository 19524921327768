/**
 * Utility turning an image into a data url
 */

function loadImage(url: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		let img = document.createElement('img');
		img.onload = () => {
			resolve(img);
		};
		img.src = url;
	});
}

export function loadImageAsDataURL(url: string, scale = 1): Promise<string> {
	return loadImage(url).then(img => imageAsDataURL(img, scale));
}

export default function imageAsDataURL(img: HTMLImageElement, scale = 1) {
	const cnv = document.createElement('canvas');
	cnv.width = img.width * scale;
	cnv.height = img.height * scale;
	const ctx = cnv.getContext('2d');
	ctx.drawImage(img, 0, 0, cnv.width, cnv.height);
	return cnv.toDataURL();
}
