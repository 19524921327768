/**
 * Application configurations are passed into
 * application space from html using this -
 * @author Brett Johnson, periscopic.com
 */

//pull in from global
declare var jsWebAppConfig: {
	//app configuration
	containerId: string;
	cacheBreak?: string | number;
	assetPath: string;
	analyticsId: string;
};

if (!jsWebAppConfig || typeof jsWebAppConfig !== 'object') {
	throw `global variable 'jsWebAppConfig' does not exist or is not an object`;
}

if (
	jsWebAppConfig.containerId === undefined ||
	typeof jsWebAppConfig.assetPath !== 'string'
) {
	throw `global variable 'jsWebAppConfig' does not have string field 'containerId'`;
} else {
	// guarantee no leading '#'
	jsWebAppConfig.containerId = jsWebAppConfig.containerId.replace(/^#/, '');
}

if (
	jsWebAppConfig.assetPath === undefined ||
	typeof jsWebAppConfig.assetPath !== 'string'
) {
	throw `global variable 'jsWebAppConfig' does not have string field 'assetPath'`;
} else {
	// guarantee trailing slash
	jsWebAppConfig.assetPath = jsWebAppConfig.assetPath.replace(
		/(?:\/)?$/,
		'/'
	);
}

if (jsWebAppConfig.cacheBreak === undefined) {
	// add a cache break if none provided
	jsWebAppConfig.cacheBreak = Date.now().toString();
}

export default jsWebAppConfig;
