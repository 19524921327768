/* tslint:disable:max-file-line-count */
/**
 * A react markdown component that utilizes Markdown-It (same markdown engine used by PDF rendering).
 * @author brett@periscopic.com
 **/

import MarkdownIt from 'markdown-it';
import MarkdownItSuperscript from 'markdown-it-sup';
import MarkdownItSubscript from 'markdown-it-sub';
import MarkdownItMultiMdTable from 'markdown-it-multimd-table';

import { fromPairs, findIndex } from 'lodash-es';
import camelcase from 'camelcase';
import { tableTokenRules } from './markdownTableUtils';

// the instance we'll use throughout
var md = new MarkdownIt({ linkify: true })
	//https://github.com/markdown-it/markdown-it/blob/e6f19eab4204122e85e4a342e0c1c8486ff40c2d/lib/presets/commonmark.js
	.disable([
		'code',
		'fence',
		'hr',
		'reference',
		'lheading',
		'blockquote',
		'backticks',
		'entity',
		'image'
	])
	.use(MarkdownItSuperscript)
	.use(MarkdownItSubscript)
	.use(MarkdownItMultiMdTable, { headerless: true, multiline: true });

function smartLinkTargets(tokens, idx) {
	var hrefIndex, aIndex;

	hrefIndex = tokens[idx].attrIndex('href');

	if (
		// has href
		hrefIndex >= 0 &&
		// has scheme or protocol
		/^(?:(?:https?|ftp):)?\/\//i.exec(tokens[idx].attrs[hrefIndex][1])
	) {
		aIndex = tokens[idx].attrIndex('target');

		if (aIndex < 0) {
			// add new attribute
			tokens[idx].attrPush(['target', '_blank']);
		} else {
			// replace value of existing attr
			tokens[idx].attrs[aIndex][1] = '_blank';
		}
	}
}

const tokenRules = {
	/** NOTE: these mutate tokens; that is how examples in markdown-it do it **/
	link_open: [smartLinkTargets],
	...tableTokenRules
};

export interface IMarkdownTreeTagNode {
	attrs?: { [key: string]: string };
	tag: string;
	children: IMarkdownTreeNode[];
}

export type IMarkdownTreeNode = IMarkdownTreeTagNode | string;

// typeguard
export function isMarkdownTreeTagNode(
	node: IMarkdownTreeNode
): node is IMarkdownTreeTagNode {
	return !!(node && (node as IMarkdownTreeTagNode).children);
}

/**
 * @constructor
 * Represents structured text that can be retrieved as either HTML
 * or a hierarchical structure. Facilitates rendering the same
 * text to either HTML or PDF.
 *
 * @param {string} mdText Markdown syntax text
 */
export default class MarkdownStructuredText {
	private _mdText: string;
	private _tokens: MarkdownIt.Token[] = null;
	private _rawText: string = null;
	private _env: any;
	private _html: string;
	private _tree: IMarkdownTreeNode[];

	constructor(mdText: string) {
		this._mdText = mdText;
	}

	toHtml() {
		this._lazyEvalHtml();
		return this._html;
	}

	toRawString() {
		function recurseTree(tree: MarkdownIt.Token[], text: string = '') {
			let item: MarkdownIt.Token;

			for (let i = 0, l = tree.length; i < l; ++i) {
				item = tree[i];

				if (item.children) {
					text += recurseTree(item.children);
				} else if (item.type === 'text') {
					text += item.content;
				}
			}

			return text;
		}

		this._lazyEval();

		this._rawText =
			this._rawText !== null ? this._rawText : recurseTree(this._tokens);

		return this._rawText;
	}

	getHtml() {
		this._lazyEvalHtml();
		return this._html;
	}

	getMarkdown() {
		return this._mdText;
	}

	getTokens() {
		this._lazyEval();
		return this._tokens;
	}

	getTree() {
		this._lazyEvalTree();
		return this._tree;
	}

	private _lazyEval() {
		if (this._tokens) {
			return;
		}

		this._env = {};

		let tokens = md.parse(this._mdText, this._env);

		// unwraps single 'p' tag
		if (tokens.length === 3 && tokens[0].type === 'paragraph_open') {
			// remove first and last elements
			tokens.pop();
			tokens.shift();
		}

		this._tokens = MarkdownStructuredText.applyTokenRules(tokens);
	}

	private _lazyEvalHtml() {
		if (this._html) {
			return;
		}
		this._lazyEval();
		this._html = md.renderer.render(this._tokens, {}, this._env);
	}

	private _lazyEvalTree() {
		if (this._tree) {
			return;
		}

		this._lazyEval();
		this._tree = MarkdownStructuredText.treeEvalTokens(this._tokens);
	}

	private static applyTokenRules(
		tokens: MarkdownIt.Token[]
	): MarkdownIt.Token[] {
		for (let i = 0, l = tokens.length; i < l; ++i) {
			let tok = tokens[i];
			let rules = tokenRules[tok.type];

			if (rules && rules.length) {
				rules.reduceRight(function (memo, rule) {
					rule(tokens, i);
				}, null);
			}

			if (tok.children) {
				tok.children = MarkdownStructuredText.applyTokenRules(
					tok.children
				);
			}
		}

		return tokens;
	}

	private static treeEvalTokens(
		tokens: MarkdownIt.Token[]
	): IMarkdownTreeNode[] {
		let targ: IMarkdownTreeNode[] = [];

		let cursor = 0;
		const l = tokens.length;

		while (cursor < l) {
			cursor = MarkdownStructuredText.treeEvalToken(tokens, cursor, targ);
		}

		return targ;
	}

	private static treeEvalToken(
		tokens: MarkdownIt.Token[],
		cursor: number,
		target: IMarkdownTreeNode[]
	): number {
		const tok = tokens[cursor];

		const tokProps = { key: cursor };
		let children: IMarkdownTreeNode[] = null;
		let nextCursor: number;

		switch (tok.nesting) {
			case 0:
				// singleton tag
				nextCursor = cursor + 1;

				if (tok.type === 'text') {
					// if text, then there is no tag, so we short circuit
					target.push(tok.content);
					return nextCursor;
				}

				if (tok.children && tok.children.length) {
					children = MarkdownStructuredText.treeEvalTokens(
						tok.children
					);
				}

				// if this node is just a wrapper without a tag
				if (tok.tag === '') {
					if (children && children.length) {
						Array.prototype.push.apply(target, children);
					}

					return nextCursor;
				}

				break;
			case 1:
				// open tag

				let closeIdx: number = findIndex(
					tokens,
					{ nesting: -1, tag: tok.tag },
					cursor + 1 // fromIndex
				);

				let childTokens = tokens.slice(cursor + 1, closeIdx);
				children = MarkdownStructuredText.treeEvalTokens(childTokens);
				nextCursor = closeIdx + 1;

				break;
			default:
				throw 'Unexpected nesting value';
		}

		target.push({
			attrs: tok.attrs ? fromPairs(tok.attrs) : null,
			tag: tok.tag,
			children
		});

		// return the index of the next unconsumed token
		return nextCursor;
	}

	truncate(maxLength: number) {
		let mst = new MarkdownStructuredText(null);
		this._lazyEvalTree();
		mst._tree = MarkdownStructuredText.splitTree(
			this._tree,
			Math.max(0, maxLength)
		);
		return mst;
	}

	/**
	 * Trim treenodes to a specified content characterlength. NOTE: currently uses shared references
	 * to attrs and tags of nodes for performances.
	 */
	private static splitTree(
		treeNodes: IMarkdownTreeNode[],
		length: number
	): IMarkdownTreeNode[] {
		let rem = length;
		function trim(nodes: IMarkdownTreeNode[]) {
			return nodes.reduce((memo, node) => {
				if (isMarkdownTreeTagNode(node)) {
					if (rem > 0) {
						let nodeSharedCopy: IMarkdownTreeTagNode = {
							tag: node.tag,
							attrs: node.attrs,
							children: trim(node.children)
						};

						memo = memo.concat(nodeSharedCopy);
					}
					// else ignore self and children
				} else {
					if (rem > 0) {
						memo = memo.concat(node.substr(0, rem));
						rem -= node.length;
					}
				}

				return memo;
			}, [] as IMarkdownTreeNode[]);
		}

		if (rem <= 0) {
			return [''];
		}

		return trim(treeNodes);
	}
}
