/**
 * Animated Radio-Buttons Component
 */

import React, { useMemo, useState } from 'react';
import { findIndex } from 'lodash';

interface IAnimatedRadioOpts<T>
	extends React.HtmlHTMLAttributes<HTMLDivElement> {
	containerProps?: React.HtmlHTMLAttributes<HTMLDivElement>;
	options: (T & { key: string })[];
	value: string;
	renderOption: {
		(props: {
			option: T & { key: string };
			index: number;
			selected: boolean;
		}): React.ReactElement;
	};
	renderHighlight: {
		(props: {
			box: { width: number; height: number; left: number; top: number };
		}): React.ReactElement;
	};
}

export function AnimatedRadio<T = {}>({
	options,
	value,
	renderOption,
	renderHighlight,
	containerProps,
	...props
}: IAnimatedRadioOpts<T>) {
	const [container, setContainer] = useState<HTMLDivElement>(null);
	const selectedIndex = findIndex(options, (o) => o.key === value);
	const selectedBox = useMemo(() => {
		if (!container || selectedIndex < 0) {
			return null;
		}

		const el = [...container.children][selectedIndex] as HTMLElement;
		return {
			left: el.offsetLeft,
			top: el.offsetTop,
			width: el.offsetWidth,
			height: el.offsetHeight
		};
	}, [selectedIndex, container]);

	return (
		<div {...props}>
			{selectedBox && renderHighlight({ box: selectedBox })}
			<div
				{...containerProps}
				ref={(el) => {
					if (container !== el && el) {
						setContainer(el);
					}
				}}
			>
				{options.map((option, index) =>
					renderOption({
						option,
						index,
						selected: selectedIndex === index
					})
				)}
			</div>
		</div>
	);
}
