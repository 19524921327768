/* tslint:disable:max-file-line-count */
/**
 * Map
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { TData, IStudy } from '../../../../preprocess/src/ts/exec';
import { CSSTransition } from 'react-transition-group';

import { StudyTooltip } from './tooltips';

import ReactMapboxGL, {
	Cluster,
	Marker,
	Popup,
	ZoomControl
} from 'react-mapbox-gl';

let CustomMarker = Marker as any;

import Colors from '../utils/color';
import {
	map,
	uniq,
	flatten,
	delay,
	isEqual,
	filter,
	uniqWith,
	groupBy,
	minBy,
	maxBy,
	find,
	fromPairs
} from 'lodash-es';
import { openStudyHref } from './referencesPanel';
import { makeMapZoneController } from './MapZoneController';
import { ReplaySubject } from 'rxjs';
import { map as rxmap, filter as rxfilter, startWith } from 'rxjs/operators';
import { IFilterObject } from './filters';
import { distinctUntilChanged } from 'rxjs/operators';

require('../../icon/carousel_right.svg');

interface IMapProps {
	store: any;
	DATA: TData;
	filterObjectArray: IFilterObject[];
	filteredStudiesAndLocations: {
		[key: string]: string[];
	};
	isSmallScreen: boolean;
}

let ACCESS_TOKEN =
	'pk.eyJ1IjoiYWdldmlkZW5jZWRlbW8iLCJhIjoiY2s5Y3p5d3JsMDg2dTNmcjN3dDBjczJ4ZyJ9.C56HB2wapvViIRc3hoFVtg';

const MapGL = ReactMapboxGL({
	accessToken: ACCESS_TOKEN,
	maxZoom: 14,
	customAttribution:
		'Kenya: Soil carbon basemap: Hengl et al., 2021. Sci Rep 11. Accessed July 2021; Farming systems: Dixon et al., 2001. FAO. Accessed August 2021. | United States: Soil Survey Staff, USDA NRCS, 2018. Soil Colors of the Continental United States. Available at: https://nrcs.app.box.com/v/soils. Accessed January 2020.'
});

const Map: React.FC<IMapProps> = ({
	store,
	DATA,
	filterObjectArray,
	filteredStudiesAndLocations,
	isSmallScreen
}) => {
	let PopupPortalRef = useRef(null);
	const [theMap, setTheMap] = useState<mapboxgl.Map>(null);

	let [locations, setLocations] = useState([]);
	let [activeStudyAndLocation, setActiveStudyAndLocation] = useState([
		undefined as IStudy,
		undefined as any
	]);

	let areaOfInterest: [[number, number], [number, number]] = [
		[DATA.map.bounds[0].lng, DATA.map.bounds[0].lat],
		[DATA.map.bounds[1].lng, DATA.map.bounds[1].lat]
	];

	let [center, setCenter] = useState([
		DATA.map.center.lng,
		DATA.map.center.lat
	] as [number, number]);
	let [zoom, setZoom] = useState([DATA.map.zoom] as [number]);
	let [bounds, setBounds] = useState(
		undefined as [[number, number], [number, number]]
	);

	useEffect(() => {
		let studyObjectList = map(filteredStudiesAndLocations, (value, key) => {
			return {
				studyID: key,
				study: DATA.studyDictionary[key],
				locations: uniq(flatten(value.map((v) => v.split(';')))).map(
					(x) => parseInt(x)
				)
			};
		});

		let locationsList = flatten(
			studyObjectList.map((studyObject, idx) => {
				return map(studyObject.locations, (location, locationIdx) => {
					let locationFromStudyObject =
						studyObject.study.locations[location - 1] ||
						studyObject.study.locations[0];

					let coordinates = [
						locationFromStudyObject.lon,
						locationFromStudyObject.lat
					];

					return {
						studyID: studyObject.studyID,
						study: studyObject.study,
						locationID: coordinates[0],
						coordinates
					};
				});
			})
		).map((location, idx) => {
			return {
				...location,
				key: location.studyID + '&' + location.locationID
			};
		});

		let uniqLocations = uniqWith(
			locationsList,
			(locationA, locationB) =>
				isEqual(locationA.studyID, locationB.studyID) &&
				isEqual(locationA.coordinates, locationB.coordinates)
		);

		setLocations(uniqLocations);
		resetBoundingBox(uniqLocations);
	}, [filteredStudiesAndLocations, isSmallScreen]);

	const resetBoundingBox = (locations) => {
		let justCoords = locations
			.map((l) => l.coordinates)
			.filter(
				(coords) =>
					coords[0] > areaOfInterest[0][0] &&
					coords[0] < areaOfInterest[1][0]
			);

		let locationBounds: [[number, number], [number, number]] =
			justCoords.length > 0
				? [
						[
							minBy(justCoords, (c) => c[0])[0],
							minBy(justCoords, (c) => c[1])[1]
						],
						[
							maxBy(justCoords, (c) => c[0])[0],
							maxBy(justCoords, (c) => c[1])[1]
						]
				  ]
				: areaOfInterest;

		setBounds(locationBounds);
	};

	// Handle clustered and spiderified markers
	// If multiple markers are close together, cluster them
	const ClusteredMarkers = ({ coordinates, pointCount }) => {
		return (
			<Marker coordinates={coordinates} style={{ cursor: 'pointer' }}>
				<CSSTransition
					in={true}
					timeout={500}
					classNames={'fade-transition'}
				>
					<SmallIcon {...{ height: 30, width: 26, pointCount }} />
				</CSSTransition>
			</Marker>
		);
	};

	// If two markers share the same coordinates, stack them
	// This is a fix for the broken spiderifier technique previously used
	const SpiderifiedMarkers = ({ leafArray }) => {
		return (
			<Marker coordinates={leafArray[0].props.locationData.coordinates}>
				{leafArray.map((leaf, index) =>
					spiderifierContent(index, leaf.props.locationData)
				)}
			</Marker>
		);
	};

	const clusterMarker = (coordinates, pointCount, getLeaves) => {
		let leaves = getLeaves(Infinity);

		// show cluster by default if lots of leaves
		if (zoom[0] < 12) {
			return (
				<ClusteredMarkers
					coordinates={coordinates}
					pointCount={pointCount}
				/>
			);
		}

		let groupedByCoords = groupBy(leaves, (lC) =>
			lC.props.coordinates.join('-')
		);
		let groupedCoordsToSpider = filter(
			groupedByCoords,
			(coordGroup) => coordGroup.length > 1
		);

		return (
			<>
				{map(groupedCoordsToSpider, (leafArray) => (
					<SpiderifiedMarkers leafArray={leafArray} />
				))}
			</>
		);
	};

	const spiderifierContent = (key, location) => {
		const study = DATA.studyDictionary[location.studyID];
		return (
			<div className="spiderifier-marker-content" key={key}>
				<div
					key={key}
					onMouseEnter={() => {
						setActiveStudyAndLocation([study, location]);
					}}
					onMouseLeave={() => {
						setActiveStudyAndLocation([undefined, undefined]);
					}}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						openStudyHref(study);
					}}
				>
					<CSSTransition
						in={true}
						timeout={500}
						classNames={'fade-transition'}
					>
						<div style={{ transform: 'translate(-6.5px, -7.5px)' }}>
							<SmallIcon />
						</div>
					</CSSTransition>
				</div>
			</div>
		);
	};

	// Piping filter state into stream
	const { output: mapzoneToggles$, input: filterObject$ } = useMemo(() => {
		const input = new ReplaySubject<IFilterObject[]>(1);
		const output = input.pipe(
			rxmap((flts) => find(flts, (f) => f.key === 'mapregion')),
			distinctUntilChanged(),

			rxmap((flt) =>
				!flt
					? // show all regions when no map filter
					  true
					: // transforms map region filter state into a dictionary<boolean> - correspond to selection of map region
					  fromPairs(flt.options.map((o) => [o.value, o.active]))
			),
			distinctUntilChanged(isEqual),
			startWith({})
		);
		return { input, output };
	}, []);

	filterObject$.next(filterObjectArray);

	// Controlling map zone layers from filter state
	useEffect(() => {
		if (!theMap) {
			return;
		}
		const subscription = makeMapZoneController(theMap, mapzoneToggles$);
		return () => {
			subscription.unsubscribe();
		};
	}, [theMap]);

	return (
		<div>
			<MapGL
				className="map"
				containerStyle={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				}}
				style={
					//DATA.map.mapboxStyleURL
					// 'mapbox://styles/agevidencedemo/ckrdsxvn215cb19ogmnzsbzuv/draft'

					'mapbox://styles/agevidencedemo/ckrdsxvn215cb19ogmnzsbzuv' // kenya
					// mapbox://styles/agevidencedemo/ckd0ppfv718j31imcxvah7b2o' // style without the soil map
					//'mapbox://styles/agevidencedemo/ckd6iynil00611imhi1in9gyj' // style that includes the soil map
				}
				// maxBounds={areaOfInterest}
				{...(bounds
					? {
							fitBounds: bounds,
							fitBoundsOptions: {
								padding: {
									top: 50,
									bottom: 75,
									left: 50,
									right: 50
								}
							}
					  }
					: { center, zoom })}
				onStyleLoad={(map) => {
					let flag = document.createElement('div');
					flag.id = 'map-loaded';
					delay(() => document.body.appendChild(flag), 1000);

					setTheMap(map);
				}}
				onZoom={(_, e) => {
					setZoom([(e.target as any).style.z]);
					setActiveStudyAndLocation([undefined, undefined]);
				}}
				renderChildrenInPortal={true}
			>
				{/*
				<Source
					id="raster-soil-map"
					tileJsonSource={{
						type: 'raster',
						tiles: [
							`https://api.mapbox.com/v4/agevidencedemo.99s8jez0/{z}/{x}/{y}.png?access_token=${ACCESS_TOKEN}`,
						],
					}}
				/>
				<Layer
					type="raster"
					id="soil-map"
					sourceId="raster-soil-map"
					paint={{
						'raster-opacity': 0.5,
						'raster-resampling': 'nearest',
					}}
				></Layer>
                 */}
				<ZoomControl position={'top-left'} className="zoom-controls" />

				<Cluster
					ClusterMarkerFactory={clusterMarker}
					zoomOnClick={true}
				>
					{locations.map((location, idx) => {
						const study = DATA.studyDictionary[location.studyID];
						return (
							<CustomMarker
								coordinates={location.coordinates}
								key={idx}
								idx={idx}
								onMouseEnter={() => {
									setActiveStudyAndLocation([
										study,
										location
									]);
								}}
								onMouseLeave={() => {
									setActiveStudyAndLocation([
										undefined,
										undefined
									]);
								}}
								style={{ cursor: 'pointer' }}
								onClick={(e) => {
									openStudyHref(study);
									e.persist();
									e.preventDefault();
									e.stopPropagation();
								}}
								locationData={location}
							>
								<CSSTransition
									in={true}
									timeout={500}
									classNames={'fade-transition'}
								>
									<div>
										<SmallIcon />
									</div>
								</CSSTransition>
							</CustomMarker>
						);
					})}
				</Cluster>
				{activeStudyAndLocation[0] &&
					ReactDOM.createPortal(
						<Popup
							coordinates={activeStudyAndLocation[1].coordinates}
							anchor={'right'}
							offset={[-35, -10]}
							style={{ zIndex: 10 }}
						>
							<StudyTooltip study={activeStudyAndLocation[0]} />
						</Popup>,
						PopupPortalRef.current
					)}
			</MapGL>
			<img
				src={require('../../../shared/icon/reset_map.svg')}
				className="zoom-to-bb"
				onClick={() => resetBoundingBox(locations)}
			/>
			<div ref={PopupPortalRef} />
		</div>
	);
};

export default Map;

export function SmallIcon({
	height = 15,
	width = 13,
	pointCount = 0,
	...props
}) {
	return (
		<svg
			width={height}
			height={width}
			viewBox="0 0 22 26"
			className="study-icon"
			{...props}
		>
			<g stroke="#3C3C3C" fill="none" fillRule="evenodd">
				<path fill="#FAFAFA" d="M15 1H1v24h20V7z" />
				<path d="M15 1v6h6" />
				{pointCount && (
					<text
						textAnchor={'middle'}
						x={10.75}
						y={17.5}
						fontSize={8.5}
						fontWeight={300}
						fontFamily={'Whitney A, sans-serif'}
						stroke={Colors.darkGray}
					>
						{pointCount}
					</text>
				)}
			</g>
		</svg>
	);
}
