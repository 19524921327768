/**
 * A promise that is synchronous if resolved synchronously; otherwise asynchronous.
 */

/* Ignore max file line count since much of the length comes from verbose typing. */
/* tslint:disable:max-file-line-count */

import {
	TCallback,
	AsapThenable,
	IAsapNode,
	Thenable
} from './asapPromiseInterfaces';

// typeguard
export function isPromise<T>(
	targ: Promise<T> | TCallback<T>
): targ is Promise<T> {
	const p = targ as Promise<T>;
	return p && Boolean(p.then) && Boolean(p.catch);
}

export function isThenable(o: any): o is Thenable<any> {
	return o && o.then && typeof o.then === 'function';
}

export enum ResolveOrReject {
	pending,
	resolved,
	rejected
}

export default class AsapPromise<T> implements AsapThenable<T> {
	private result: T;
	private _state: ResolveOrReject = ResolveOrReject.pending;
	private rejectionReason: any;
	protected rejectionCaught: boolean;
	private _heard = false;
	private _listeners: IAsapNode<T, any>[] = [];
	private _finallys: { (): void }[] = [];

	constructor(executor: TCallback<T>) {
		try {
			executor(this.resolve, this.reject);
		} catch (e) {
			this.syncRejectIfHandled(e);
		}
	}

	private syncRejectIfHandled(err: any) {
		// handle synchronously thrown error in executor
		this._state = ResolveOrReject.rejected;
		this.rejectionReason = err;

		setTimeout(() => {
			if (!this._heard) {
				throw this.rejectionReason;
			}
		}, 0);
	}

	private resolve = (val: T) => {
		if (this.isPending()) {
			this._state = ResolveOrReject.resolved;
			this.result = val;

			// resolve nodes
			this._listeners.forEach((node) =>
				AsapPromise.resolveNode(node, this.result)
			);

			// cleanup
			this._listeners = null;

			// execute finally handlers
			this.finish();
		}
	};

	private reject = (reason: any) => {
		if (this.isPending()) {
			this._state = ResolveOrReject.rejected;
			this.rejectionReason = reason;

			// reject nodes
			this.rejectionCaught = this._listeners.reduce((memo, node) => {
				return (
					AsapPromise.rejectNode(node, this.rejectionReason) || memo
				);
			}, false);

			// cleanup
			this._listeners = null;

			// execute finally handlers
			this.finish();

			if (!this.rejectionCaught) {
				throw reason;
			}
		}
	};

	private finish() {
		this._finallys.forEach((cb) => cb());
		this._finallys = null;
	}

	private addNode(node: IAsapNode<T, any>) {
		this._heard = true;

		if (this.isPending()) {
			this._listeners.push(node);
		} else {
			// when node added after complete
			if (this.isResolved()) {
				AsapPromise.resolveNode(node, this.result);
			} else {
				this.rejectionCaught =
					AsapPromise.rejectNode(node, this.rejectionReason) ||
					this.rejectionCaught;
			}
		}
	}

	getResultNow() {
		if (this.isPending()) {
			throw 'AsapPromise is pending; result is not yet available';
		}
		return this.result;
	}

	isPending() {
		return this._state === ResolveOrReject.pending;
	}

	isResolved() {
		return this._state === ResolveOrReject.resolved;
	}

	isRejected() {
		return this._state === ResolveOrReject.rejected;
	}

	getRejectionReason() {
		return this.rejectionReason;
	}

	then<U>(
		onFulfilled?: (value: T) => U | Thenable<U>,
		onRejected?: (error: any) => U | Thenable<U>
	): AsapPromise<U> {
		const p = AsapPromise.createExposedPromise<U>();

		this.addNode({
			onFulfilled,
			onRejected,
			...p
		});

		return p.promise;
	}

	catch<U>(onRejected?: (error: any) => U | AsapThenable<U>): AsapPromise<U> {
		return this.then(undefined, onRejected);
	}

	finally(onFinish: { (): void }): this {
		const p = AsapPromise.createExposedPromise<T>();

		this.addNode({
			onFinish,
			...p
		});
		return this;
	}

	private static createExposedPromise<U>() {
		// create a promise where resolve/reject are exposed
		let decendantResolve: { (value: U): void };
		let decendantReject: { (error: any): void };

		const promise = new AsapPromise<U>((res, rej) => {
			decendantResolve = res;
			decendantReject = rej;
		});

		return { decendantResolve, decendantReject, promise };
	}

	private static resolveNode<T>(node: IAsapNode<T, any>, result: T) {
		if (node.onFulfilled) {
			let fulResult = node.onFulfilled(result);

			if (isThenable(fulResult)) {
				fulResult.then(node.decendantResolve, node.decendantReject);
			} else {
				node.decendantResolve(fulResult);
			}
		} else {
			if (node.onFinish) {
				node.onFinish();
			}

			node.decendantResolve(result);
		}
	}

	private static rejectNode<T>(
		node: IAsapNode<T, any>,
		reason: any
	): boolean {
		if (node.onRejected) {
			try {
				let rejResult = node.onRejected(reason);

				if (isThenable(rejResult)) {
					rejResult.then(node.decendantResolve, node.decendantReject);
				} else {
					node.decendantResolve(rejResult);
				}
			} catch (e) {
				// pass error in onReject along
				node.decendantReject(e);
			}

			// caught own rejection
			return true;
		} else {
			if (node.onFinish) {
				node.onFinish();
			}

			node.decendantReject(reason);
			return node.promise.rejectionCaught;
		}
	}

	static resolve<T1>(value?: T1 | Thenable<T1>): AsapPromise<T1> {
		if (isThenable(value)) {
			let resolve: { (v: T1): any };
			let reject: { (err: any): any };

			// the returned thenabled
			const promise = new AsapPromise<T1>((res, rej) => {
				// store in wrapping scope
				resolve = res;
				reject = rej;
			});

			// fire this when ancestor thenable fires
			value.then(
				(val) => {
					resolve(val);
				},
				(err) => {
					reject(err);
				}
			);

			return promise;
		}

		return new AsapPromise((resolve, reject) => resolve(value));
	}

	static reject<T = any>(reason: any) {
		return new AsapPromise<T>((resolve, reject) => reject(reason));
	}

	static all<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>,
			T6 | AsapThenable<T6>,
			T7 | AsapThenable<T7>,
			T8 | AsapThenable<T8>,
			T9 | AsapThenable<T9>,
			T10 | AsapThenable<T10>
		]
	): AsapPromise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>;
	static all<T1, T2, T3, T4, T5, T6, T7, T8, T9>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>,
			T6 | AsapThenable<T6>,
			T7 | AsapThenable<T7>,
			T8 | AsapThenable<T8>,
			T9 | AsapThenable<T9>
		]
	): AsapPromise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>;
	static all<T1, T2, T3, T4, T5, T6, T7, T8>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>,
			T6 | AsapThenable<T6>,
			T7 | AsapThenable<T7>,
			T8 | AsapThenable<T8>
		]
	): AsapPromise<[T1, T2, T3, T4, T5, T6, T7, T8]>;
	static all<T1, T2, T3, T4, T5, T6, T7>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>,
			T6 | AsapThenable<T6>,
			T7 | AsapThenable<T7>
		]
	): AsapPromise<[T1, T2, T3, T4, T5, T6, T7]>;
	static all<T1, T2, T3, T4, T5, T6>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>,
			T6 | AsapThenable<T6>
		]
	): AsapPromise<[T1, T2, T3, T4, T5, T6]>;
	static all<T1, T2, T3, T4, T5>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>,
			T5 | AsapThenable<T5>
		]
	): AsapPromise<[T1, T2, T3, T4, T5]>;
	static all<T1, T2, T3, T4>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>,
			T4 | AsapThenable<T4>
		]
	): AsapPromise<[T1, T2, T3, T4]>;
	static all<T1, T2, T3>(
		values: [
			T1 | AsapThenable<T1>,
			T2 | AsapThenable<T2>,
			T3 | AsapThenable<T3>
		]
	): AsapPromise<[T1, T2, T3]>;
	static all<T1, T2>(
		values: [T1 | AsapThenable<T1>, T2 | AsapThenable<T2>]
	): AsapPromise<[T1, T2]>;
	static all<T1>(values: (T1 | AsapThenable<T1>)[]): AsapPromise<T1[]>;

	static all(values) {
		let promises = (values as any[]).map((v) => AsapPromise.resolve(v));

		for (let i = 0; i < promises.length; i++) {
			if (promises[i].isRejected()) {
				return AsapPromise.reject(promises[i].getRejectionReason());
			}
		}

		let resultValues = promises.map((p) => p.getResultNow());
		return AsapPromise.resolve(resultValues);
	}

	static race<T1>(promises: (T1 | AsapThenable<T1>)[]): AsapPromise<T1> {
		return AsapPromise.resolve(promises[0]);
	}
}
