/**
 * lil' bundle of tooltip components
 */

import React from 'react';
import { TooltipProps } from 'react-tooltip';
import Colors from '../utils/color';
import { IStudy } from '../../../../preprocess/src/ts/exec';

export const DefaultTooltipProps: TooltipProps = {
	effect: 'solid',
	place: 'left',
	multiline: true,
	backgroundColor: 'white',
	textColor: Colors.darkGray,
	border: true,
	borderColor: Colors.lightGray,
	arrowColor: 'transparent',
	className: 'tnc-tooltip'
};

interface IGenericTooltip {
	markdown: string;
}

export const GenericTooltip: React.FC<IGenericTooltip> = ({ markdown }) => {
	return <div />;
};

export const StudyTooltip: React.FC<{ study: IStudy; table?: boolean }> = ({
	study,
	table = false
}) => {
	if (!study) {
		return null;
	}

	return (
		<>
			{table && (
				<div
					className="text_tooltip-cta"
					style={{ paddingTop: '10px' }}
				>
					Observation from:
				</div>
			)}
			<div style={table ? { padding: '10px 0 0 10px' } : {}}>
				<div className="text_tooltip-emphasis">{study.pubyear}</div>
				<div
					className="text_tooltip-body text--clover"
					style={{ padding: '7px 0px', fontWeight: 600 }}
				>
					{study.title}
				</div>
				<div
					className="text_tooltip-body"
					style={{ padding: '7px 0px' }}
				>
					{study.authors}
				</div>
				{!table && (
					<div
						className="text_tooltip-cta"
						style={{ paddingTop: '10px' }}
					>
						Click the icon to view reference
					</div>
				)}
			</div>
			{table && (
				<div
					className="text_tooltip-cta"
					style={{ paddingTop: '10px' }}
				>
					Click the table row to view reference
				</div>
			)}
		</>
	);
};

export const HeatmapTooltip: React.FC<{
	activeHeatmapCell: { p: string; o: string };
}> = ({ activeHeatmapCell }) => {
	let { p, o } = activeHeatmapCell;

	if (!p) {
		return null;
	}

	return (
		<div className="heatmap-tooltip text_tooltip-cta">
			<span className="text--semibold">Click</span> to explore the effect
			of <span className="text--semibold">{p}</span> on{' '}
			<span className="text--semibold">{o}</span>.
		</div>
	);
};
