/**
 * Contextual panel group.
 */

import React, { useMemo, useState } from 'react';
import { INavState } from '../nav/state';
import { TData } from '../../../../preprocess/src/ts/exec';

import BreadCrumbTrail from './breadCrumbTrail';
import Filters, { IFilterObject } from './filters';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import StudiesGrid from './studiesGrid';
import ReferencesPanel from './referencesPanel';
import { isEqual, times, reduce, uniq, keyBy, map } from 'lodash';
import Map from './map';
import { AnimatedRadio } from './AnimatedRadio';
import getContextualLexicon, { Lexicon } from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

interface IContextProps {
	nav: INavState;
	store: any;
	DATA: TData;
	filteredObservationsByUID: number[];
	filterObjectArray: IFilterObject[];
	filteredStudiesAndLocations: {
		[key: string]: string[];
	};
	isSmallScreen: boolean;
	setIsDownloadModalOpen: (x: boolean) => void;
}

interface IContextContentProps extends IContextProps {
	filteredStudyUIDs: string[];
	lexicon: Lexicon;
}

const ContextPanel: React.FC<IContextProps> = (props) => {
	let { DATA, nav, filteredStudiesAndLocations, filteredObservationsByUID } =
		props;
	const { Practice } = nav;
	const lexicon = getContextualLexicon(nav);

	filteredStudiesAndLocations = useMemo(() => {
		// If there are no filtered studies and no Practice, show all studies and locations
		if (Practice === '' && isEqual(filteredStudiesAndLocations, {})) {
			return reduce(
				DATA.studyDictionary,
				(acc, value, key) => {
					return {
						...acc,
						[key]: times(value.locations.length, String)
					};
				},
				{}
			);
		}
		return filteredStudiesAndLocations;
	}, [Practice, filteredStudiesAndLocations, DATA.studyDictionary]);

	const filteredStudyUIDs = useMemo(
		() =>
			Practice === ''
				? map(DATA.studyDictionary, (d, key) => key)
				: uniq(
						filteredObservationsByUID.map(
							(observationUID) =>
								DATA.observationDictionary[observationUID]
									.studyUID
						)
				  ),
		[Practice, filteredObservationsByUID]
	);

	props = { ...props, filteredStudiesAndLocations };

	// tab state
	const [selected, setSelected] = useState(contextAdapters[0].key);
	const adapter = useMemo(
		() => contextAdaptersDict[selected] ?? contextAdaptersDict[0],
		[selected]
	);

	return (
		<div className="context-panel">
			<ContextHeader
				{...props}
				lexicon={lexicon}
				filteredStudyUIDs={filteredStudyUIDs}
			/>
			<div className="context-panel-content">
				<adapter.content
					{...props}
					lexicon={lexicon}
					filteredStudyUIDs={filteredStudyUIDs}
				/>
			</div>
			<ContextTabs
				options={contextAdapters}
				value={selected}
				setValue={setSelected}
			/>
		</div>
	);
};

export default ContextPanel;

const ContextHeader: React.FC<IContextContentProps> = ({
	nav,
	DATA,
	filteredObservationsByUID,
	filteredStudyUIDs,
	lexicon
}) => {
	const { Practice } = nav;

	const countOfStudies = filteredStudyUIDs.length;

	const countOfObservations =
		Practice === ''
			? DATA.counts.total.observations
			: filteredObservationsByUID.length;
	return (
		<div>
			<div className="text_heading underline">
				{lexicon.DASHBOARD['S&L_PANEL'].Title}
			</div>

			<div className="counts text_sub-heading">
				<div>
					{`${countOfStudies} ${
						countOfStudies === 1 ? 'Study' : 'Studies'
					}`}
					<img
						style={{
							margin: '0 7.5px 0 10px',
							width: '13px'
						}}
						src={require('../../icon/study_box_2.svg')}
					/>
					<img
						style={{
							width: '13px'
						}}
						src={require('../../icon/study_box_2.svg')}
					/>
				</div>
				<div>
					<img
						style={{ margin: '0 10px' }}
						src={require('../../icon/observation_icon.svg')}
					/>
					{`${countOfObservations.toLocaleString()} ${
						countOfObservations === 1
							? 'Observation'
							: 'Observations'
					}`}
				</div>
			</div>
		</div>
	);
};

const ContextTabs: React.FC<{
	options: { label: string; key: string }[];
	value: string;
	setValue;
}> = ({ options, value, setValue }) =>
	AnimatedRadio({
		className: 'context-tabs',
		options,
		containerProps: { className: 'context-tabs-options' },
		value,
		renderHighlight: ({ box }) => (
			<div
				className="context-tabs-highlight"
				style={{ left: box.left, width: box.width }}
			/>
		),
		renderOption: ({ option, index, selected }) => (
			<div
				key={index}
				style={{
					fontWeight: selected ? 'bold' : undefined
				}}
				onClick={() => {
					setValue(option.key);
					sendAnalyticsUIInteraction(
						'SELECT',
						analyticsCategories.CONTEXT_PANEL,
						'CONTEXT_BUTTON',
						{ key: 'context_type', value: option.key }
					);
				}}
			>
				{option.label}
			</div>
		)
	});

interface IContextAdapter {
	label: string;
	key: string;
	content: React.FC<IContextContentProps>;
}

const citationsAdapter: IContextAdapter = {
	label: 'View Citations',
	key: 'citation',
	content: (props) => <ReferencesPanel {...props} />
};

const locationsAdapter: IContextAdapter = {
	label: 'View by Location',
	key: 'location',
	content: (props) => <Map {...props} />
};

const byDateAdapter: IContextAdapter = {
	label: 'View by Date',
	key: 'date',
	content: (props) =>
		useMemo(
			() => <StudiesGrid {...props} />,
			[props.filteredStudiesAndLocations, props.DATA]
		)
};

const contextAdapters = [locationsAdapter, byDateAdapter, citationsAdapter];
const contextAdaptersDict = keyBy(contextAdapters, (a) => a.key);
