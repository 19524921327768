/**
 * Internally representation of the data used by RouteConfig and shared with encode and decode processes.
 */

import { RouteConfigNode, PropRouteConfigNode } from './RouteConfigNode';
import RouteConfig, { RouteConfigOpts } from './RouteConfig';
import { pick } from 'lodash-es';

export default class RouteConfigCore<StoreStateT, DataT> {
	layers: RouteConfigNode<any, any, DataT>[] = [];
	properties: PropRouteConfigNode<any, any, DataT>[] = [];
	brancher: { (lastLayerVal: any): RouteConfigCore<any, any> };
	project: { (storeState: StoreStateT): DataT };
	unproject: { (storeState: StoreStateT, data: DataT): StoreStateT };
	namespace: string;
	explicitDefaults: { [key: string]: boolean };
	_applicableDefaults: any;

	constructor(
		public defaults: DataT,
		opts: RouteConfigOpts<StoreStateT, DataT> = {}
	) {
		this.namespace = opts.urlNamespace || '';
		this.explicitDefaults = opts.explicitDefaults || {};

		if (!!opts.reducerKey) {
			// for a composite reducer
			// storing in a field on the store state
			this.project = (s: StoreStateT): DataT => {
				return ((s as any)[opts.reducerKey] || {}) as DataT;
			};

			this.unproject = (s: StoreStateT, d: DataT) => {
				let o = {} as any;
				o[opts.reducerKey] = { ...s[opts.reducerKey], ...(d as any) };
				return { ...(s as any), ...(o as any) } as StoreStateT;
			};
		} else {
			// defaults, appropriate for store with single, non-composite reducer
			this.project = (s: StoreStateT): DataT => s as any as DataT;
			this.unproject = (s: StoreStateT, d: DataT): StoreStateT => ({
				...(s as any),
				...(d as any)
			});
		}
	}

	getApplicableDefaults() {
		return (this._applicableDefaults =
			this._applicableDefaults ||
			pick(this.defaults, this._getModelKeys()));
	}

	private _getModelKeys() {
		return this.layers.concat(this.properties).map((l) => l.fieldKey);
	}
}
