/**
 * Key Insights Component
 */
import React, { useMemo, useState } from 'react';

import ReactMarkdownText from './text/ReactMarkdownText';
import { map } from 'lodash-es';
import OutsideClickHandler from 'react-outside-click-handler';
import ValueTransition from './valueTransition';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import Colors from '../utils/color';
import getContextualLexicon from '../data/contextualLexicon';
import { TData } from '../../../../preprocess/src/ts/exec';
import AppConfig from '../app/config/AppConfig';
import { IInsightConfig } from '../../../../preprocess/src/ts/prepObservations';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';
gsap.registerPlugin(ScrollToPlugin);

interface IInsight {
	label: string;
	title: string;
	subTitle: string;
	topBody: string;
	bottomBody: string;
	imgSrc: string;
}

const KeyInsights: React.FC<{
	regionKey: string;
	DATA: TData;
	openInsightIndex: number;
	setOpenInsightIndex: { (n: number): void };
}> = ({ regionKey, DATA, openInsightIndex, setOpenInsightIndex }) => {
	const keyInsights: IInsight[] = useMemo(() => {
		return map(DATA.insights, (x) => x).map((insight, idx) => {
			return {
				label: insight.Label,
				title: insight.Title,
				subTitle: insight['Sub_Title'],
				topBody: insight['Top_Body'],
				bottomBody: insight['Bottom_Body'],
				imgSrc: `${AppConfig.assetPath}images/${regionKey}/insight-${
					idx + 1
				}.jpg?${AppConfig.cacheBreak}`
			};
		});
	}, [regionKey, DATA]);

	return (
		<>
			{/*
			<div className="key-insights-heading text_heading">
				Selected Insights from the Data
			</div>
*/}
			<div className="key-insights">
				{keyInsights.map((insight, idx) => (
					<Insight
						{...{
							key: idx,
							insight,
							idx,
							openInsightIndex,
							setOpenInsightIndex
						}}
					/>
				))}
			</div>
		</>
	);
};

export default KeyInsights;

interface IInsightProps {
	insight: IInsight;
	idx: number;
	openInsightIndex: number | undefined;
	setOpenInsightIndex: (idx: number) => void;
}

const Insight: React.FC<IInsightProps> = ({
	insight,
	idx,
	openInsightIndex,
	setOpenInsightIndex
}) => {
	let isActive = openInsightIndex === idx;

	return (
		<ValueTransition
			stableKey={'active'}
			stableValue={isActive}
			active={isActive}
			duration={200}
			render={({ state, stableValue }) => (
				<div
					className={`insight-wrapper ${
						isActive || stableValue ? 'active z' : ''
					}`}
					onClick={() => {
						if (!stableValue) {
							sendAnalyticsUIInteraction(
								'SELECT',
								analyticsCategories.INSIGHT_SUMMARIES,
								'insight-tile',
								{ key: 'insight_name', value: insight.title }
							);
							setOpenInsightIndex(idx);
						}
					}}
				>
					<OutsideClickHandler
						onOutsideClick={() =>
							isActive
								? setOpenInsightIndex(undefined)
								: undefined
						}
					>
						<div
							className={`insight${
								openInsightIndex === idx ? ' insight-open' : ''
							}${idx > 2 ? ' bottom' : ''}${
								idx === 2 || idx === 5 ? ' end' : ''
							}`}
						>
							{stableValue && (
								<img
									src={require('../../icon/checkbox_x.svg')}
									className="close-x"
									onClick={() => {
										setOpenInsightIndex(undefined);
									}}
								/>
							)}
							<div
								className="image"
								style={{
									backgroundImage: `url(${insight.imgSrc}?${AppConfig.cacheBreak})`
								}}
							/>
							{!stableValue && (
								<div className={`text fadeIn-${state}`}>
									<div className="text_sub-heading text--clover">
										{insight.label}
									</div>
									<p className="text_heading">
										{insight.title}
									</p>
									<p className="text_body">
										{insight.subTitle}
									</p>
									<p className="keep-reading text_body text--italic tout">
										Keep Reading
									</p>
								</div>
							)}
							{stableValue && (
								<div className={`text fadeIn-${state} open`}>
									<p className="text_heading">
										{insight.title}
									</p>
									<ReactMarkdownText
										source={insight.topBody}
										className="text_body"
									/>

									<p
										className="text_body text--italic top"
										aria-setsize={30}
									>
										Use the links below to explore
										supporting data in the application
									</p>
									<ReactMarkdownText
										source={insight.bottomBody}
										className="text_body bottom"
										renderers={{
											a: (props) => {
												let { attrs, children } = props;

												return (
													<a
														onClick={() =>
															gsap.to(window, {
																duration: 0.8,
																scrollTo:
																	'#app-container',
																ease: 'power2',
																delay: 0.4
															})
														}
														href={
															'#' +
															attrs.href.split(
																'#'
															)[1]
														}
													>
														{children.map((x) =>
															typeof x ===
															'string' ? (
																<ReactMarkdownText
																	componentProps={{
																		style: {
																			display:
																				'inline'
																		}
																	}}
																	source={x}
																/>
															) : (
																<ReactMarkdownText
																	componentProps={{
																		style: {
																			display:
																				'inline'
																		}
																	}}
																	source={
																		{
																			getTree:
																				() => [
																					x
																				]
																		} as any
																	}
																/>
															)
														)}
													</a>
												);
											}
										}}
									/>
								</div>
							)}
						</div>
					</OutsideClickHandler>
				</div>
			)}
		/>
	);
};
