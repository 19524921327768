/**
 * RouteDecoder decodes url routes to state. Used internally by RouteConfig.
 */

import { IEncodedProps, IDecodeOpts } from './RouteConfig';
import AsapPromise from '../AsapPromise';
import { defaults as _defaults } from 'lodash-es';
import { RouteConfigNode, identityMount } from './RouteConfigNode';
import RouteConfigCore from './RouteConfigCore';
import { asapReduce, omit } from './RouteUtils';

export class RouteDecoder {
	private static decodeNodeValue<ValT, StoreStateT, DataT>(
		value: string,
		node: RouteConfigNode<ValT, StoreStateT, DataT>,
		storeState: StoreStateT,
		configState: DataT,
		opts: IDecodeOpts
	): AsapPromise<{ storeState: StoreStateT; value: ValT }> {
		let result: ValT;

		if (value !== undefined) {
			result = node.decode(value, storeState, configState);

			// if we are preparing, mount the value
			if (
				opts.prepare &&
				node.mountDecoded &&
				node.mountDecoded !== identityMount
			) {
				return AsapPromise.resolve(
					node.mountDecoded(result, storeState)
				).then(
					(storeState) => {
						return { storeState, value: result };
					},
					(err) => {
						console.log(err);
						return null;
					}
				);
			}
		}

		return AsapPromise.resolve({ storeState, value: result });
	}

	private static decodeProperties<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		propVals: IEncodedProps,
		storeState: StoreStateT,
		opts: IDecodeOpts
	): AsapPromise<DataT> {
		const properties = core.properties;

		return asapReduce(
			properties,
			(memo, node) => {
				const { data, storeState } = memo;
				const vals = propVals[node.nsUrlKey];
				return RouteDecoder.decodeNodeValue(
					vals && vals[0],
					node,
					storeState,
					data,
					opts
				).then(({ storeState, value }) => {
					data[node.fieldKey] = value;
					return { storeState, data };
				});
			},
			{ storeState, data: {} as DataT }
		).then((s) => s.data);
	}

	private static decodeLayers<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		data: DataT,
		layerVals: string[],
		storeState: StoreStateT,
		opts: IDecodeOpts
	): AsapPromise<{
		data: DataT;
		lastLayerResult: any;
	}> {
		// Decode hierarchical values back to model properties

		layerVals = layerVals || [];
		let layers = core.layers || [];

		if (layers.length > layerVals.length) {
			layers = layers.slice(0, layerVals.length);
		}

		return asapReduce(
			layers,
			(memo, node, index) => {
				if (memo.shouldBreak) {
					// just skip this step
					return AsapPromise.resolve(memo);
				} else {
					let { shouldBreak, storeState, data, lastLayerResult } =
						memo;
					const value = layerVals[index];

					return RouteDecoder.decodeNodeValue(
						value,
						node,
						storeState,
						data,
						opts
					).then(({ storeState, value }) => {
						// handle/record the value
						if (value === undefined) {
							shouldBreak = true;
						} else {
							lastLayerResult = data[node.fieldKey] = value;
						}

						// the updated state of the processing
						return {
							storeState,
							data,
							lastLayerResult,
							shouldBreak
						};
					});
				}
			},
			{ storeState, data, lastLayerResult: undefined, shouldBreak: false }
		).then((memo) => omit(memo, 'shouldBreak', 'storeState'));
	}

	private static decodeBranch<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		layerVals: string[],
		propVals: IEncodedProps,
		storeState: StoreStateT,
		targetState: StoreStateT,
		lastLayerResult: any,
		opts: IDecodeOpts
	): AsapPromise<StoreStateT> {
		// enter into branch if we have any
		if (core.brancher) {
			const layers = core.layers;

			const remLayers = (layerVals || []).slice(layers.length);

			// if we don't have a final layer value
			// from decoding, get the default final layer value
			if (lastLayerResult === undefined && layers.length) {
				// get the default branch
				const lastLayerNode = layers[layers.length - 1];
				lastLayerResult = core.defaults[lastLayerNode.fieldKey];
			}

			const branch = core.brancher(lastLayerResult);

			if (branch) {
				return (
					RouteDecoder
						// apply branch config to store state
						.recursiveDecode(
							branch,
							remLayers,
							propVals,
							storeState,
							targetState,
							opts
						)
				);
			}
		}

		return AsapPromise.resolve(targetState);
	}

	private static applyDecode<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		input: DataT,
		targetState: StoreStateT
	): StoreStateT {
		return core.unproject(
			targetState,
			_defaults(
				input,
				core.project(targetState),
				core.getApplicableDefaults()
			)
		);
	}

	static recursiveDecode<StoreStateT, DataT>(
		core: RouteConfigCore<StoreStateT, DataT>,
		layerVals: string[],
		propVals: IEncodedProps,
		storeState: StoreStateT,
		targetState: StoreStateT,
		opts: IDecodeOpts
	): AsapPromise<StoreStateT> {
		return (
			RouteDecoder
				// decode the properties
				.decodeProperties(core, propVals, storeState, opts)
				// decode the layers
				.then((data) => {
					return RouteDecoder.decodeLayers(
						core,
						data,
						layerVals,
						storeState,
						opts
					);
				})
				// decode the branches
				.then(({ data, lastLayerResult }) => {
					return RouteDecoder.decodeBranch(
						core,
						layerVals,
						propVals,
						storeState,
						targetState,
						lastLayerResult,
						opts
					).then((targetState) => {
						return {
							data,
							targetState
						};
					});
				})
				// apply the decoding to the next store state
				.then(({ data, targetState }) => {
					return RouteDecoder.applyDecode(core, data, targetState);
				})
		);
	}
}
