/** Filters Component **/

import React, { useMemo, useState } from 'react';
import { INavState } from '../nav/state';
import { TData } from '../../../../preprocess/src/ts/exec';
import { CSSTransition } from 'react-transition-group';

import Downloads from './downloads';
import OutsideClickHandler from 'react-outside-click-handler';
import Colors from '../utils/color';
import { uniq, includes, some, flatten } from 'lodash-es';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

export interface IFilterObject {
	label: string;
	key: string;
	type: string;
	valueType: 'primitive' | 'array';
	highlight: boolean;
	default?: string;
	options: {
		value: string;
		label: string;
		set?: any;
		active: boolean;
		defaultActive: boolean;
	}[];
}

interface IFiltersProps {
	nav: INavState;
	store: any;
	DATA: TData;
	filteredObservationsByUID: number[];
	filterObjectArray: IFilterObject[];
	setIsDownloadModalOpen: (x: boolean) => void;
	setFiltersPanelOpen: (x: boolean) => void;
}
const ALLOWED_FILTERS = 3;

const isFilterModified = ({ options }: IFilterObject) =>
	some(options, (o) => o.defaultActive !== o.active);

const Filters: React.FC<IFiltersProps> = ({
	DATA,
	filterObjectArray,
	filteredObservationsByUID,
	nav,
	setIsDownloadModalOpen,
	setFiltersPanelOpen
}) => {
	const modifiedFilters = useMemo(
		() => filterObjectArray.filter(isFilterModified),
		[filterObjectArray]
	);

	return (
		<div className="filters filters-horizontal">
			{filterObjectArray
				.filter((f) => f.highlight)
				.map((filter, idx) => (
					<Filter
						key={idx}
						{...{ DATA, filter, idx, filteredObservationsByUID }}
					/>
				))}

			<div
				className="action"
				style={{
					opacity: filterObjectArray.length > 0 ? 1 : 0,
					transition: 'opacity 0.3s ease-in-out',
					pointerEvents:
						filterObjectArray.length > 0 ? 'auto' : 'none'
				}}
			>
				<div className="action-summary">
					{(() => {
						const count = modifiedFilters.length;
						if (count > 0) {
							return `${count} Filter${
								count !== 1 ? 's' : ''
							} Applied`;
						}
						return '\u00A0'; // non-breaking space
					})()}
				</div>
				<div
					className="action-button"
					onClick={() => {
						sendAnalyticsUIInteraction(
							'OPEN',
							analyticsCategories.FILTERS,
							'filter-panel'
						);
						setFiltersPanelOpen(true);
					}}
				>
					Filter Data
				</div>
			</div>

			<Downloads
				{...{
					DATA,
					nav,
					filteredObservationsByUID,
					setIsDownloadModalOpen
				}}
			/>
		</div>
	);
};
export default Filters;

export const Filter: React.FC<{
	DATA: TData;
	filter: IFilterObject;
	idx: number;
	filteredObservationsByUID: number[];
}> = ({ DATA, filter, idx, filteredObservationsByUID }) => {
	let [open, setOpen] = useState(false);

	let availableOptions = useMemo(
		() =>
			filter.key === 'Chart range'
				? filter.options.map((x) => x.value)
				: filter.valueType === 'array'
				? uniq(
						flatten(
							filteredObservationsByUID.map(
								(x) =>
									DATA.observationDictionary[x].variables[
										filter.key
									]
							)
						)
				  )
				: uniq(
						filteredObservationsByUID.map(
							(x) =>
								DATA.observationDictionary[x].variables[
									filter.key
								]
						)
				  ),
		[filter, filteredObservationsByUID]
	);

	const newOptions = [];
	filter.options.forEach((o, i) => {
		let minYear = Number(o.value.split('-')[0]);
		if (Number.isNaN(minYear)) {
			minYear = 21;
		}
		newOptions.push({ ...o, minYear });
	});

	newOptions.sort((a, b) => a.minYear - b.minYear);
	console.log(newOptions);

	return (
		<div
			className={`filter ${
				filter.key === 'Chart range' ? 'chart-range' : ''
			}`}
		>
			<OutsideClickHandler onOutsideClick={() => setOpen(false)}>
				<div
					onClick={() => {
						if (!open) {
							sendAnalyticsUIInteraction(
								'OPEN',
								analyticsCategories.FILTERS,
								'filter-options',
								{ key: 'filter_name', value: filter.key }
							);
						}
						setOpen(!open);
					}}
				>
					<div className="filter-label text_control-label">
						{filter.label}
					</div>
					<div className={`filter-summary ${open ? 'hover' : ''}`}>
						<span
							className="text_breadcrumb-label"
							style={{ lineHeight: 'unset' }}
						>
							{newOptions
								.filter((o) => o.active)
								.map((o) => o.label)
								.join(', ') || (
								<span
									className="text--italic"
									style={{
										margin: 0,
										padding: 0,
										textTransform: 'none'
									}}
								>
									Make a selection
								</span>
							)}
						</span>
						<DownArrow />
					</div>
				</div>
				<CSSTransition
					in={open}
					timeout={300}
					classNames={'no-delay-fade-transition'}
					unmountOnExit
				>
					<div
						style={{
							position: 'absolute',
							zIndex: 1,
							bottom: 0,
							left: 0,
							right: 0
						}}
					>
						<div className="filter-menu">
							{newOptions.map((o, i) => {
								let isOptionAvailable = includes(
									availableOptions,
									o.value
								);

								return (
									<div
										key={i}
										className={`filter-option ${
											filter.type === 'radio' && o.active
												? 'active'
												: ''
										} ${
											isOptionAvailable ||
											o.active === false ||
											filter.type === 'radio'
												? ''
												: 'unavailable'
										} `}
										onClick={() => {
											sendAnalyticsUIInteraction(
												'SELECT',
												analyticsCategories.FILTERS,
												'filter-options',
												{
													key: 'filter_name',
													value: filter.key
												}
											);
											if (filter.type === 'radio') {
												setOpen(false);
											}

											o.set();
										}}
									>
										{filter.type === 'checkbox' ? (
											o.active ? (
												<img
													src={require('../../icon/checkbox_filled.svg')}
												/>
											) : (
												<img
													src={require('../../icon/checkbox_empty.svg')}
												/>
											)
										) : undefined}
										<span
											className={`text_breadcrumb-label`}
										>
											{o.label}
										</span>
									</div>
								);
							})}
						</div>
					</div>
				</CSSTransition>
			</OutsideClickHandler>
		</div>
	);
};

function DownArrow(props) {
	return (
		<svg
			width={7}
			height={14}
			viewBox="0 0 7 14"
			{...props}
			style={{ transform: 'rotate(90deg)' }}
		>
			<path
				d="M.5.5l6 6.39-6 6.61"
				stroke={Colors.clover}
				fill="none"
				fillRule="evenodd"
			/>
		</svg>
	);
}
