/**
 * Component for demonstrating selection components for the nav.
 */

import React, { useState, useEffect } from 'react';
import { INavState, SET } from '../nav/state';
import { TData, IStudy } from '../../../../preprocess/src/ts/exec';
import { loadImageAsDataURL } from '../utils/imageAsDataURL';
import ReactTooltip from 'react-tooltip';
import { StudyTooltip, DefaultTooltipProps } from './tooltips';
import Portal from './Portal';

import { map, sortBy, findIndex, compact, flatten } from 'lodash-es';
import { SmallIcon } from './map';
import { openStudyHref } from './referencesPanel';
import { group } from 'd3-array';

interface IStudiesGridProps {
	nav: INavState;
	store: any;
	DATA: TData;
	filteredStudiesAndLocations: { [key: string]: string[] };
}

const iconURL = require('../../icon/study_box.svg');

const StudiesGrid: React.FC<IStudiesGridProps> = ({
	store,
	DATA,
	filteredStudiesAndLocations
}) => {
	const [iconStr, setIconStr] = useState('');

	const [tooltipStudy, setTooltipStudy] = useState(undefined as IStudy);

	// cache data url of icon for performance
	useEffect(() => {
		let mounted = true;
		loadImageAsDataURL(iconURL, 2).then((dataURL) => {
			mounted && setIconStr(dataURL);
		});
		return () => {
			mounted = false;
		};
	}, []);

	let studiesWithUIDs = map(DATA.studyDictionary, (study, studyUID) => {
		return { ...study, UID: studyUID };
	});

	let dateSortedStudies = sortBy(studiesWithUIDs, (study) => {
		return study.pubyear;
	});

	const decadeStudyGroups = dateSortedStudies.reduce((acc, study) => {
		const lastGroup = acc[acc.length - 1];
		const studyYear = parseInt(study.pubyear, 10);
		if (!lastGroup || lastGroup.maxYear <= studyYear) {
			const minYear = Math.floor(studyYear / 10) * 10;
			let group = {
				minYear,
				maxYear: minYear + 10,
				startYear: studyYear,
				endYear: studyYear,
				studies: [study]
			};
			acc.push(group);
		} else {
			lastGroup.endYear = studyYear;
			lastGroup.studies.push(study);
		}
		return acc;
	}, [] as { minYear: number; maxYear: number; startYear: number; endYear: number; studies: typeof dateSortedStudies }[]);

	let createStudyBoxArray = (studyArray) =>
		studyArray.map((study, idx) => {
			let isStudyActive = filteredStudiesAndLocations.hasOwnProperty(
				study.UID
			);

			return (
				<span
					data-tip
					data-for="study-grid-tooltip"
					key={idx}
					className={'study-grid-icon'}
					style={{
						cursor: isStudyActive ? 'pointer' : 'default',
						opacity: isStudyActive ? 1 : 0.2
					}}
					onMouseEnter={
						isStudyActive
							? () => {
									setTooltipStudy(study);
							  }
							: () => undefined
					}
					onMouseLeave={
						isStudyActive
							? () => {
									setTooltipStudy(undefined);
							  }
							: () => undefined
					}
					onClick={
						isStudyActive
							? () => {
									openStudyHref(study);
							  }
							: () => undefined
					}
				>
					{SmallIcon({})}
				</span>
			);
		});

	function formatYear(year: number) {
		return (
			<div
				className="text_tooltip-data-label"
				style={{
					width: '25px',
					margin: '0 3.1px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{year}
			</div>
		);
	}

	let finalStudiesArray = compact(
		flatten(
			decadeStudyGroups.map((group, idx, groups) => [
				formatYear(idx === 0 ? group.startYear : group.minYear),
				createStudyBoxArray(group.studies),
				idx === groups.length - 1 ? formatYear(group.endYear + 1) : null
			])
		)
	);

	return (
		<div>
			<div className="studies-grid">
				{finalStudiesArray.map((study, idx) => (
					<React.Fragment key={idx}>{study}</React.Fragment>
				))}
			</div>
			<Portal container={document.body}>
				{tooltipStudy && (
					<ReactTooltip
						id="study-grid-tooltip"
						{...DefaultTooltipProps}
					>
						<StudyTooltip study={tooltipStudy} />
					</ReactTooltip>
				)}
			</Portal>
		</div>
	);
};

export default StudiesGrid;
