/**
 * Functions that facilitate fully typed redux stores.
 */

import {
	createStore,
	Store,
	Action,
	Dispatch,
	Unsubscribe,
	Reducer
} from 'redux';

// catch-all action for anything not handled by a specific reducer
export type OtherAction = { type: '' };
export const OtherAction: OtherAction = { type: '' };

export interface IReducer<S, A> {
	(state: S, action: A): S;
}

export function composeReducers<S, A1, A2>(
	reducer1: IReducer<S, A1>,
	reducer2: IReducer<S, A2>
): IReducer<S, A1 | A2> {
	return function (state: S, action: A1 | A2) {
		return reducer2(
			reducer1(state, action as any as A1),
			action as any as A2
		);
	};
}

interface ITypedStore<S, A extends Action> {
	dispatch: { (action: A): A };
	getState(): S;
	subscribe(listener: () => void): Unsubscribe;
	replaceReducer(nextReducer: Reducer<S>): void;
}

export function createTypedStore<S, A extends Action>(
	reducer: IReducer<S, A>,
	preloadedState?: S,
	enhancer?: any
) {
	return createStore(reducer as any, preloadedState, enhancer) as ITypedStore<
		S,
		A
	>;
}
