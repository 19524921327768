/**
 * Syncs URL properties to redux store and back.
 *
 * @author Brett Johnson, brett@periscopic.com
 *
 * Example
 *
 	import {createTypedStore} from 'periscopic/lib/redux/typed';
 	import RouterSetup from 'periscopic/lib/redux/RouterSetup';
 	import RouteConfig from 'periscopic/lib/redux/RouteConfig';


 	// given an app level reducer which has state of child reducer
 	// in field 'nav' and values 'firstName' and 'lastName' that are strings
 	// and a value 'age' that is a number.

	const DEFAULTS = {firstName: 'John', lastName: 'Doe', age: 35};

	const rc = new RouteConfig<{nav: NavState}, NavState>(
			DEFAULTS,
			{reducerKey: 'nav'}
		)
		.addLayer('firstName')
		.addLayer('lastName')
		.addIntProperty('age');

	const router = new RouterSetup();
	router.addConfig(rc);

	// add the router to the store by wrapping the primary reducer
 	const store = createTypedStore(
		router.wrapReducer(appReducer)
	);

	// initialize the router
	router.setStore(store)
		.init();
 */

// this usage of 'history' is based on https://github.com/mjackson/history
// v.4.4.1
import { Location as HistoryLocation } from 'history';
//import { stripLeadingSlash, addLeadingSlash } from 'history/PathUtils';
import { Store, Action } from 'redux';

import { OtherAction, composeReducers, IReducer } from './typed';
export type SET = 'ROUTE/SET';
export const SET: SET = 'ROUTE/SET';
export type SetAction = {
	type: SET;
	val: string;
};

import StatelessRouterSetup from './StatelessRouterSetup';
import { merge } from 'lodash-es';
import RouterSetupHelpers from './RouterSetupHelpers';
import { addLeadingSlash, stripLeadingSlash } from './RouteUtils';

// union of applicable action types
export type RouteStateAction = OtherAction | SetAction;

export default class RouterSetup<
	StoreStateT
> extends StatelessRouterSetup<StoreStateT> {
	//private routeConfigs: RouteConfig<StoreStateT, any>[] = [];
	private isSyncingToRoute: boolean = false;
	private isSyncingToState: boolean = false;
	private initialized: boolean = false;
	private inboundRoute: string = '';
	private outboundRoute: string = '/';
	private store: Store<StoreStateT>;
	private hasReduxInitialized = false;

	public wrapReducer<R extends IReducer<StoreStateT, any>>(reducer: R): R {
		// piggy backs the router reducer on top of an existing reducer
		// so that RouterSetup can adjust state when the route changes
		return composeReducers(reducer, this.reducer) as R;
	}

	public setStore(store: Store<StoreStateT>) {
		this.store = store;
		return this;
	}

	public init() {
		if (this.initialized) {
			throw 'RouterSetup already initialized';
		}

		if (!this.store) {
			throw 'RouterSetup must have a `store` when `init` is called';
		}

		this.initialized = true;
		// Setup
		const history = RouterSetupHelpers.getHistory();
		history.listen(this.handleHistory);
		this.handleHistoryLocation(history.location);

		return this;
	}

	/**
	 * Get a route string for a specified transformation of current state.
	 *
	 * @param  {Object}} map
	 * @return {string} [description]
	 */
	public encodeTransformHref(storeState: StoreStateT): string {
		storeState = merge({}, this.store.getState(), storeState);
		return this.encodeAsHref(storeState);
	}

	private handleHistory = ({ location }: { location: HistoryLocation }) => {
		this.handleHistoryLocation(location);
	};

	private handleHistoryLocation = (location: HistoryLocation) => {
		this.handleRoute(location.pathname + location.search);
	};

	private handleRoute(route: string) {
		if (route === this.inboundRoute) {
			return;
		}

		this.inboundRoute = route;

		if (this.isSyncingToState) {
			return;
		}

		const cleanRoute = stripLeadingSlash(route);

		// load data necessary for route decoding
		// and decode it
		this.loadAndDecodeHrefToState(this.store.getState(), cleanRoute).then(
			() => {
				// if route is still valid after loading of data,
				// apply it
				if (route === this.inboundRoute) {
					// flag that we're syncing, so related calls to
					// RouterSetup's reducer are handled appropriately
					this.isSyncingToRoute = true;

					// dispatch the route change to the redux store
					this.store.dispatch({ type: SET, val: cleanRoute });

					this.isSyncingToRoute = false;
				}
			},
			(err) => console.log(err)
		);
	}

	private reducer = (state: any, action: RouteStateAction = OtherAction) => {
		// there are several types of actions which we need to distinguish
		// 1) Redux generated actions (INIT)
		// 2) Router actions (parses URL down to state)
		// 3) Other actions (parses state to URL)
		// 4) Feedback actions (when a URL changes, state should be synced, and when state changes URL should synced)

		if (!this.hasReduxInitialized) {
			if ((action.type as string).indexOf('@@redux/INIT') >= 0) {
				this.hasReduxInitialized = true;
				return state;
			} else {
				throw 'expected initial redux init call';
			}
		}

		if (!this.initialized) {
			// handles case reducer has been added to redux, but router not yet initialized (for example when data needs to be preloaded before route can be handled).
			return state;
		}

		if (action.type === SET) {
			if (!this.isSyncingToState) {
				// adjust state based on decoding href
				state = this.decodeHrefToState(state, action.val);

				// sync state back to href (prevents invalid values,
				// such as misspelled enums from hanging out in url)
				this.syncToState(state);

				// now submit
				return state;
			}
		} else if (!this.isSyncingToRoute) {
			this.syncToState(state);
		}

		return state;
	};

	private syncToState(state: any) {
		// Encode state to url
		const route = addLeadingSlash(this.getRouteString(state));

		if (route !== this.outboundRoute) {
			this.outboundRoute = route;

			if (route !== this.inboundRoute) {
				// flag that we're handling state change
				// so route handling is suppressed
				this.isSyncingToState = true;

				// add route to history
				RouterSetupHelpers.getHistory().push(route);

				this.isSyncingToState = false;
			}
		}
	}

	/**
	 * A generic click handler for an anchor element that pulls data from its default attributes
	 */
	static onAnchorClick(e: React.MouseEvent<HTMLAnchorElement> | MouseEvent) {
		return RouterSetupHelpers.onAnchorClick(e);
	}

	/**
	 * A generic click handler for a non-anchor element that pulls data from it a 'data-href' attribute.
	 * Generally should be used where element has a related anchor element that has an 'href' value, but
	 * should a larger hit-area is preferred.
	 */
	static onHitAreaClick(e: React.MouseEvent<HTMLElement> | MouseEvent) {
		return RouterSetupHelpers.onHitAreaClick(e);
	}

	static navigate(route: string) {
		return RouterSetupHelpers.navigate(route);
	}

	static getHistory() {
		return RouterSetupHelpers.getHistory();
	}
}
