/**
 * Bread Crumbs Trail Component
 */

import React, { useState, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
	getLevelAdapter,
	getLevelAdapterIndex,
	levelAdapters,
	ILevelAdapter,
	ICrumbProps
} from './contentAdapters';
import { useDebouncedCallback } from 'use-debounce';

const BreadCrumb: React.FC<ICrumbProps & { adapter: ILevelAdapter }> = ({
	nav,
	store,
	DATA,
	adapter
}) => {
	const [hover, setHover] = useState(false);
	const [setHoverDebounced, cancelHoverDebounced] = useDebouncedCallback(
		setHover,
		300
	);

	return (
		<div
			className="crumb"
			onClick={() => {
				setHover(false);
				cancelHoverDebounced();
			}}
			onMouseEnter={() => {
				setHoverDebounced(true);
			}}
			onMouseLeave={() => {
				setHoverDebounced(false);
			}}
		>
			{adapter.depth > 0 && (
				<img
					style={{ position: 'absolute', left: '-16px', top: '24px' }}
					src={require('../../icon/breadcrub_trail_connector_dropdown_arrow.svg')}
				/>
			)}
			<adapter.Crumb {...{ nav, store, DATA, hover: hover }} />
			<CSSTransition
				in={hover}
				timeout={500}
				classNames={'fade-transition'}
				unmountOnExit
			>
				<div>
					<adapter.CrumbDetail {...{ nav, store, DATA }} />
				</div>
			</CSSTransition>
		</div>
	);
};

const BreadCrumbTrail: React.FC<ICrumbProps> = ({ DATA, nav, store }) => {
	const levelIndex = getLevelAdapterIndex(nav);
	const adapters = useMemo(
		() => levelAdapters.slice(0, levelIndex + 1).filter((la) => !!la.Crumb),
		[levelIndex]
	);

	return (
		<TransitionGroup className="breadcrumb-trail">
			{adapters.map((adapter, idx) => (
				<CSSTransition
					timeout={300}
					classNames="stack-left"
					key={adapter.key}
				>
					<BreadCrumb {...{ nav, DATA, store, adapter }} />
				</CSSTransition>
			))}
		</TransitionGroup>
	);
};

export default BreadCrumbTrail;
