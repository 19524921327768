/**
 * Functional utils for use with router.
 **/

import AsapPromise from '../AsapPromise';
import { AsapThenable } from '../asapPromiseInterfaces';

/**
 * Asynch reduce that returns an asap promise.
 */
export function asapReduce<T, U>(
	arr: T[],
	iterator: {
		(accumulator: U, currentValue: T, currentIndex?: number, array?: T[]):
			| U
			| AsapThenable<U>;
	},
	initialValue: U
) {
	return new AsapPromise<U>((resolve, reject) => {
		let idx = 0;

		function step(accumulator: U) {
			if (idx < arr.length) {
				AsapPromise.resolve(
					iterator(accumulator, arr[idx], idx++, arr)
				).then(step, reject);
			} else {
				resolve(accumulator);
			}
		}

		step(initialValue);
	});
}

export type Diff<
	T extends string | number | symbol,
	U extends string | number | symbol
> = ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
export type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>;

export function omit<T extends Object, K extends keyof T>(
	source: T,
	...cull: K[]
): Omit<T, K> {
	return cull.reduce(
		(o, x) => {
			delete o[x];
			return o;
		},
		{ ...(source as any) }
	);
}

export function stripLeadingSlash(path) {
	return path.charAt(0) === '/' ? path.substr(1) : path;
}

export function addLeadingSlash(path) {
	return path.charAt(0) === '/' ? path : '/' + path;
}
