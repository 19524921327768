/**
 * Sorting button
 */

import React, { useState } from 'react';

export const SortIcon = ({ active, reverse, onClick }) => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		onClick={onClick}
		className={`sort-icon ${reverse ? 'reverse' : ''} ${
			active ? 'active' : ''
		}`}
	>
		<g fill="none" fillRule="evenodd">
			<path
				className="border"
				fill="#FFF"
				d="M13.5 13.5H.737V.737H13.5z"
			/>
			<path stroke="#45812B" d="M10.56 5.398L7.176 8.84l-3.499-3.44" />
		</g>
	</svg>
);
