/**
 * Footer Component
 */

import React, { useState } from 'react';
import FileSaver from 'file-saver';
import ReactMarkdownText from './text/ReactMarkdownText';
import Methods from './methods';
import getContextualLexicon from '../data/contextualLexicon';
import AppConfig from '../app/config/AppConfig';
import { IShareConfig } from '../../../../preprocess/src/ts/prepObservations';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';
import { downloadActions } from './downloads';

const getIndexFolderURL = () =>
	window.location.href
		// replace greedily after hash/search
		.replace(/(?:#|\?).*/, '')
		// replace anything after and inclusive of folder '/'
		.replace(/\/[^/]*$/, '') +
	// add trailing slash
	'/';
const getShareURL = (regionKey: string) =>
	`${getIndexFolderURL()}pages/${regionKey}.html`;

const Footer: React.FC<{
	timestamp: string;
	regionKey: string;
	share: IShareConfig;
}> = ({ timestamp, regionKey, share }) => {
	const [isMethodsOpen, setIsMethodsOpen] = useState(false);
	const lexicon = getContextualLexicon({ Region: regionKey });

	return (
		<div className="footer" id="about">
			<div className="width">
				<ReactMarkdownText
					source={lexicon.FOOTER.Title}
					className="title footer-title text_title"
				/>
				<div className="col1">
					<ReactMarkdownText
						source={lexicon.FOOTER.SECTION_I.Title}
						className={'text_heading'}
					/>
					<ReactMarkdownText
						source={lexicon.FOOTER.SECTION_I.Body}
						className={'text_body'}
					/>
					<p className="timestamp" style={{ display: 'none' }}>
						{/* Application data last updated on{' '} */}
						{new Date(timestamp).toLocaleDateString()}.
					</p>
				</div>
				<div className="col2">
					<ReactMarkdownText
						source={lexicon.FOOTER.SECTION_II.Title}
						className={'text_heading'}
					/>
					<ReactMarkdownText
						source={lexicon.FOOTER.SECTION_II.Body}
						className={'text_body'}
					/>
				</div>
				<div className="col3">
					<a
						href="https://www.nature.org/"
						target="_blank"
						style={{ outline: 'none' }}
					>
						<img src={require('../../icon/TNC_logo.svg')} />
					</a>
					<a
						href="https://snappartnership.net/"
						target="_blank"
						style={{ outline: 'none' }}
					>
						<img src={require('../../icon/SNAPP_logo.png')} />
					</a>{' '}
					<a
						href="https://aws.amazon.com/"
						target="_blank"
						style={{ outline: 'none' }}
					>
						<img
							className="aws"
							src={require('../../icon/AWS_logo.png')}
						/>
					</a>
				</div>

				<div className="buttons">
					{/* Updated <span /> to redirect to download pg. - Set to finding the #hash difference between Kenya & us-corn-belt */}
					<div
						className="button"
						onClick={() => {
							if (
								window.location.hash.split('/')[1] === 'kenya'
							) {
								window.open(
									'https://doi.org/10.5063/F1805124',
									'_blank'
								);
							} else {
								window.open(
									'https://doi.org/10.5063/F1CR5RSR',
									'_blank'
								);
							}
						}}
					>
						{lexicon.FOOTER.BUTTONS.Download}
					</div>
					<div
						className={`button button--methods ${
							isMethodsOpen && 'open'
						}`}
						onClick={() => {
							if (!isMethodsOpen) {
								sendAnalyticsUIInteraction(
									'OPEN',
									analyticsCategories.FOOTER,
									'methods-sources'
								);
							}

							setIsMethodsOpen(!isMethodsOpen);
						}}
					>
						{isMethodsOpen
							? lexicon.FOOTER.BUTTONS.Methods_Hide
							: lexicon.FOOTER.BUTTONS.Methods}
					</div>
				</div>

				<Methods open={isMethodsOpen} lexicon={lexicon} />
			</div>
			<div className="copyright">
				<span style={{ display: 'flex' }}>
					<span className="c-right"> &#169;</span>
					<ReactMarkdownText
						source={lexicon.FOOTER.Copyright}
						className=" text_heading"
						componentProps={{ style: { paddingLeft: '5px' } }}
					/>
				</span>
				<div className="share">
					<ReactMarkdownText
						source={lexicon.FOOTER.Share}
						className="text_heading share-text"
					/>
					<div className="share-icons">
						<a
							onClick={() =>
								sendAnalyticsUIInteraction(
									'SELECT',
									analyticsCategories.FOOTER,
									'share',
									{ key: 'share_type', value: 'facebook' }
								)
							}
							href={`https://www.facebook.com/sharer/sharer.php?u=${getShareURL(
								regionKey
							)}`}
							target="_blank"
						>
							<img src={require('../../icon/facebook.svg')} />
						</a>
						<a
							onClick={() =>
								sendAnalyticsUIInteraction(
									'SELECT',
									analyticsCategories.FOOTER,
									'share',
									{ key: 'share_type', value: 'twitter' }
								)
							}
							href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
								share.defaultComment
							)}&url=${getShareURL(regionKey)}`}
							target="_blank"
						>
							<img src={require('../../icon/twitter.svg')} />
						</a>
						<a
							onClick={() =>
								sendAnalyticsUIInteraction(
									'SELECT',
									analyticsCategories.FOOTER,
									'share',
									{ key: 'share_type', value: 'linkedin' }
								)
							}
							href={`https://www.linkedin.com/shareArticle?mini=true&url=${getShareURL(
								regionKey
							)}&title=${encodeURIComponent(
								share.title
							)}&summary=${encodeURIComponent(
								share.description
							)}`}
							target="_blank"
						>
							<img src={require('../../icon/linkedin.svg')} />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;

const figureOne = {
	title: 'Search Terms for Papers',
	columnATitle: 'Geographic Locations',
	columnAContent: [
		'Illinois',
		'Indiana',
		'Iowa',
		'Kansas',
		'Michigan',
		'Minnesota',
		'Missouri',
		'North Dakota',
		'South Dakota',
		'Nebraska',
		'Ohio',
		'Wisconsin',
		'Midwest U.S.',
		'Midwest US',
		'Midwest',
		'United States'
	],
	columnBTitle: 'Cropping Systems',
	columnBContent: [
		'corn',
		'maize',
		'soybean',
		'Zea mays',
		'Glycine max',
		'agricultur*',
		'agro-ecosystem',
		'agroecosystem',
		'crop',
		'field crop',
		'cropping system',
		'farm',
		'conservation agricult*',
		'',
		'* cropped purposefully for search purposes'
	],
	label: 'Fig. 1  Geographic and agricultural terms used to search for papers'
};

const figureTwo = {
	title: 'Results from Initial Study Search',
	columnTitles: ['Search Categories', 'Returned', 'Included'],
	rows: [
		['Cover Crops', 351, 58],
		['Tillage Management', 1130, 176],
		['Pest Management', 135, 26],
		['Nutrient Management', 738, 25]
	],
	label: 'Fig. 2  Total number of papers returned from initial search and included in final application, per agricultural practice'
};

const figureThree = {
	title: 'Control Variables for Agricultural Practices',
	columnTitles: ['Agricultural Practice', 'Control Options'],
	rows: [
		['Cover Crops', 'Winter Fallow'],
		['Early Season Pest Management', 'No Pesticide'],
		[
			'Nutrient Management > Application Timing > Early Season',
			'Pre-Plant'
		],
		[
			'Nutrient Management > Application Timing > Early Season',
			'Single-Application'
		],
		['Nutrient Management > Application Timing > Early Season', 'Fall'],
		['Nutrient Management > Fertilizer Placement > Banded', 'Broadcast'],
		['Conventional Tillage', ''],
		['Conservation Tillage', ''],
		['Zonal Tillage', '']
	],
	label: 'Fig. 3 Study control variables included in tool, per agricultural practice subcategories'
};
