/**
 * State for the navigation options and their tie to the router.
 */

import RouteConfig from '../router/redux/RouteConfig';
import { OtherAction } from '../router/redux/typed';
import * as _ from 'lodash';

/** the form of the state **/
export interface INavState {
	Region: string;
	Practice: string;
	Outcome: string;
	GLII: string;
	GLIII: string;
	ChartRange: string;
	PracticeFilters: string;
	Studies: string;
	Carousel: number;
	store?: any;
	openInsightIndex?: number;
}

/** The action types applicable to this state **/
export type SET = 'NAV/SET';
export const SET: SET = 'NAV/SET';
export type SetAction = {
	type: SET;
	val: INavState;
};

// union of applicable action types
export type NavStateAction = OtherAction | SetAction;

export const DEFAULTS = {
	Region: '',
	Practice: '',
	Outcome: '',
	GLII: '',
	GLIII: '',
	ChartRange: '100',
	PracticeFilters: '',
	Studies: '',
	Carousel: 0
};

/** Redux Reducer that updates state based on action. NOTE: this must be a PURE function with no mutations to state.**/
export const nav = (
	state: INavState = null,
	action: NavStateAction = OtherAction
) => {
	state = state || DEFAULTS;

	switch (action.type) {
		case SET:
			return { ...state, ...action.val };
		default:
			return state;
	}
};

// add route configuration for nav
export const registerNavRoutes = function (router) {
	router.addConfig(
		new RouteConfig<{ nav: INavState }, INavState>(DEFAULTS, {
			reducerKey: 'nav'
		})
			.addStringLayer('Region')
			.addStringLayer('Practice')
			.addStringLayer('Outcome')
			.addStringLayer('GLII')
			.addStringLayer('GLIII')
			.addProperty('ChartRange')
			.addProperty('PracticeFilters')
			.addProperty('Studies')
			.addIntProperty('Carousel')
	);
};
