/**
 * Header Component
 */

import React, { useMemo, useState } from 'react';
import Colors from '../utils/color';
import { IRegionMeta } from '../app/View';
import { getRouter } from '../router/routes';
import { AnimatedRadio } from './AnimatedRadio';
import getContextualLexicon from '../data/contextualLexicon';
import {
	analyticsCategories,
	sendAnalyticsUIInteraction
} from '../app/utils/analytics';

const Toggle: React.FC<{
	options: { name: string; key: string }[];
	value: string;
}> = ({ options, value }) => {
	const augOpts = useMemo(
		() =>
			options.map((o) => ({
				...o,
				href: getRouter().encodeAsHref({
					nav: { Region: o.key }
				} as any)
			})),
		[options]
	);

	return AnimatedRadio({
		className: 'header-toggle',
		containerProps: { className: 'header-toggle-options' },
		options: augOpts,
		value,
		renderHighlight: ({ box }) => (
			<div
				className="header-toggle-highlight"
				style={{ left: `${box.left}px`, width: `${box.width}px` }}
			/>
		),
		renderOption: ({ option: { href, key, name }, index, selected }) => (
			<a
				key={index}
				href={href}
				onClick={() => {
					sendAnalyticsUIInteraction(
						'SELECT',
						analyticsCategories.HEADER,
						'REGION_BUTTON',
						{ key: 'region_id', value: key }
					);
				}}
				className={`${selected ? 'selected' : ''}`}
			>
				{name}
			</a>
		)
	});
};

const Header: React.FC<{ regions: IRegionMeta[]; regionKey: string }> = ({
	regions,
	regionKey
}) => {
	const lexicon = getContextualLexicon();
	return (
		<div className="header">
			<a
				href="https://www.nature.org/"
				target="_blank"
				style={{ outline: 'none' }}
			>
				<img
					style={{ width: '128px' }}
					src={require('../../icon/TNCLogoPrimary_CMYK.svg')}
				/>
			</a>
			<a
				className="text_heading"
				href="./#"
				style={{
					outline: 'none',
					color: 'unset',
					textDecoration: 'none'
				}}
			>
				{lexicon.HEADER.Title}{' '}
				<span style={{ color: Colors.lightGray }}>|</span>{' '}
				<span style={{ fontStyle: 'italic' }}>
					{lexicon.HEADER.SubTitle}
				</span>
			</a>
			<Toggle options={regions} value={regionKey}></Toggle>
		</div>
	);
};

export default Header;
