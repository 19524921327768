/**
 * Utilities to transform deep links into dashboard state
 */

import * as _ from 'lodash';
const DEEPMERGE = require('deepmerge');
import { INavState } from '../../nav/state';
import { TData, IObservation } from '../../../../../preprocess/src/ts/exec';
import { IFilterObject } from '../../components/filters';

function getListOfObservationUIDsFromTree(
	data: TData,
	nav: INavState
): number[] {
	const { Practice, Outcome, GLII, GLIII } = nav;

	if (Practice === '') {
		// pre calculate observations
		// pre calculate studies
		// placeholder for edge case
		return [];
	}

	// Start with Level Two Groups
	const LevelTwoGroups = _.get(data.practicesAndOutcomesTree, [
		Practice,
		'groups',
		Outcome,
		'groups'
	]);

	if (GLII === '') {
		return _.flatten(
			_.map(LevelTwoGroups, (LevelTwoGroup, key) => {
				return _.flatten(
					_.map(LevelTwoGroup.groups, (value, key) => {
						return value.observationsByUID;
					})
				);
			})
		);
	}

	if (GLII !== '' && GLIII == '') {
		return _.flatten(
			_.map(_.get(LevelTwoGroups, [GLII, 'groups']), (value, key) => {
				return value.observationsByUID;
			})
		);
	}

	if (GLII !== '' && GLIII !== '') {
		return _.get(LevelTwoGroups, [
			GLII,
			'groups',
			GLIII,
			'observationsByUID'
		]);
	}
}

export function filterObservations(
	listOfObservationUIDs: number[],
	filterObjectArray: IFilterObject[],
	DATA: TData
): number[] {
	return _.filter(listOfObservationUIDs, (observationUID) => {
		let observation: IObservation =
			DATA.observationDictionary[observationUID];

		let tests: boolean[] = filterObjectArray.map((filterObject) => {
			if (filterObject.key === 'Chart range') {
				return true;
			}

			let listOfActiveValues = _.filter(
				filterObject.options,
				(option) => option.active
			).map((option) => option.value);

			const value = observation.variables[filterObject.key];
			if (filterObject.valueType === 'array') {
				// if value is array, match if any values are selected
				return _.some(
					_.map(value, (v) => _.includes(listOfActiveValues, v))
				);
			} else {
				return _.includes(listOfActiveValues, value);
			}
		});

		return _.includes(tests, false) ? false : true;
	});
}

export default function getObservationsFromNavState(
	DATA: TData,
	nav: INavState,
	filterObjectsArray: IFilterObject[]
): {
	filteredObservationsByUID: number[];
	filteredStudiesAndLocations: { [key: string]: string[] };
} {
	const listOfObservationUIDsFromTree: number[] =
		getListOfObservationUIDsFromTree(DATA, nav);

	const filteredObservationsByUID = filterObservations(
		listOfObservationUIDsFromTree,
		filterObjectsArray,
		DATA
	);

	const filteredStudiesAndLocations: {
		[key: string]: string[];
	} = filteredObservationsByUID.reduce((acc, observationUID) => {
		let observation = DATA.observationDictionary[observationUID];

		return DEEPMERGE(acc, {
			[observation.studyUID]: [observation.location]
		});
	}, {} as { [key: string]: string[] });

	return { filteredObservationsByUID, filteredStudiesAndLocations };
}
