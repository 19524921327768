/**
 * Component for demonstrating selection components for the nav.
 */

import React from 'react';
import { filter, flatten, times, sum, uniq } from 'lodash-es';

import { INavState } from '../nav/state';
import { TData } from '../../../../preprocess/src/ts/exec';
import { ScaleLinear } from 'd3-scale';
import { normColors, norms } from '../utils/norms';

import BeanplotContent from './beanplotContent';
import BeanplotTooltip from './beanplotTooltip';
import ReactTooltip from 'react-tooltip';
import { DefaultTooltipProps } from './tooltips';
import Portal from './Portal';
import getContextualLexicon from '../data/contextualLexicon';

interface IBeanplotProps {
	nav: INavState;
	DATA: TData;
	observationsByUID: number[];
	norm: string;
	shown: boolean;
	width: number;
	plotWidth: number;
	height: number;
	scaleY: ScaleLinear<number, number>;
	refLines: { point: [number, number]; dashed: boolean }[];
	beanKey: string;
}

export const showDistribution = (numOfObservations: number) =>
	numOfObservations >= 20;

const Beanplot: React.FC<IBeanplotProps> = ({
	DATA,
	nav,
	norm,
	observationsByUID,
	shown,
	width,
	plotWidth,
	height,
	scaleY,
	refLines,
	beanKey
}) => {
	const lexicon = getContextualLexicon(nav);
	const observations = observationsByUID.map(
		(UID) => DATA.observationDictionary[UID]
	);

	const studyCount = uniq(observations.map((o) => o.studyUID)).length;

	let percentChangesOnly: number[] = flatten(
		filter(observations, (observation) => {
			return !isNaN(observation.percentChange);
		}).map((o) =>
			times(
				o.location.split(';').length * o.rvYear.split(';').length,
				() => o.percentChange
			)
		)
	);

	let mean = sum(percentChangesOnly) / percentChangesOnly.length;

	return (
		<div
			data-tip
			data-for={beanKey}
			className="plot-parent"
			style={{
				height,
				width: plotWidth
			}}
		>
			<div
				className="norm-box"
				style={{
					background: `${normColors[norms[norm.toLowerCase()]]}`
				}}
			/>
			<div
				className="norm-box"
				style={{
					background: [...normColors].reverse()[
						norms[norm.toLowerCase()]
					]
				}}
			/>
			<BeanplotContent
				{...{
					nav,
					observations,
					height,
					width,
					plotWidth,
					scaleY,
					refLines,
					shown,
					mean,
					norm,
					percentChangesOnly
				}}
			/>
			<Portal container={document.body}>
				<ReactTooltip
					id={beanKey}
					getContent={() => {
						return (
							<BeanplotTooltip
								{...{
									observations,
									studyCount,
									mean,
									norm,
									lexicon
								}}
							/>
						);
					}}
					{...{
						...DefaultTooltipProps,
						effect: 'float'
					}}
				/>
			</Portal>
		</div>
	);
};

export default Beanplot;
